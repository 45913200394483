import * as React from 'react';
import {
  Flex, Form, FormInstance, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { PermMedia } from '@mui/icons-material';
import { ServiceAboutType, ServiceProfileType, ServiceType } from '../../../../common/types';
import { FileUpload } from '../FileUpload';

interface IFormPhotoVideo{
  form: FormInstance<any>,
  decodedToken: any,
}

const { Title } = Typography;

export const FormPhotoVideo: React.FC<IFormPhotoVideo> = ({ form, decodedToken }) => {
  const { t } = useTranslation();

  return <>
      <Flex gap={10} align={'center'} style={{ marginBottom: 20 }}>
          <PermMedia />
          <Title level={4} style={{ margin: 0, textTransform: 'uppercase' }}>{t('Photo')}</Title>
      </Flex>
      <Form.Item<ServiceProfileType & ServiceAboutType>
          label={t('Logo Full')}
          name="logo"
      >
          <FileUpload
              form={form}
              nameField={'logo'}
              type={'logo'}
              dirName={decodedToken.sub}/>
      </Form.Item>
      <Form.Item<ServiceProfileType & ServiceAboutType>
          label={t('Logo Short')}
          name="avatar"
      >
          <FileUpload
              form={form}
              nameField={'avatar'}
              type={'avatarService'}
              dirName={decodedToken.sub}/>
      </Form.Item>
      <Form.Item<ServiceProfileType & ServiceAboutType>
          label={t('Presentation Picture')}
          name="presentationPicture"
      >
          <FileUpload
              form={form}
              nameField={'presentationPicture'}
              type={'gallery'}
              dirName={decodedToken.sub}/>
      </Form.Item>
      <Form.Item<ServiceProfileType & ServiceAboutType & ServiceType>
          label={t('Gallery')}
          name="gallery"
      >
          <FileUpload
              form={form}
              nameField={'gallery'}
              type={'gallery'}
              multiple={true}
              dirName={decodedToken.sub}/>
      </Form.Item>
    </>;
};
