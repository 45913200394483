import * as React from 'react';
import { useCallback } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons/lib';
import { useTranslation } from 'react-i18next';
import { paths } from '../../common';

interface IButtonLogin{
  disabled?: boolean
  withIcon?: boolean
}

export const ButtonLogin: React.FC<IButtonLogin> = ({
  disabled = false, withIcon,
}) => {
  const { t } = useTranslation();
  const onClick = useCallback(() => {

  }, []);

  return <>
        <Link to={paths.LOGIN} onClick={onClick}>
            <Button type={'primary'} disabled={disabled} icon={withIcon && <UserOutlined />} aria-label={'Login'} style={{ minWidth: '80px' }}>{t('Login')}</Button>
        </Link>
    </>;
};
