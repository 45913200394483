import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useResizeWidthWindow } from '../hooks/useResizeWidthWindow';

const RowStyled = styled(Row)`
  padding: 40px;
  
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const RegistrationHeaderSeeker: React.FC = () => {
  const { t } = useTranslation();

  return (
          <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
              <RowStyled align={'middle'}>
                  <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}
                       style={{ margin: '0 auto 20px' }}>
                      <h1 className={'text_main regular'}>{t('Join and')} </h1>
                      <h1 className={'text_main accent'}>{t('achieve more')}</h1>
                      <h2 className={'text_second'}>{t('Register, find the service you need and write directly')}.<br />{t('All in one place')}.</h2>
                  </Col>
                  <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}
                       style={{ textAlign: 'center', padding: '0 40px' }}>
                      <img src={'/reg_seeker.png'} alt={'b2b'} width={useResizeWidthWindow() > 800 ? '70%' : '50%'} loading="lazy"/>
                  </Col>
              </RowStyled>
          </div>
  );
};
