import * as React from 'react';
import { useEffect, useState } from 'react';
import { ImageStyled } from './ImageStyled';
import { defaultPict } from '../../common/defaultPictConst';

type BaseImageProps = {
  preview?: boolean;
  src?: string;
  alt?: string;
  loading?: 'eager' | 'lazy';
  width?: string | number;
  height?: string | number;
};

export const BaseImage: React.FC<BaseImageProps> = ({
  src, preview, alt, loading, width, height,
}) => {
  const [insideSrc, setInsideSrc] = useState<string>();

  useEffect(() => {
    setInsideSrc(src);
  }, [src]);

  return (
    <ImageStyled height={height} width={width} loading={loading} alt={alt} preview={preview} src={insideSrc} onError={() => { setInsideSrc(defaultPict); }}/>
  );
};
