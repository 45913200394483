import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  message,
  Radio,
  RadioChangeEvent,
  Select,
  Spin,
  Switch,
  Tabs,
  TabsProps,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import FlagIconFactory from 'react-flag-icon-css';
import {
  AccountBox, HelpCenter, Language, Settings,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
  API, COMPANY_SIZE, COOKIE_ID_TOKEN, decodeToken, LANG_EN, paths, SUPPORT_LANGUAGE,
} from '../../common';
import {
  ServiceAboutType, ServiceProfileType, ServiceType, StatusServiceType,
} from '../../../../common/types';
import { FormOffices } from './FormOffices';
import { AppDispatch, RootState } from '../../store';
import { fetchListService } from '../../store/slices/servicesSlice';
import { useInsideService } from '../../hooks/useInsideService';
import { FormPhotoVideo } from './FormPhotoVideo';
import { LanguageField } from '../LanguageField';
import { FormPrices } from './FormPrices';
import { setterApp } from '../../store/slices/appSlice';
import { useSaveData } from '../../hooks/useSaveData';
import { useCategory } from '../../hooks/useGategory';
import { FormContacts } from './FormContacts';
import { FormSocial } from './FormSocial';

const { Title } = Typography;

export const FormService = () => {
  const {
    RenderMessageError, saveData, contextHolder,
  } = useSaveData();
  const { t } = useTranslation();
  const [messageApi, contextHolder2] = message.useMessage();
  const { CATEGORY_SERVICES, GROUP_CATEGORY_SERVICES, getGroupCategoryByCategory } = useCategory();

  const dispatch = useDispatch<AppDispatch>();
  const service = useSelector<RootState, ServiceType>((state) => state.services.service);
  const currentLanguage = useSelector((state: RootState) => state.app.currentLanguageForFormService);

  const [form] = Form.useForm();
  const insideService = useInsideService();

  const [spin, setSpin] = useState(false);
  const [supportLanguage, setSupportLanguage] = useState<string[]>([LANG_EN]);

  const [loadingStatus, setLoadingStatus] = useState<boolean>();
  const [status, setStatus] = useState<StatusServiceType>(service.status);

  const [groupCategory, setGroupCategory] = useState<string>();

  const token = Cookies.get(COOKIE_ID_TOKEN) || '';
  const decodedToken = decodeToken(token);

  useEffect(() => {
    setStatus(service.status);
  }, [service]);

  const onChangeSwitch = async (value: boolean) => {
    setLoadingStatus(true);
    const newStatus = value ? 'online' : 'offline';
    if (await saveData({ ...service, status: newStatus }, 'status')) {
      setStatus(newStatus);
      setLoadingStatus(false);
      dispatch(fetchListService(decodedToken['cognito:username']));
    }
    setLoadingStatus(false);
  };

  const onFinish = useCallback(async (values: any) => {
    setSpin(true);

    try {
      const newService: ServiceType = {
        ...service,
        isResource: values.isResource,
        isShowRequestButton: values.isShowRequestButton,
        profile: {
          ...service.profile,
          supportLanguage: values.supportLanguage,
          companyName: values.companyName,
          companyName_vn: values.companyName_vn,
          companyName_ua: values.companyName_ua,
          category: values.category,
          presentationInfo_ua: values.presentationInfo_ua,
          presentationInfo: values.presentationInfo,
          presentationInfo_vn: values.presentationInfo_vn,
          logo: values.logo,
          produce: values.produce,
          produce_ua: values.produce_ua,
          produce_vn: values.produce_vn,
          tags: values.tags,
          parentCompany: values.parentCompany,
          parentCompany_vn: values.parentCompany_vn,
          parentCompany_ua: values.parentCompany_ua,
          representativeName: values.representativeName,
          representativeName_vn: values.representativeName_vn,
          representativeName_ua: values.representativeName_ua,
          bank: values.bank,
          bank_ua: values.bank_ua,
          bank_vn: values.bank_vn,
          lastName: values.lastName,
          avatar: values.avatar,
          presentationPicture: values.presentationPicture,
          accountPeriod: values.accountPeriod,
          accountPeriod_ua: values.accountPeriod_ua,
          accountPeriod_vn: values.accountPeriod_vn,
          shareholder: values.shareholder,
          shareholder_ua: values.shareholder_ua,
          shareholder_vn: values.shareholder_vn,
          capital: values.capital,
          city: values.city,
          employee: values.employee,
          certification: values.certification,
          website: values.website,
          valueCustomer: values.valueCustomer,
          valueCustomer_vn: values.valueCustomer_vn,
          valueCustomer_ua: values.valueCustomer_ua,
          region: values.region,
          establishDate: values.establishDate ? values.establishDate.format('YYYY') : '',
          socialLinks: {
            linkInstagram: values.linkInstagram,
            linkTiktok: values.linkTiktok,
            linkFacebook: values.linkFacebook,
            linkTwitter: values.linkTwitter,
            linkLinkedIn: values.linkLinkedIn,
            linkYouTube: values.linkYouTube,
          },
          firstName: values.firstName,
          license: values.license,
        },
        about: {
          businessDescription: values.businessDescription,
          businessDescription_ua: values.businessDescription_ua,
          businessDescription_vn: values.businessDescription_vn,

          shortInfo: values.shortInfo,
          shortInfo_vn: values.shortInfo_vn,
          shortInfo_ua: values.shortInfo_ua,
        },
        gallery: values.gallery,
      };
      if (await saveData(newService, 'form')) {
        dispatch(fetchListService(decodedToken['cognito:username']));
      }
    } catch (e: any) {
      console.log(e);
    }
    setSpin(false);
  }, [service]);

  const onFinishFailed = async (errorInfo: any) => {
    console.log('Failed:', errorInfo);

    messageApi.open({
      type: 'error',
      content: <>{t('Form include errors')}. {t('Please check form again')}</>,
      duration: 3,
    });
  };

  const onCategoryChange = (value: string) => {
    setGroupCategory(value);
    if (groupCategory !== value) {
      const [newCategory] = GROUP_CATEGORY_SERVICES[value].category.length === 1
        ? GROUP_CATEGORY_SERVICES[value].category : [undefined];

      form.setFieldsValue({ category: newCategory });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      isResource: insideService.isResource,
      isShowRequestButton: insideService.isShowRequestButton,
      firstName: insideService.profile.firstName,
      companyName: insideService.profile.companyName,
      companyName_ua: insideService.profile.companyName_ua,
      companyName_vn: insideService.profile.companyName_vn,
      phone: '',
      email: '',
      groupCategory: getGroupCategoryByCategory(insideService.profile.category),
      category: insideService.profile.category,

      presentationInfo: insideService.profile.presentationInfo,
      presentationInfo_ua: insideService.profile.presentationInfo_ua,
      presentationInfo_vn: insideService.profile.presentationInfo_vn,

      logo: insideService.profile.logo,
      avatar: insideService.profile.avatar,
      presentationPicture: insideService.profile.presentationPicture,
      website: insideService.profile.website,

      businessDescription: insideService.about.businessDescription,
      businessDescription_ua: insideService.about.businessDescription_ua,
      businessDescription_vn: insideService.about.businessDescription_vn,

      produce: insideService.profile.produce,
      produce_ua: insideService.profile.produce_ua,
      produce_vn: insideService.profile.produce_vn,
      tags: insideService.profile.tags,
      shortInfo: service.about.shortInfo,
      shortInfo_ua: service.about.shortInfo_ua,
      shortInfo_vn: service.about.shortInfo_vn,
      employee: service.profile.employee,
      establishDate: service.profile.establishDate
        ? dayjs(service.profile.establishDate, 'YYYY')
        : '',
      certification: service.profile.certification,
      representativeName: service.profile.representativeName,
      representativeName_ua: service.profile.representativeName_ua,
      representativeName_vn: service.profile.representativeName_vn,
      parentCompany: service.profile.parentCompany,
      parentCompany_ua: service.profile.parentCompany_ua,
      parentCompany_vn: service.profile.parentCompany_vn,

      license: service.profile.license,

      accountPeriod: service.profile.accountPeriod,
      accountPeriod_ua: service.profile.accountPeriod_ua,
      accountPeriod_vn: service.profile.accountPeriod_vn,

      shareholder: service.profile.shareholder,
      shareholder_ua: service.profile.shareholder_ua,
      shareholder_vn: service.profile.shareholder_vn,

      bank: service.profile.bank,
      bank_ua: service.profile.bank_ua,
      bank_vn: service.profile.bank_vn,

      valueCustomer: service.profile.valueCustomer,
      valueCustomer_ua: service.profile.valueCustomer_ua,
      valueCustomer_vn: service.profile.valueCustomer_vn,

      gallery: typeof service.gallery === 'string' ? service.gallery : undefined,
      supportLanguage: service.profile.supportLanguage || [LANG_EN],
      linkFacebook: service.profile.socialLinks?.linkFacebook,
      linkInstagram: service.profile.socialLinks?.linkInstagram,
      linkTiktok: service.profile.socialLinks?.linkTiktok,
      linkTwitter: service.profile.socialLinks?.linkTwitter,
      linkLinkedIn: service.profile.socialLinks?.linkLinkedIn,
      linkYouTube: service.profile.socialLinks?.linkYouTube,
    });
    setSupportLanguage(service.profile.supportLanguage || []);
    setGroupCategory(getGroupCategoryByCategory(insideService.profile.category));

    dispatch(setterApp({ currentLanguageForFormService: LANG_EN }));
  }, [insideService]);

  const getFormFieldForTranslate = (language: string) => {
    const prefix = language === LANG_EN ? '' : `_${language}`;
    // @ts-ignore
    return <>
            <Form.Item<ServiceProfileType & ServiceAboutType>
                label={t('Company Name')}
                name={`companyName${prefix}` as any}
                rules={[
                  { required: true, message: t('Please, fill this field!') },
                  {
                    pattern: !prefix ? /^[a-zA-Z0-9 _./]+$/ : undefined,
                    message: t('Only litters, numbers and _'),
                  },
                  {
                    validator: async (_, value) => {
                      const resultCheckCompanyName = (await axios.get<boolean>(`${API.URL_REST_API}${paths.CHECK_COMPANY_NAME}?serviceId=${insideService.id}&companyName=${value}`)).data;
                      if (!resultCheckCompanyName) {
                        return Promise.reject(new Error(t('This Company Name is already used!')));
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
            >
                <Input placeholder={t('Company Name')}/>
            </Form.Item>
            <Form.Item<ServiceProfileType & ServiceAboutType>
                label={t('Short info')}
                name={`shortInfo${prefix}` as any}
            >
                <TextArea rows={2} placeholder={t('Short info')} showCount allowClear
                          maxLength={100}/>
            </Form.Item>
            <Form.Item<ServiceProfileType & ServiceAboutType>
                label={t('Presentation Info')}
                name={`presentationInfo${prefix}` as any}
                rules={[{ required: true, message: t('Please, fill this field!') }]}
            >
                <TextArea rows={6} placeholder={t('Presentation Info')} showCount
                          maxLength={400} allowClear />
            </Form.Item>
            <Form.Item<ServiceProfileType & ServiceAboutType>
                label={t('Business Description')}
                name={`businessDescription${prefix}` as any}
                rules={[{ required: true, message: t('Please, fill this field!') }]}
            >
                <TextArea rows={7} placeholder={t('Business Description')} showCount allowClear
                          maxLength={500}/>
            </Form.Item>
                <Form.Item<ServiceProfileType & ServiceAboutType>
                    label={[t('Produce/provide services'), <Tooltip key={'1'} placement="right" title={
                        // <>Start typing and select from list.<br /> If you do not see tag you need, continue typing and press Enter button to add a tag</>
                        <>Start typing and press Enter button to add a tag</>
                    }>
                        <HelpCenter />
                    </Tooltip>]}
                    name={`produce${prefix}` as any}
                    rules={[{ required: true, message: t('Please, fill this field!') }]}
                >
                    <Select
                        mode={'tags'}
                        placeholder={t('Produce/provide services')}
                        onChange={(e: any) => {
                          console.log(e);
                        }}
                        allowClear
                    />
                </Form.Item>
            <Form.Item<ServiceProfileType & ServiceAboutType>
                label={t('Representative Name')}
                name={`representativeName${prefix}` as any}
            >
                <Input placeholder={t('Representative Name')}/>
            </Form.Item>
            <Form.Item<ServiceProfileType & ServiceAboutType>
                label={t('Parent Company')}
                name={`parentCompany${prefix}` as any}
            >
                <Input placeholder={t('Parent Company')}/>
            </Form.Item>
            <Form.Item<ServiceProfileType & ServiceAboutType>
                label={t('Capital')}
                name={`capital${prefix}` as any}
            >
                <Input placeholder={t('Capital')}/>
            </Form.Item>
            <Form.Item<ServiceProfileType & ServiceAboutType>
                label={t('Employee')}
                name={`employee${prefix}` as any}
            >
                <Select
                    placeholder="Select your company size"
                    allowClear
                    options={
                        (Object.keys(COMPANY_SIZE) as Array<keyof typeof COMPANY_SIZE>).map((key) => ({
                          value: key,
                          label: COMPANY_SIZE[key] as any,
                        }))
                    }
                />
            </Form.Item>
            <Form.Item<ServiceProfileType & ServiceAboutType>
                label={t('Shareholder')}
                name={`shareholder${prefix}` as any}
            >
                <Input placeholder={t('Shareholder')}/>
            </Form.Item>
            <Form.Item<ServiceProfileType & ServiceAboutType>
                label={t('Account Period')}
                name={`accountPeriod${prefix}` as any}
            >
                <Input placeholder={t('Account Period')}/>
            </Form.Item>
            <Form.Item<ServiceProfileType & ServiceAboutType>
                label={t('Value Customer')}
                name={`valueCustomer${prefix}` as any}
            >
                <TextArea rows={7} placeholder={t('Value Customer')} showCount allowClear
                          maxLength={200}/>
            </Form.Item>
        </>;
  };

  const items: TabsProps['items'] = supportLanguage.map((language: string) => ({
    key: language,
    label: SUPPORT_LANGUAGE[language].toUpperCase(),
    children: getFormFieldForTranslate(language),
  }));

  const onChangeLanguage = useCallback((value: RadioChangeEvent) => {
    dispatch(setterApp({ currentLanguageForFormService: value.target.value }));
  }, []);

  const FlagIcon = FlagIconFactory(React, { useCssModules: false });

  return (<div>
            {contextHolder}
            <Spin spinning={spin} tip={t('Please wait')} style={{ width: '100%' }}>
                {contextHolder2}
                <Flex justify={'end'} align={'center'} gap={10}>
                    <Flex gap={3} align={'center'}>
                        <Settings />
                        <p>{t('Settings')}:</p>
                    </Flex>
                    <Switch checkedChildren="On-Line"
                            loading={loadingStatus}
                            unCheckedChildren="Off-Line"
                            onChange={onChangeSwitch}
                            checked={status === 'online'} />
                </Flex>
                <Flex gap={10} align={'center'} style={{ marginBottom: 20 }}>
                    <AccountBox />
                    <Title level={4} style={{ margin: 0, textTransform: 'uppercase' }}>{t('Profile')}</Title>
                </Flex>
                <Form
                    name="newServices"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ width: '100%' }}
                    // initialValues={{ initialValues: defaultService }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    labelAlign={'left'}
                    size={'large'}
                    form={form}
                >
                    <Form.Item<ServiceType>
                        label={t('Service or Resource')}
                        name="isResource"
                    >
                        <Switch checkedChildren={t('Resource')} unCheckedChildren={t('Service')} />
                    </Form.Item>
                    <Form.Item<ServiceType>
                        label={t('Getting request from customers')}
                        name="isShowRequestButton"
                    >
                        <Switch checkedChildren={t('Yes')} unCheckedChildren={t('No')} />
                    </Form.Item>
                    <Form.Item<ServiceProfileType & ServiceAboutType & { groupCategory: string }>
                        label={<><span>Category</span><Tag style={{ marginLeft: 10 }} color={groupCategory ? GROUP_CATEGORY_SERVICES[groupCategory].color : 'white'}>color</Tag></>}
                        name="groupCategory"
                        rules={[{ required: true, message: t('Please, fill this field!') }]}
                    >
                        <Select
                            placeholder="Select your category"
                            onChange={onCategoryChange}
                            allowClear
                            options={
                                Object.keys(GROUP_CATEGORY_SERVICES).map((key) => ({
                                  value: key,
                                  label: GROUP_CATEGORY_SERVICES[key].caption,
                                }))
                            }
                        />
                    </Form.Item>
                    <Form.Item<ServiceProfileType & ServiceAboutType>
                        label="Sub Category"
                        name="category"
                        rules={[{ required: true, message: t('Please, fill this field!') }]}
                    >
                        <Select
                            placeholder="Select your subcategory"
                            allowClear
                            disabled={!groupCategory}
                            options={
                                GROUP_CATEGORY_SERVICES[groupCategory!]?.category.map((key) => ({
                                  value: key,
                                  label: CATEGORY_SERVICES[key],
                                }))
                            }
                        />
                    </Form.Item>
                    <LanguageField form={form} setSupportLanguage={setSupportLanguage}/>
                    <Tabs items={items} onChange={() => {
                    }}/>
                    <Form.Item<ServiceProfileType & ServiceAboutType>
                        label={t('Website')}
                        name="website"
                        rules={[
                          {
                            pattern: /^(https?:\/\/)(www\.)?[@a-zA-Z0-9-_.:/?=&]+\/?$/,
                            message: t('Link is not correct'),
                          },
                        ]}
                    >
                        <Input addonBefore={<Language color={'primary'}/>} placeholder="https://"/>
                    </Form.Item>
                    <Form.Item<ServiceProfileType & ServiceAboutType>
                        label={t('Establish Date')}
                        name="establishDate"
                    >
                        <DatePicker style={{ width: '100%' }} picker="year"/>
                    </Form.Item>
                    <Form.Item<ServiceProfileType & ServiceAboutType>
                        label={t('Certification / ISO')}
                        name="certification"
                    >
                        <Input placeholder={t('Certification / ISO')}/>
                    </Form.Item>
                    <Form.Item<ServiceProfileType & ServiceAboutType>
                        label={t('License')}
                        name="license"
                    >
                        <Input placeholder={t('License')}/>
                    </Form.Item>
                    <Form.Item<ServiceProfileType & ServiceAboutType>
                        label={t('Bank')}
                        name='bank'
                    >
                        <Input placeholder={t('Bank')}/>
                    </Form.Item>
                    <Form.Item<ServiceProfileType & ServiceAboutType>
                        label={t('Tags')}
                        name="tags"
                    >
                        <Select
                            mode={'tags'}
                            placeholder={t('Type tags to improve searching process')}
                            onChange={(e: any) => {
                              console.log(e);
                            }}
                            allowClear
                        />
                    </Form.Item>
                    <RenderMessageError />
                    <Flex justify={'flex-end'}>
                        <Button type="primary" htmlType="submit" size={'large'} aria-label={'Save'}>
                            {t('Save')}
                        </Button>
                    </Flex>
                </Form>
                <Divider orientation="left"></Divider>
                <FormOffices/>
                <Divider orientation="left"></Divider>
                <FormContacts/>
                <Divider orientation="left"></Divider>
                <Form
                    name="social"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ width: '100%' }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    labelAlign={'left'}
                    size={'large'}
                    form={form}
                >
                    <FormSocial />
                    <Flex justify={'flex-end'}>
                        <Button type="primary" htmlType="submit" size={'large'} aria-label={'Save'}>
                            {t('Save')}
                        </Button>
                    </Flex>
                </Form>
                <Divider orientation="left"></Divider>
                <Form
                    name="photo"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ width: '100%' }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    labelAlign={'left'}
                    size={'large'}
                    form={form}
                >
                    <FormPhotoVideo form={form} decodedToken={decodedToken}/>
                    <Flex justify={'flex-end'}>
                        <Button type="primary" htmlType="submit" size={'large'} aria-label={'Save'}>
                            {t('Save')}
                        </Button>
                    </Flex>
                </Form>
                <Divider orientation="left"></Divider>
                <Radio.Group value={currentLanguage} onChange={onChangeLanguage} buttonStyle="solid">
                    {
                        supportLanguage.map((language: string) => (
                            <Radio.Button key={language} value={language}>
                                <FlagIcon code={language === 'en' ? 'gb' : language} />
                            </Radio.Button>
                        ))
                    }
                </Radio.Group>
                <Divider orientation="left"></Divider>
                <FormPrices />
            </Spin>
        </div>
  );
};
