import * as React from 'react';
import { useRef } from 'react';
import styled from 'styled-components';
import {
  Col, Divider, Empty, Row,
} from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ServiceType } from '../../../common/types';
import { RootState } from '../store';
import { LANG_EN } from '../common';

interface IPriceBusiness {
  service: ServiceType
}

const DivStyled = styled.div`
  & .price_card {
    padding: 20px 0;
  }
  
  & .price_page__caption {
    font-size: 1.6rem;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }

  & .price_card__caption {
    font-size: 1.5rem;
    font-weight: bolder;
  }

  & .price_card__description {
    font-size: 1.1rem;
    margin-top: 10px;
  }

  & .price_card__amount {
    font-size: 1.6rem;
    font-weight: bolder;
  }

  @media (max-width: 768px) {
    & .price_card {
      padding: 10px 0;
    }
    
    & .price_page__caption,
    & .price_card__caption,
    & .price_card__amount {
      font-size: 1rem;
    }

    & .price_card__description{
      font-size: 0.9rem;
    }
  }
`;

export const RowStyled = styled(Row)`
  padding: 20px;
  align-items: center;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const PriceBusiness: React.FC<IPriceBusiness> = ({ service }) => {
  const { t } = useTranslation();
  const currentLanguage = useSelector((state: RootState) => state.app.currentLanguageForFormService);
  const languagePrefix = useRef<string>('');

  languagePrefix.current = currentLanguage === LANG_EN ? '' : `_${currentLanguage}`;

  return (
      <>
      {
        service.pricesForm && service.pricesForm.length
          ? <DivStyled className={'price_page'}>
                  <p className={'price_page__caption'}>{t('Price list')}</p>
                  <div className={'price_card'}>
                      {service.pricesForm.map((price, key) => (
                          <div key={key}>
                              <RowStyled>
                                  <Col className="gutter-row" xs={16} sm={16} md={18} lg={18} xl={18}>
                                      <p className={'price_card__caption'}>{(price as any)[`caption${languagePrefix.current}`]}</p>
                                      <p className={'price_card__description'}> {(price as any)[`description${languagePrefix.current}`]}</p>
                                  </Col>
                                  <Col className="gutter-row" xs={8} sm={8} md={6} lg={6} xl={6} style={{ textAlign: 'right' }}>
                                      <p className={'price_card__amount'}>CA${price.price}</p>
                                  </Col>
                              </RowStyled>
                              <Divider orientation="left"></Divider>
                          </div>
                      ))}
                  </div>
              </DivStyled>
          : <Empty description={'Sorry :( but we cannot find information about price.'}/>
      }
      </>
  );
};
