import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { COOKIE_ID_TOKEN, decodeToken, SIZE_IMAGE } from '../common';
import { selectLogin, selectUser } from '../store';
import { imageSizeTypeType } from '../../../common/types';

export const useUserPictUrl = (sizeImage: imageSizeTypeType) => {
  const user = useSelector(selectUser);
  const login = useSelector(selectLogin);
  const [userPictUrl, setUserPictUrl] = useState('');

  useEffect(() => {
    const token = Cookies.get(COOKIE_ID_TOKEN) || '';
    if (!token) {
      return;
    }

    const decodedToken = decodeToken(token);
    setUserPictUrl(`${process.env.REACT_APP_S3_STORAGE_URL}${process.env.REACT_APP_BUCKET}/${decodedToken.sub}/${SIZE_IMAGE[sizeImage]}${decodedToken.picture}`);
  }, [user, login]);

  return userPictUrl;
};
