import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
// import * as tresources2 from './i18n/resources2.json';
import enTranslation from './locales/en.json';
import vnTranslation from './locales/vn.json';
import uaTranslation from './locales/ua.json';

i18n.use(initReactI18next).use(detector).init({
  lng: 'en',
  fallbackLng: 'en',
  debug: true,
  resources: {
    en: {
      translation: enTranslation,
    },
    vn: {
      translation: vnTranslation,
    },
    ua: {
      translation: uaTranslation,
    },
  },
  returnEmptyString: false,
  // eslint-disable-next-line global-require
  // resources: (tresources as any).default,
  // compatibilityJSON: 'v3',
  // warnOnUpdate: false,
} as InitOptions);

export default i18n;
