import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Flex, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colors } from '../common/constStyles';
import { ScrollUp } from '../components/ScrollUp';
import { useCategory } from '../hooks/useGategory';
import { IconService } from '../IconService';
import { AppDispatch } from '../store';
import { CategoryServiceType } from '../../../common/types';
import { setterApp } from '../store/slices/appSlice';

const DivStyled = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  && .text_main {
    font-size: 4rem;
    font-weight: bold;
    padding: 0;
    line-height: 1.2;
  }

  && .text_main.regular {
    color: ${colors.colorTextDark};
  }

  && .text_second {
    font-size: 1.4rem;
    font-weight: 700;
    padding: 0;
    line-height: 1.5;
    color: ${colors.colorTextDark};
  }

  @media (max-width: 768px) {
    && .text_main {
      font-size: 2rem;
    }

    && .text_second {
      font-size: 1rem;
    }
  }
`;

const FlexStyled = styled(Flex)`
  padding: 40px 40px 0 40px;
  margin: 0 auto;
  
  && .text_main_second {
    font-size: 3rem;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    padding: 20px 20px 0 20px;

    && .text_main_second {
      font-size: 2rem;
    }
  }
`;

const TagStyled = styled(Tag)`
  cursor: pointer;
  font-size: 1.1em;
  padding: 10px;
  border: 1px solid ${colors.colorMain};
  color: ${colors.colorMain};
  background-color: #E6F4FF;

  &:hover {
    transform: scale(1.01);
    transition: all 0.1s ease-in 0s;
    border: 1px solid ${colors.colorTextLight};
  }
`;

export const AllServicesPage: React.FC = () => {
  const { t } = useTranslation();
  const { CATEGORY_SERVICES, GROUP_CATEGORY_SERVICES } = useCategory();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const onClickCategory = (category: CategoryServiceType) => {
    dispatch(setterApp({ currentCategory: category.category, currentGroupCategory: category.caption, filterCategory: category.category }));
    navigate(category.path);
  };

  return (
      <DivStyled>
          <Helmet>
              <title>All Services | ServiceIn.ca</title>
              <meta name="description" content="Find the best professional"/>
          </Helmet>
          <ScrollUp/>
          <FlexStyled vertical justify={'left'} gap={30}>
              <p className={'text_main_second'}>{t('All services')}</p>
              <div>

                  {
                      Object.keys(GROUP_CATEGORY_SERVICES).map((key) => {
                        const groupCategoryService = GROUP_CATEGORY_SERVICES[key];

                        return (<Flex key={key} gap={10} vertical style={{ marginBottom: '40px' }}>
                            <Flex align={'center'} gap={10}>
                                <IconService iconName={groupCategoryService.icon!} size={30} color={groupCategoryService.color}/>
                                <p className={'text_second'} >{groupCategoryService.caption}</p>
                            </Flex>
                            <Flex gap={20} wrap={'wrap'}>
                               {groupCategoryService.category.map((category) => (
                                 <div key={category} onClick={() => onClickCategory(groupCategoryService)}>
                                      <TagStyled key={category}>{CATEGORY_SERVICES[category]}</TagStyled>
                                </div>))}
                            </Flex>
                        </Flex>);
                      })
                  }
              </div>
          </FlexStyled>
      </DivStyled>

  );
};
