import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ListServiceResultApiType, ServiceType } from '../../../../common/types';
import { API, LANG_EN, paths } from '../../common';

export const initialService: ServiceType = {
  id: '',
  userId: '',
  isResource: false,
  isShowRequestButton: true,
  isAuthorAdmin: false,
  dateByUpdate: 0,
  dateByCreate: 0,
  dateByDelete: 0,
  status: 'offline',
  profile: {
    presentationPicture: '',
    presentationInfo: '',
    presentationInfo_ua: '',
    presentationInfo_vn: '',
    logo: '',
    avatar: '',
    companyName: '',
    companyName_ua: '',
    companyName_vn: '',
    category: '',
    establishDate: '',
    website: '',
    firstName: '',
    lastName: '',
    city: '',
    region: '',
    produce: [],
    produce_ua: [],
    produce_vn: [],
    tags: [],
    representativeName: '',
    representativeName_ua: '',
    representativeName_vn: '',
    parentCompany: '',
    parentCompany_ua: '',
    parentCompany_vn: '',
    supportLanguage: [LANG_EN],
    certification: '',
    license: '',
    bank: '',
    bank_vn: '',
    bank_ua: '',
    accountPeriod: '',
    accountPeriod_ua: '',
    accountPeriod_vn: '',
    shareholder: '',
    shareholder_vn: '',
    shareholder_ua: '',
    capital: '',
    employee: '',
    valueCustomer: '',
    valueCustomer_ua: '',
    valueCustomer_vn: '',
    socialLinks: {
      linkFacebook: '',
      linkInstagram: '',
      linkTiktok: '',
      linkTwitter: '',
      linkLinkedIn: '',
      linkYouTube: '',
    },
  },
  office: [],
  contact: [],
  about: {
    shortInfo: '',
    shortInfo_vn: '',
    shortInfo_ua: '',
    additionalInfo: '',
    businessDescription: '',
    businessDescription_ua: '',
    businessDescription_vn: '',
  },
  gallery: '',
  isPremium: false,
  pricesForm: [],
};

export interface IServiceState {
  serviceGlobalList: ServiceType[],
  serviceListAdv: ServiceType[],
  serviceList: ServiceType[],
  counterCategory: { [key: string]: number },
  servicePublic: ServiceType,
  service: ServiceType,
  serviceLoader: boolean,
  serviceNotFound: boolean,
}

const initialState: IServiceState = {
  serviceGlobalList: [],
  serviceListAdv: [],
  serviceList: [],
  counterCategory: {},
  service: initialService,
  servicePublic: initialService,
  serviceLoader: false,
  serviceNotFound: false,
};

export const fetchListService = createAsyncThunk('listService/fetch', async (id: string) => {
  const response = await axios.get(`${API.URL_REST_API}${paths.LIST_SERVICES}?userId=${id}`);
  // const response = { data: data.services.filter((serv) => serv.userId === id) };
  return response.data as any;
});

export const fetchPublicService = createAsyncThunk('public_service/fetch', async (param: { idAuthor: string, id: string }) => {
  try {
    const response = await axios.get(`${API.URL_REST_API}${paths.PUBLIC_SERVICE}/${param.idAuthor}/${param.id}`);
    return response.data as any;
  } catch (e) {
    return initialService;
  }
});

export const fetchPublicResource = createAsyncThunk('public_resource/fetch', async (param: { keyUrl: string }) => {
  try {
    const response = await axios.get(`${API.URL_REST_API}${paths.PUBLIC_RESOURCE}/${param.keyUrl}`);
    return response.data as any;
  } catch (e) {
    return initialService;
  }
});

export const fetchListServiceGlobal = createAsyncThunk('listServiceGlobal/fetch', async (searchParam?: string) => {
  const response = await axios.get(`${API.URL_REST_API}${paths.LIST_SERVICES}?${searchParam}`);
  return response.data as any;
});

export const fetchListServiceAdv = createAsyncThunk('listServiceAdv/fetch', async (searchParam?: string) => {
  const response = await axios.get(`${API.URL_REST_API}${paths.LIST_SERVICES}?${searchParam}`);
  return response.data as any;
});

// export const addService = createAsyncThunk('service/add', async (values: ServiceType) => {
//   console.log(values);
//   // const response = await axios.post(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.CUSTOMER}`, JSON.stringify(values));
//   // response.data
// });
//
// export const updateService = createAsyncThunk('service/update', async (payload: any) => {
//   console.log(payload);
//   // const response = await axios.post(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.CUSTOMER}/${payload.customerId}`, payload.data);
//   // response.data
// });

export const servicesSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setCurrentService: (state, action: PayloadAction<ServiceType>) => {
      state.service = action.payload;
    },
    setCurrentServicePublic: (state, action: PayloadAction<ServiceType>) => {
      state.servicePublic = action.payload;
    },
    addServiceToList: (state, action: PayloadAction<ServiceType>) => {
      state.serviceList = [...state.serviceList, action.payload];
    },
    delServiceFromList: (state, action: PayloadAction<string>) => {
      state.serviceList = state.serviceList.filter((service) => service.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListService.pending, (state) => {
      state.serviceLoader = true;
    });

    builder.addCase(fetchListService.fulfilled, (state, action: PayloadAction<ListServiceResultApiType>) => {
      for (const key of Object.keys(action.payload)) {
        if (key === 'listServices') {
          state.serviceList = (action.payload as any)[key];
        } else {
          (state as any)[key] = (action.payload as any)[key];
        }
      }
      state.serviceLoader = false;
    });

    builder.addCase(fetchPublicService.pending, (state) => {
      state.serviceLoader = true;
    });

    builder.addCase(fetchPublicService.fulfilled, (state, action: PayloadAction<ServiceType>) => {
      if (!action.payload.id) {
        state.serviceNotFound = true;
      } else {
        state.serviceNotFound = false;
      }
      state.servicePublic = action.payload;
      state.serviceLoader = false;
    });

    builder.addCase(fetchPublicResource.pending, (state) => {
      state.serviceLoader = true;
    });

    builder.addCase(fetchPublicResource.fulfilled, (state, action: PayloadAction<ServiceType>) => {
      if (!action.payload.id) {
        state.serviceNotFound = true;
      } else {
        state.serviceNotFound = false;
      }
      state.servicePublic = action.payload;
      state.serviceLoader = false;
    });

    builder.addCase(fetchListServiceGlobal.pending, (state) => {
      state.serviceLoader = true;
    });

    builder.addCase(fetchListServiceGlobal.fulfilled, (state, action: PayloadAction<ListServiceResultApiType>) => {
      for (const key of Object.keys(action.payload)) {
        if (key === 'listServices') {
          state.serviceGlobalList = (action.payload as any)[key];
        } else {
          (state as any)[key] = (action.payload as any)[key];
        }
      }
      state.serviceLoader = false;
    });

    builder.addCase(fetchListServiceAdv.fulfilled, (state, action: PayloadAction<ListServiceResultApiType>) => {
      state.serviceListAdv = action.payload.listServices;
      state.serviceLoader = false;
    });
    // builder.addCase(fetchService.fulfilled, (state, action: PayloadAction<CustomerType>) => {
    //   state.customer = action.payload;
    // });
    // builder.addCase(fetchService.fulfilled, (state, action: PayloadAction<ServiceType>) => {
    //   state.service = action.payload;
    // });
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrentService,
  addServiceToList,
  delServiceFromList,
  setCurrentServicePublic,
} = servicesSlice.actions;

export default servicesSlice.reducer;
