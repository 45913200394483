import React from 'react';

interface ITabPanel {
  children?: React.ReactNode;
  index: number;
  currentTab: number;
}

export const TabPanel: React.FC<ITabPanel> = ({
  children, currentTab, index, ...other
}) => (
      <div
          role="tabpanel"
          hidden={currentTab !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {currentTab === index && (
            <div style={{ padding: 3 }}>
              {children}
            </div>
        )}
      </div>
);
