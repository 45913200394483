import { configureStore } from '@reduxjs/toolkit';
import spinnerReducer from './slices/spinnerSlice';
import settingsReducer from './slices/settingsSlice';
import appReducer from './slices/appSlice';
import servicesReducer from './slices/servicesSlice';
import postReducer from './slices/postsSlice';
import questionReducer from './slices/questionsSlice';
import userReducer from './slices/userSlice';

export const store = configureStore({
  reducer: {
    spinner: spinnerReducer,
    language: settingsReducer,
    user: userReducer,
    app: appReducer,
    services: servicesReducer,
    post: postReducer,
    question: questionReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const selectLanguage = (state: RootState) => state.language.lang;
export const selectUser = (state: RootState) => state.user;
export const selectLogin = (state: RootState) => state.language.login;
