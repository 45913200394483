import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import { paths } from '../../../common';

export const NotFoundPage = () => {
  const history = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button type="primary" onClick={() => { history(paths.MAIN); }} aria-label={'Back home'}>Back Home</Button>}
    />
  );
};
