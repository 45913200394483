import * as React from 'react';
import { Flex } from 'antd';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const FlexStyled = styled(Flex)`
  padding: 40px 40px 0 40px;
  margin: 0 auto;

  .text_slogan{
    font-size: 26px;
    font-weight: 700
  }
  

  @media (max-width: 768px) {
    padding: 20px 20px 0 20px;
    
    .text_slogan{
      font-size: 18px;
      font-weight: 500
    }
  }
`;

export const HomeTextBlockMain: React.FC = () => {
  const { t } = useTranslation();
  return (
      <FlexStyled className={''} vertical>
          <span className={'text_slogan'}>{t('Find')}. {t('Connect')}. {t('Collaborate')}.</span>
          <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.9 }}
          >
           <h1 className={'text_main accent'}>{t('Online platform')}</h1>
           <h1 className={'text_main regular'}>{t('for local services')}</h1>
              </motion.div>
          <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.9 }}
          >
      </motion.div>
      </FlexStyled>
  );
};
