import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tag } from 'antd';
import { setterApp } from '../../store/slices/appSlice';
import { AppDispatch, RootState } from '../../store';
import { CategoryServiceType } from '../../../../common/types';
import { IconService } from '../../IconService';

interface ICardCategoryService {
  groupCategoryService: CategoryServiceType,
}

export const FolderCategoryService: React.FC<ICardCategoryService> = ({ groupCategoryService }) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentGroupCategory = useSelector<RootState, string>((state) => state.app.currentGroupCategory);
  // const counterCategory = useSelector<RootState, { [key: string]: number }>((state) => state.services.counterCategory);

  const isActive = currentGroupCategory === groupCategoryService.caption;
  const handleItemClick = (key: string[], caption: string) => () => {
    dispatch(setterApp({ currentCategory: key, currentGroupCategory: caption, filterCategory: key }));
  };

  return <Tag
        key={groupCategoryService.caption}
        style={{
          margin: '5px',
          fontSize: 14,
          padding: '3px 10px',
          cursor: 'pointer',
        }}
        color={isActive ? 'processing' : 'default'}
        onClick={handleItemClick(groupCategoryService.category, groupCategoryService.caption)}
        icon={<IconService iconName={groupCategoryService.icon!} size={15}/>}

    >
        <span style={{ marginLeft: 5 }}>{groupCategoryService.caption}</span>
    </Tag>;
};
