import * as React from 'react';
import { Typography, Avatar } from 'antd';
import {
  UserOutlined,
} from '@ant-design/icons/lib';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectLogin } from '../../store';

const { Text } = Typography;

type AvatarUserProps = {
  showLogin?: boolean
  size?: number,
  imgSrc?: string
};

const SpanStyle = styled(Text)`
  margin-left: 10px;
  &:hover {
    color: var(--primary-color);
  }
`;

export const AvatarUser: React.FC<AvatarUserProps> = ({ showLogin, size, imgSrc }) => {
  const login = useSelector(selectLogin);

  return (
        <>
            <Avatar src={imgSrc} icon={<UserOutlined/>} size={size}/>
            {
                showLogin && (<SpanStyle>{login}</SpanStyle>)
            }
        </>
  );
};
