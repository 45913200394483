import * as React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Flex } from 'antd';
import { paths } from '../common';

type LogoTypeType = 'full' | 'short';

type LogoStyleType = {
  type?: LogoTypeType
};
type LogoProps = {
  type?: LogoTypeType,
  margin?: string
};

const LogoStyle = styled.a<LogoStyleType>`
  display: block;
  background: transparent url(${(props) => (props.type === 'full' ? '/newcamer-logo.svg' : '/newcamer-logo-short.svg')}) no-repeat 0 0;
  text-indent: -9999px;
  margin: 0 auto;
  width: ${(props) => (props.type === 'full' ? '200px' : '64px')};
  height: ${(props) => (props.type === 'full' ? '50px' : '48px')};
  //background-size: cover;
  background-position-y: bottom;
`;

export const Logo: React.FC<LogoProps> = (props) => {
  const history = useNavigate();
  const location = useLocation();

  const onClick = useCallback(() => {
    if (location.pathname.includes(paths.PUBLIC_SERVICE)
        || location.pathname.includes(paths.PUBLIC_RESOURCE)) {
      window.history.back();
    } else { history(paths.MAIN); }
  }, [location, history]);

  return <Flex style={{ margin: props.margin }} className="logo" align={'center'}>
      <LogoStyle href={'#'} type={props.type} onClick={onClick}>ServiceIn</LogoStyle>
    </Flex>;
};
