import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Tab, Tabs } from '@mui/material';
import { NotificationOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { ProfileInfo } from '../components/ProfileInfo';
import { ServicesPage } from '../components/ServicesPage';
import { addSpinnerItem, removeSpinnerItem } from '../store/slices/spinnerSlice';
import { fetchListService } from '../store/slices/servicesSlice';
import { AppDispatch } from '../store';
import { COOKIE_ID_TOKEN, decodeToken } from '../common';
import { TabPanel } from '../components/TabPanel';
import { NotificationsPage } from '../components/NotificationsPage';
import { NotFoundPage } from './NotFound';

const DivStyled = styled.div`
  padding: 20px;

  & .css-tefn1p-MuiButtonBase-root-MuiTab-root{
  min-height: 40px;
}
  
  @media (max-width: 768px) {
    padding: 10px;
`;

export const UserProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [currentTab, setCurrentTab] = useState(0);

  const token = Cookies.get(COOKIE_ID_TOKEN) || '';
  const decodedToken = decodeToken(token);

  useEffect(() => {
    (async () => {
      if (!decodedToken?.['cognito:username']) {
        return;
      }
      dispatch(addSpinnerItem('service_detail'));
      await dispatch(fetchListService(decodedToken['cognito:username'])); // userId
      dispatch(removeSpinnerItem('service_detail'));
    })();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <DivStyled>
      {
        decodedToken?.['cognito:username']
          ? <>
            <Row style={{ height: '100%' }}>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example">
                  <Tab icon={<UserOutlined />} iconPosition="start" label={t('Profile')} />
                  <Tab icon={<ShopOutlined />} iconPosition="start" label={t('Your Services')} />
                  <Tab icon={<NotificationOutlined />} iconPosition="start" label={t('Notifications')} />
                </Tabs>
              </Col>
            </Row>
            <Row>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24} style={{ height: '100%' }}>
                <TabPanel currentTab={currentTab} index={0}>
                  <ProfileInfo />
                </TabPanel>
                <TabPanel currentTab={currentTab} index={1}>
                  <ServicesPage />
                </TabPanel>
                <TabPanel currentTab={currentTab} index={2}>
                  <NotificationsPage />
                </TabPanel>
              </Col>
            </Row>
            </>
          : <NotFoundPage/>
      }
    </DivStyled>
  );
};
