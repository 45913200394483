import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Flex, Row } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useIsFocusVisible } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { colors } from '../common/constStyles';
import { RoleType } from '../../../common/types';
import { ButtonOpenModal } from './Modal/ButtonOpenModal';
import {
  COOKIE_REGISTRATION_CONFIRMED_EMAIL,
  COOKIE_REGISTRATION_EMAIL,
  COOKIE_REGISTRATION_FIRST_LOGIN,
  COOKIE_REGISTRATION_ROLE,
  paths,
  TRUE_STRING,
} from '../common';
import { CardStyled } from './Cards/CardReasonJoin';
import { ButtonLogin } from './Buttons/ButtonLogin';
import { selectLogin } from '../store';
import { useResizeWidthWindow } from '../hooks/useResizeWidthWindow';

const FlexStyled = styled(Flex)`
  padding: 20px 15px;
  margin: 0 auto;
  white-space: pre-line;
  text-align: justify;
  width: ${() => (useResizeWidthWindow() < 700 ? '100%' : '70%')};
  
  .step_block{
    padding-right: 10px;
  }
  
  .step_num{
    color: ${colors.colorMain};
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .step_caption{
    font-size: 1.1em;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .step_description{
    font-size: 1.2em;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

interface IStepsRegistrationUser{
  role: RoleType;
}

export const StepsRegistrationUser: React.FC<IStepsRegistrationUser> = ({ role }) => {
  const { t } = useTranslation();
  const isFocused = useIsFocusVisible();
  const [isReadyFirstStep, setIsReadyFirstStep] = useState(false);
  const [isReadySecondStep, setIsReadySecondStep] = useState(false);
  const [isReadyThirdStep, setIsReadyThirdStep] = useState(false);
  const history = useNavigate();
  const login = useSelector(selectLogin);

  useEffect(() => {
    if (Cookies.get(COOKIE_REGISTRATION_ROLE) !== role) {
      Cookies.set(COOKIE_REGISTRATION_ROLE, role);
    }
  }, [role]);

  useEffect(() => {
    if (login) {
      history(paths.USER_PROFILE);
    }
  }, [isFocused]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (Cookies.get(COOKIE_REGISTRATION_ROLE) === role) {
        setIsReadyFirstStep(!!Cookies.get(COOKIE_REGISTRATION_EMAIL));
        setIsReadySecondStep(Cookies.get(COOKIE_REGISTRATION_CONFIRMED_EMAIL) === TRUE_STRING);
        setIsReadyThirdStep(Cookies.get(COOKIE_REGISTRATION_FIRST_LOGIN) === TRUE_STRING);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const checkCircle = <CheckCircleOutlined style={{
    fontSize: 40,
    position: 'absolute',
    top: -10,
    left: -15,
    color: colors.colorGreenSuccess,
    backgroundColor: 'white',
    borderRadius: '50%',
  }} />;

  const resetProcess = () => {
    setIsReadyFirstStep(false);
    setIsReadySecondStep(false);
    Cookies.set(COOKIE_REGISTRATION_EMAIL, '');
    Cookies.set(COOKIE_REGISTRATION_CONFIRMED_EMAIL, '');
  };

  return (
  <>
      <FlexStyled vertical gap={20}>
          <CardStyled variant="outlined" style={{
            overflow: 'visible',
            borderWidth: isReadyFirstStep ? 2 : 1,
            backgroundColor: isReadyFirstStep ? colors.colorGreenSuccessDark : 'white',
            borderColor: isReadyFirstStep ? colors.colorGreenSuccess : colors.colorGreyDark,
          }}>
              <Row>
                  <Col className="gutter-row" xs={15} sm={19} md={18} lg={18} xl={18}>
                      <div className={'step_block'}>
                          <p className={'step_num'} style={{ color: isReadyFirstStep ? 'green' : `${colors.colorMain}` }}>{t('STEP ONE')}</p>
                          <p className={'step_caption'}>{t('Sign Up')}</p>
                      </div>
                  </Col>
                  <Col className="gutter-row" xs={9} sm={5} md={6} lg={6} xl={6} style={{ textAlign: 'right' }}>
                      <ButtonOpenModal mode={'button'} disabled={isReadyFirstStep} title={t('Sign up')} type={'registration'} />
                  </Col>
              </Row>
              <Row>
                  <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className={'step_block'}>
                          <span className={'step_description'}>{t('To register, click on the "Sign up" button')}. </span>
                          <span className={'step_description'}>{t('In the window, enter your email address and password')}.</span>
                      </div>
                  </Col>
              </Row>
              {
                  isReadyFirstStep && checkCircle
              }
          </CardStyled>
          <CardStyled variant="outlined" style={{
            overflow: 'visible',
            borderWidth: isReadySecondStep ? 2 : 1,
            backgroundColor: isReadySecondStep ? colors.colorGreenSuccessDark : 'none',
            borderColor: isReadySecondStep ? colors.colorGreenSuccess : 'none',
          }}>
              <Row>
                  <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className={'step_block'}>
                          <p className={'step_num'} style={{ color: isReadySecondStep ? 'green' : `${colors.colorMain}` }}>{t('STEP TWO')} </p>
                          <p className={'step_caption'}>{t('Confirm your email address')}</p>
                          <p className={'step_description'}>{t('Check your email and click on the link inside the email')}.</p>
                          {
                              isReadyFirstStep && !isReadySecondStep
                            && <p className={'step_description'}>{t('If you don\'t receive an email please click the link')} {<a onClick={resetProcess} style={{ textDecoration: 'underline', color: colors.colorMain, fontWeight: 'bold' }} >Reset process and Start again</a>}</p>
                          }
                      </div>
                  </Col>
              </Row>
              {
                  isReadySecondStep && checkCircle
              }
          </CardStyled>
          <CardStyled variant="outlined" style={{
            overflow: 'visible',
            borderWidth: isReadyThirdStep ? 2 : 1,
            backgroundColor: isReadyThirdStep ? colors.colorGreenSuccessDark : 'none',
            borderColor: isReadyThirdStep ? colors.colorGreenSuccess : 'none',
          }}>
              <Row>
                  <Col className="gutter-row" xs={15} sm={19} md={18} lg={18} xl={18}>
                      <div className={'step_block'}>
                          <p className={'step_num'} style={{ color: isReadyThirdStep ? 'green' : `${colors.colorMain}` }}>{t('STEP THREE')}</p>
                          <p className={'step_caption'}>{t('Login with your credentials')}</p>
                          <p className={'step_description'}>
                              {t('Click on the button to login in your account')}.</p>
                      </div>
                  </Col>
                  <Col className="gutter-row" xs={9} sm={5} md={6} lg={6} xl={6} style={{ textAlign: 'right' }}>
                      <ButtonLogin disabled={!isReadySecondStep || isReadyThirdStep} withIcon={false}/>
                  </Col>
              </Row>
              {
                  isReadyThirdStep && checkCircle
              }
          </CardStyled>
          {
              role === 'provider' && <CardStyled variant="outlined" style={{
                overflow: 'visible',
              }}>
                  <Row>
                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                          <div className={'step_block'}>
                              <p className={'step_num'}>{t('STEP FOUR')} </p>
                              <p className={'step_caption'}>{t('Create a profile of your business')}</p>
                              <p className={'step_description'}>{t('Provide detailed information about your service, add a description, images and contact details')}. {t('Start growing your businesses right now!')}</p>
                          </div>
                      </Col>
                  </Row>
              </CardStyled>
          }
      </FlexStyled>
  </>
  );
};
