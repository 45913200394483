import React, { useEffect, useState } from 'react';
import {
  Button, Col, Empty, Flex, Row,
} from 'antd';
import { LeftCircleOutlined, PlusCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { green } from '@ant-design/colors';
import { useTranslation } from 'react-i18next';
import { GridView, ViewList } from '@mui/icons-material';
import { FormService } from './Forms/FormService';

import { AppDispatch, RootState } from '../store';
import { ServiceType } from '../../../common/types';
import { addServiceToList, initialService, setCurrentService } from '../store/slices/servicesSlice';
import { COOKIE_ID_TOKEN, decodeToken } from '../common';
import { colors } from '../common/constStyles';
import { useResizeWidthWindow } from '../hooks/useResizeWidthWindow';
import { ScrollUp } from './ScrollUp';
import { UserServiceCardForAdmin } from './UserServiceCardForAdmin';
import { UserServiceCard } from './UserServiceCard';

export const DivStyled = styled.div`
  border: 1px solid ${colors.colorBorder};
`;

export const ServicesPage: React.FC = () => {
  const widthWindow = useResizeWidthWindow();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const serviceList = useSelector<RootState, ServiceType[]>((state) => state.services.serviceList);
  const service = useSelector<RootState, ServiceType>((state) => state.services.service);
  const [isSave, setIsSave] = useState(false);
  const [viewCard, setViewCard] = useState<'small' | 'big'>('big');

  const token = Cookies.get(COOKIE_ID_TOKEN) || '';
  const decodedToken = decodeToken(token);

  const initService = () => {
    const newService: ServiceType = {
      ...initialService,
      id: uuidv4(),
      userId: decodedToken.sub,
    };
    dispatch(setCurrentService(newService));
  };

  useEffect(() => {
    if (isSave) {
      dispatch(addServiceToList(service));
      setIsSave(false);
    }
  }, [isSave]);

  const addService = () => {
    initService();
    setIsSave(true);
  };

  const backToAllService = () => {
    const newService: ServiceType = {
      ...initialService,
    };
    dispatch(setCurrentService(newService));
  };

  const onClickSwitch = (type: 'small' | 'big') => () => {
    setViewCard(type);
  };

  return <DivStyled>
        <ScrollUp data={service.id}/>
        <Row gutter={20} style={{ margin: '1.5em 0' }}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}
                 style={{ borderRight: `1px solid ${colors.colorBorder}`, padding: '10px' }}>
                <Flex gap={20} vertical={true}>
                    {
                        (widthWindow < 768 && service.id)
                        && <Flex justify={'center'}>
                            <Button type={'primary'} style={{ backgroundColor: green[7] }} icon={<LeftCircleOutlined/>}
                                    onClick={backToAllService}
                                    aria-label={'Back to service'}>{t('Back to services')}</Button>
                        </Flex>
                    }
                    {
                        !(widthWindow < 768 && service.id) && serviceList.length >= 3
                        && <Flex justify={'space-between'}>
                                <Button type={'primary'} icon={<PlusCircleFilled/>} onClick={addService}
                                        aria-label={'Add service'}>{t('Add service')}</Button>
                                <Flex gap={10}>
                                    <Button className={'grid_view'}
                                            type={viewCard === 'big' ? 'primary' : 'default'}
                                            ghost={viewCard === 'big'}
                                            icon={<GridView/>} size={'large'}
                                            onClick={onClickSwitch('big')}>
                                    </Button>
                                    <Button className={'grid_view'}
                                            type={viewCard === 'small' ? 'primary' : 'default'}
                                            ghost={viewCard === 'small'}
                                            icon={<ViewList/>} size={'large'}
                                            onClick={onClickSwitch('small')}>
                                    </Button>
                                </Flex>
                        </Flex>
                    }
                    {
                        !(widthWindow < 768 && service.id)
                        && <Flex vertical={true} gap={20}>
                            {!serviceList.length && <Empty/>}
                            {serviceList.length && [...serviceList]
                              .sort((a, b) => {
                                if (!a.dateByCreate) {
                                  return 1;
                                }
                                if (!b.dateByCreate) {
                                  return -1;
                                }
                                return b.dateByCreate - a.dateByCreate;
                              })
                              .map((subService) => (viewCard === 'small'
                                ? <UserServiceCardForAdmin key={subService.id} service={subService}/>
                                : <UserServiceCard key={subService.id} service={subService}/>))}
                        </Flex>
                    }
                    {
                        !(widthWindow < 768 && service.id)
                        && <Flex justify={'center'}>
                            <Button type={'primary'} icon={<PlusCircleFilled/>} onClick={addService}
                                    aria-label={'Add service'}>{t('Add service')}</Button>
                        </Flex>
                    }
                </Flex>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                {
                    !serviceList.length || !service.userId
                      ? (widthWindow > 768) && <Empty/>
                      : (widthWindow > 768 || service.id) && <FormService/>
                }
            </Col>
        </Row>
    </DivStyled>;
};
