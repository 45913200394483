import * as React from 'react';
import { useCallback } from 'react';
import { Button, Flex } from 'antd';
import { Link } from 'react-router-dom';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { motion } from 'framer-motion';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { COOKIE_ID_TOKEN, paths } from '../../common';

interface IButtonRegistration{
  size?: SizeType,
  withIcon?: boolean
}

export const ButtonRegistration: React.FC<IButtonRegistration> = ({ size, withIcon }) => {
  const { t } = useTranslation();
  const onClick = useCallback(() => {

  }, []);

  const token = Cookies.get(COOKIE_ID_TOKEN) || '';

  return <>
        <Link to={token ? paths.USER_PROFILE : paths.REGISTRATION_PROVIDER} onClick={onClick}>
            <Button type={'primary'} size={size} style={{ whiteSpace: 'pre-wrap', height: '100%' }} aria-label={'Sign up'}>
                <Flex align={'center'} gap={5}>
                    <span>{t('Sign up for free')}</span>
                    <motion.span
                        animate={{
                          x: [10, 0, 10],
                        }}
                        transition={{
                          duration: 2,
                          repeat: Infinity,
                          ease: 'linear',
                        }}
                    >
                        { withIcon && <ArrowRightOutlined /> }
                    </motion.span>
                </Flex>
            </Button>
        </Link>
    </>;
};
