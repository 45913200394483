import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useResizeWidthWindow } from '../hooks/useResizeWidthWindow';

const RowStyled = styled(Row)`
  padding: 40px;
  
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const RegistrationHeaderProvider: React.FC = () => {
  const { t } = useTranslation();

  return (
          <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
              <RowStyled align={'middle'}>
                  <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}
                       style={{ margin: '0 auto 20px' }}>
                      <span className={'regular'} style={{ fontSize: '26px', fontWeight: 700 }}>{t('Boost your web presence')}.</span>
                      <h1 className={'text_main regular'}>{t('Grow your business')}. {t('Get Started!')} </h1>
                  </Col>
                  <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}
                       style={{ textAlign: 'center', padding: '0 40px' }}>
                      <img src={'/reg_provider.png'} alt={'b2b'} width={useResizeWidthWindow() > 800 ? '70%' : '50%'} loading="lazy"/>
                  </Col>
              </RowStyled>
          </div>
  );
};
