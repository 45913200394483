import * as React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import styled from 'styled-components';
import FlagIconFactory from 'react-flag-icon-css';
import { Button, Flex } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ServiceType } from '../../../../common/types';
import { paths, SIZE_IMAGE } from '../../common';
import { useCategory } from '../../hooks/useGategory';
import { getReadableUrl } from '../../common/functions';
import { AvatarServiceCard } from '../Image/AvatarServiceCard';

interface IQuestionCard {
  service: ServiceType
}

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

const CardStyled = styled(Card)`
  width: 250px;
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: none;
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  &:hover{
    transform: scale(1.01);
    transition: all 0.1s ease-in 0s;
  }
  
  .company_name{
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
  }
`;

export const QuestionCard: React.FC<IQuestionCard> = ({ service }) => {
  const { CATEGORY_SERVICES } = useCategory();
  const { t } = useTranslation();

  const imageSrcAvatar = `${process.env.REACT_APP_S3_STORAGE_URL}${process.env.REACT_APP_BUCKET}/${service.userId}/${SIZE_IMAGE['124']}${service.profile.avatar}`;

  return <>
        <Link to={`${paths.PUBLIC_RESOURCE}/${getReadableUrl(service.profile.companyName)}`} target="_blank">
            <CardStyled variant="outlined" sx={{ border: 'none' }}>
                    <CardContent sx={{ paddingTop: 0 }}>
                        <Flex align={'center'} justify={'space-between'}>
                            <div style={{ marginRight: '10px' }}>
                                <AvatarServiceCard
                                    imgSrc={imageSrcAvatar}
                                    size={46}/>
                            </div>
                            <Flex vertical align={'end'}>
                                <p className={'company_name'}>{service.profile.companyName}</p>
                                <p className={'info'}>{CATEGORY_SERVICES[+service.profile.category]}</p>
                            </Flex>
                        </Flex>
                        <Flex gap={5} justify={'space-between'} style={{ marginTop: 20 }}>
                            {service.profile.supportLanguage!.map((language) => (
                                <FlagIcon key={language} code={language}/>
                            ))}
                            <Button icon={<ExportOutlined /> } size={'small'} ghost={true} type={'primary'} aria-label={'Open'}>
                                {t('Open')}
                            </Button>
                        </Flex>
                    </CardContent>
            </CardStyled>
        </Link>
    </>;
};
