import * as React from 'react';
import { Badge } from 'antd';
import { OfficeType, ServiceType } from '../../../../common/types';
import { MiniCardStyledGridContent } from './MiniCardStyledGridContent';

export interface IMiniCardBusiness {
  service: ServiceType,
  firstOffice: OfficeType
  imageSrcAvatar: string
}

export const MiniCardStyledGrid: React.FC<IMiniCardBusiness> = ({
  service, firstOffice, imageSrcAvatar,
}) => (
      <div style={{ minWidth: 350, width: '30%', cursor: 'pointer' }}>
        {
            service.isResource
              ? <Badge.Ribbon text="Resource" placement={'start'}>
                  <MiniCardStyledGridContent service={service} firstOffice={firstOffice} imageSrcAvatar={imageSrcAvatar} />
                </Badge.Ribbon>
              : <MiniCardStyledGridContent service={service} firstOffice={firstOffice} imageSrcAvatar={imageSrcAvatar} />
        }
      </div>
);
