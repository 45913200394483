import * as React from 'react';
import { useEffect } from 'react';
import {
  Button, Flex, Form, Input,
} from 'antd';
import { Mail, Phone } from '@mui/icons-material';
import { Card, CardContent } from '@mui/material';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { MaskedInput } from 'antd-mask-input';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { ContactType, ServiceType } from '../../../../common/types';
import { RootState } from '../../store';
import { ButtonConfirmationDelete } from '../Buttons/ButtonConfirmationDelete';
import { useSaveData } from '../../hooks/useSaveData';

interface IFormContact {
  contact: ContactType
}

export const initialValuesFofFormContact = {
  telephone: '',
  email: '',
} as (ContactType);

const CardStyled = styled(Card)`
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: none;
  position: relative;
  width: 300px;
`;

export const FormContact: React.FC<IFormContact> = ({ contact }) => {
  const {
    RenderMessageError, saveData, SpinnerSaveData, contextHolder,
  } = useSaveData();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const service = useSelector<RootState, ServiceType>((state) => state.services.service);

  useEffect(() => {
    form.setFieldsValue({
      ...contact,
    } as ContactType);
  }, [contact]);

  const preparedData = (values: any): ServiceType => {
    const oldContacts = service.contact || [];
    const isEdit = oldContacts.filter((subContact) => subContact.id === contact.id).length;

    let newContacts: ContactType[] = [];

    if (isEdit) {
      newContacts = [...oldContacts].map((subContact) => {
        if (subContact.id === contact.id) {
          return { ...contact, ...values };
        }
        return subContact;
      });
    } else {
      newContacts = [...oldContacts, { ...contact, ...values }];
    }

    return {
      ...service,
      contact: newContacts,
    };
  };

  const preparedDeleteContact = (): ServiceType => ({
    ...service,
    contact: service.contact.filter((subContact) => subContact.id !== contact.id),
  });

  const onFinish = async (values: any) => {
    const newService = preparedData(values);
    await saveData(newService, 'contact');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const deleteContact = async () => {
    const newService = preparedDeleteContact();
    await saveData(newService, 'contact');
  };

  return <Flex vertical={true} gap={30}>
      {contextHolder}
        <CardStyled variant="outlined">
            <CardContent>
                <SpinnerSaveData >
                    <Form
                        name={`ContactService${contact?.id}`}
                        style={{ width: '100%' }}
                        initialValues={{ initialValuesFofFormContact }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        labelAlign={'left'}
                        size={'large'}
                        form={form}
                    >
                        <Form.Item<ContactType>
                            name="telephone"
                            key={uuidv4()}
                        >
                            <MaskedInput
                                addonBefore={<Phone color={'primary'}/>}
                                size={'large'}
                                mask={
                                    '+1(000)000-0000'
                                }
                                type={'tel'}
                            />
                        </Form.Item>
                        <Form.Item<ContactType>
                            name="email"
                            rules={[
                              {
                                type: 'email',
                                message: t('The input is not valid E-mail!'),
                              },
                            ]}
                        >
                            <Input type={'email'} addonBefore={<Mail color={'primary'}/>} maxLength={40}/>
                        </Form.Item>
                        <RenderMessageError />
                        <Flex justify={'flex-end'} gap={'small'} align={'center'}>
                            <ButtonConfirmationDelete
                                typeButton={'btn'}
                                callbackOk={deleteContact}
                            />
                            <Button type="primary" htmlType="submit" size={'large'} aria-label={'Save'}>
                                {t('Save')}
                            </Button>
                        </Flex>
                    </Form>
                </SpinnerSaveData>
            </CardContent>
        </CardStyled>
    </Flex>;
};
