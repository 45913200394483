import React, { Dispatch, SetStateAction, useCallback } from 'react';
import {
  Form, FormInstance, Select, Tag,
} from 'antd';

import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import FlagIconFactory from 'react-flag-icon-css';
import { useTranslation } from 'react-i18next';
import { LANG_EN, SUPPORT_LANGUAGE } from '../common';
import { ServiceAboutType, ServiceProfileType } from '../../../common/types';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

interface ILanguagePanel{
  setSupportLanguage: Dispatch<SetStateAction<string[]>>,
  form: FormInstance<any>,
}

export const LanguageField: React.FC<ILanguagePanel> = ({ setSupportLanguage, form }) => {
  const { t } = useTranslation();
  const tagRender = (props: CustomTagProps) => {
    const {
      label, value, closable, onClose,
    } = props;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
        <Tag
            icon={<FlagIcon code={value === 'en' || !value ? 'gb' : value} />}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 5, padding: 3 }}
        >
          {`  ${label}`}
        </Tag>
    );
  };

  const onChangeLanguage = useCallback((value: string[]) => {
    setSupportLanguage((oldValue) => {
      if (!value.includes(LANG_EN)) {
        setTimeout(() => {
          form.setFieldsValue({ supportLanguage: oldValue });
        }, 0);

        return oldValue;
      }
      return value;
    });
  }, []);

  return (
        <Form.Item<ServiceProfileType & ServiceAboutType>
            label={t('Support Language')}
            name="supportLanguage"
            rules={[
              { required: true, message: t('Please, fill this field!') },
              // () => ({
              //   validator(_, value) {
              //     if (value.includes(LANG_EN)) {
              //       return Promise.resolve();
              //     }
              //     return Promise.reject(new Error('English is required. You cannot delete it!'));
              //   },
              // }),
            ]}
        >
            <Select
                mode={'multiple'}
                style={{ width: '100%' }}
                tagRender={tagRender}
                onChange={onChangeLanguage}
                allowClear
                options={
                    Object.keys(SUPPORT_LANGUAGE).map((key) => ({
                      value: key,
                      label: SUPPORT_LANGUAGE[key],
                    }))
                }
            />
        </Form.Item>
  );
};
