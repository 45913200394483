import styled from 'styled-components';
import { useResizeWidthWindow } from '../hooks/useResizeWidthWindow';

type PanelShadowType = {
  width?: string;
};

export const PanelShadow = styled.div<PanelShadowType>`
  //position: absolute;
  //top: calc(50% - 300px / 2);
  //left: 50%;
  //margin: -160px 0 0 calc(${(props) => props.width || '400px'} / 2 * -1);
  margin: 0 auto;
  //width: ${(props) => props.width || '400px'};
  width: ${(props) => props.width || (useResizeWidthWindow() > 600 ? '500px' : '400px')};
  padding: 50px 25px;
  box-shadow: 0 0 10px rgba(0,0,0,.08);
  background-color: white;

  @media (max-width: 450px) {
    width: 90%;
  }
  
`;
