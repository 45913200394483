import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  Alert, Col, Modal, Row, Spin,
} from 'antd';
import { Tab, Tabs } from '@mui/material';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { LanguageType, ServiceData, ServiceType } from '../../../common/types';
import {
  COMPANY_SIZE, COOKIE_ID_TOKEN, decodeToken, LANG_EN, paths,
} from '../common';
import { ContactForm } from './Forms/ContactForm';
import { AdvBlock } from './AdvBlock';
import { AppDispatch, RootState } from '../store';
import {
  fetchPublicResource,
  fetchPublicService,
  initialService,
  setCurrentServicePublic,
} from '../store/slices/servicesSlice';
import { ScrollUp } from './ScrollUp';
import { NotFoundPage } from '../pages/NotFound';
import { setterApp } from '../store/slices/appSlice';
import { colors } from '../common/constStyles';
import { TabProfile } from './TabProfile';
import { TabGallery } from './TabGallery';
import { TabPrices } from './TabPrices';
import { TabContact } from './TabContact';
import { FullCardHeaderService } from './FullCardHeaderService';
import { TabPanel } from './TabPanel';
import { getReadableUrl } from '../common/functions';

// const ModalDivStyled = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 400px;
//   background-color: #fff;
//   box-shadow: #2B3546;
//   padding: 20px;
//   border-radius: 4px;
// `;

const TabStyled = styled(Tab)`
  font-weight: 600;
`;

const RowStyled = styled(Row)`
  padding: 40px;
  flex-direction: column;
  gap: 20px;

  && .header_fullcard {
    background-color: rgb(236, 242, 255);
    border-radius: 12.25px;
    padding: 30px 25px 20px;
  }

  && .header_fullcard__content {
    width: calc(100% - 60px);
  }

  && .text_header {
    font-size: 2.2rem;
    //font-weight: bold;
    padding: 0;
    line-height: 1.2;
  }

  && .text_subheader {
    font-size: 1.5rem;
    //font-weight: bold;
    padding: 0;
    line-height: 1.2;
  }

  && .ant-radio-button-wrapper {
    height: min-content;
    line-height: inherit;
  }

  .service_website {
    text-decoration: underline;
    color: ${colors.colorMain};
  }

  @media (max-width: 768px) {
    padding: 20px;

    && .header_fullcard {
      background-color: rgb(236, 242, 255);
      border-radius: 12px;
      padding: 15px 12px 10px;
    }

    && .text_header {
      font-size: 1rem;
    }

    && .text_subheader {
      font-size: 0.8rem;
    }
  }
`;

interface IFullCardBusinessPage {
  typeLink: 'public_service' | 'public_resource';
}

export const FullCardBusinessPage: React.FC<IFullCardBusinessPage> = ({ typeLink }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const urlParams = useParams();
  const [value, setValue] = useState(0);
  const servicePublic = useSelector<RootState, ServiceType>((state) => state.services.servicePublic);
  const serviceLoader = useSelector<RootState, boolean>((state) => state.services.serviceLoader);
  const serviceNotFound = useSelector<RootState, boolean>((state) => state.services.serviceNotFound);
  const [open, setOpen] = useState(false);
  const [currentKeyUrlOrId, setCurrentKeyUrlOrId] = useState<string>();
  const refButtonForm = useRef<HTMLInputElement>(null);
  const languagePrefix = useRef<string>('');
  const currentLanguage = useSelector((state: RootState) => state.app.currentLanguageForFormService);

  let canonicalUrl;
  if (paths.PUBLIC_RESOURCE.includes(typeLink)) {
    canonicalUrl = `https://servicein.ca/public_resource/${getReadableUrl(servicePublic.profile.companyName)}`;
  } else {
    canonicalUrl = `https://servicein.ca/public_service/${servicePublic.userId}/${servicePublic.id}`;
  }

  useEffect(() => {
    let newLanguage: LanguageType;
    if (servicePublic.profile.supportLanguage) {
      [newLanguage] = servicePublic.profile.supportLanguage as LanguageType[];
    } else {
      newLanguage = LANG_EN;
    }
    dispatch(setterApp({ currentLanguageForFormService: newLanguage }));
  }, [servicePublic]);

  useEffect(() => {
    if (typeLink === 'public_resource') {
      const newParam = { keyUrl: urlParams.keyUrl! };
      if (currentKeyUrlOrId !== urlParams.keyUrl) {
        setCurrentKeyUrlOrId(urlParams.keyUrl);
        dispatch(setCurrentServicePublic(initialService));
        dispatch(fetchPublicResource(newParam));
      }
    }
    if (typeLink === 'public_service') {
      const newParam = { id: urlParams.id!, idAuthor: urlParams.idAuthor! };
      if (currentKeyUrlOrId !== urlParams.id) {
        setCurrentKeyUrlOrId(urlParams.id);
        dispatch(setCurrentServicePublic(initialService));
        dispatch(fetchPublicService(newParam));
      }
    }
  }, [currentKeyUrlOrId]);

  const handleClose = () => setOpen(false);
  const handleOk = () => {
    refButtonForm.current?.click();
  };

  const handleForm = (isOk: boolean) => {
    if (isOk) {
      setOpen(false);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(newValue);
    setValue(newValue);
  };

  const token = Cookies.get(COOKIE_ID_TOKEN) || '';
  const decodedToken = decodeToken(token);

  const accessToView = servicePublic.status === 'online';
  const accessAuthor = (!servicePublic.status || servicePublic.status === 'offline') && servicePublic.userId === decodedToken?.['cognito:username'];

  languagePrefix.current = currentLanguage === LANG_EN ? '' : `_${currentLanguage}`;

  const serviceData: ServiceData[] = [
    {
      label: t('Company Name'),
      value: (servicePublic.profile as any)[`companyName${languagePrefix.current}`],
      valueLink: '',
    },
    {
      label: t('Representative Name'),
      value: (servicePublic.profile as any)[`representativeName${languagePrefix.current}`],
      valueLink: '',
    },
    {
      label: t('Business Description'),
      value: (servicePublic.about as any)[`businessDescription${languagePrefix.current}`],
      valueLink: '',
    },
    {
      label: t('Produce'),
      value: (servicePublic.profile as any)[`produce${languagePrefix.current}`]?.join(', '),
      valueLink: '',
    },
    {
      label: t('Website'),
      value: '',
      valueLink: servicePublic.profile.website,
    },
    {
      label: t('Establish Date'),
      value: servicePublic.profile.establishDate,
      valueLink: '',
    },
    {
      label: t('Capital'),
      value: servicePublic.profile.capital,
      valueLink: '',
    },
    {
      label: t('Parent Company'),
      value: (servicePublic.profile as any)[`parentCompany${languagePrefix.current}`],
      valueLink: '',
    },
    {
      label: t('Shareholder'),
      value: servicePublic.profile.shareholder,
      valueLink: '',
    },
    {
      label: t('Employee'),
      value: COMPANY_SIZE[`${servicePublic.profile.employee}`],
      valueLink: '',
    },
    {
      label: t('Account Period'),
      value: servicePublic.profile.accountPeriod,
      valueLink: '',
    },
    {
      label: t('Bank'),
      value: servicePublic.profile.bank,
      valueLink: '',
    },
    {
      label: t('Certification / ISO'),
      value: servicePublic.profile.certification,
      valueLink: '',
    },
    {
      label: t('License'),
      value: servicePublic.profile.license,
      valueLink: '',
    },
    {
      label: t('Value Customer'),
      value: servicePublic.profile.valueCustomer,
      valueLink: '',
    },
  ];

  const tabsData = [
    {
      label: t('Profile'),
      content: servicePublic.profile
                && <div><TabProfile servicePublic={servicePublic} serviceData={serviceData}/></div>,
    },
    {
      label: t('Gallery'),
      content: servicePublic.gallery.length ? <div><TabGallery servicePublic={servicePublic}/></div> : null,
    },
    {
      label: t('Pricing'),
      content: servicePublic.pricesForm.length ? <div><TabPrices servicePublic={servicePublic}/></div> : null,
    },
    {
      label: t('Contact'),
      content: servicePublic.office.length ? <div><TabContact servicePublic={servicePublic}/></div> : null,
    },
  ];

  const filteredTabsData = tabsData.filter((tab) => tab.content);

  return (
        <Spin spinning={serviceLoader} tip={'please wait ...'} style={{ minHeight: 400 }}>
            <ScrollUp/>
            <Helmet>
                <title>{`${servicePublic.profile.companyName} | Review on ServiceIn.ca`}</title>
                <meta name="description" content={servicePublic.profile.presentationInfo}/>
                <link rel="canonical" href={canonicalUrl}/>
            </Helmet>
            {
                (accessToView || accessAuthor)
                  ? <RowStyled className={'main_content'}>
                        {
                            !accessToView && accessAuthor && <Alert
                                message="Warning"
                                description={<p>
                                    Mode <strong>'Off-Line'</strong> is turn on. Only author can see this page. Switch mode
                                    to <strong>'On-Line'</strong> in your account to open public access for your service.
                                </p>}
                                type="warning"
                                showIcon
                                closable
                                style={{ marginBottom: 20, marginTop: -20 }}
                            />
                        }
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <FullCardHeaderService servicePublic={servicePublic}/>
                            <Row>
                                <Tabs
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"
                                >
                                    {filteredTabsData.map((tab, index) => (
                                        // @ts-ignore
                                        <TabStyled key={index} label={tab.label}/>
                                    ))}
                                </Tabs>
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                    {filteredTabsData.map((tab, index) => (
                                        <TabPanel key={index} index={index} currentTab={value} children={tab.content}/>
                                    ))}
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                    <AdvBlock/>
                                </Col>
                            </Row>
                        </Col>
                        <Modal
                            open={open}
                            onOk={handleOk}
                            onCancel={handleClose}
                            okButtonProps={{ size: 'large' }}
                            cancelButtonProps={{ size: 'large' }}
                            okText={'Send'}
                            footer={(_, { OkBtn, CancelBtn }) => (
                                <>
                                    <CancelBtn/>
                                    <OkBtn/>
                                </>
                            )}
                        >
                            <ContactForm nameForm={'modalRequest'} title={'Request form'} service={servicePublic}
                                         refButton={refButtonForm} handleForm={handleForm}/>
                        </Modal>
                    </RowStyled>
                  : (
                    serviceNotFound && <NotFoundPage/>
                  )
            }
        </Spin>
  );
};
