import * as React from 'react';
import { Dropdown, MenuProps } from 'antd';
import { DownOutlined, LogoutOutlined, UserSwitchOutlined } from '@ant-design/icons/lib';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AvatarUser } from './Image/AvatarUser';
import { clearTokens, isTokenValid, paths } from '../common';
import { setLogin } from '../store/slices/settingsSlice';
import { useUserPictUrl } from '../hooks/useUserPictUrl';
import { useResizeWidthWindow } from '../hooks/useResizeWidthWindow';
import { AppDispatch } from '../store';

type AvatarUserProps = {
  showLogin?: boolean
};

export const UserDropdownMenu: React.FC<AvatarUserProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const history = useNavigate();
  const userPictUrl = useUserPictUrl(60);
  const widthWindow = useResizeWidthWindow();

  const items: MenuProps['items'] = [
    {
      label: (
        <a rel="noopener noreferrer">
          {t('Profile')}
        </a>
      ),
      key: 'user_profile',
      icon: <UserSwitchOutlined />,
      onClick: () => {
        history(`${paths.USER_PROFILE}`);
      },
    },
    {
      label: (
        <a>{t('Logout')}</a>
      ),
      key: 'logout',
      icon: <LogoutOutlined />,
      onClick: () => {
        clearTokens();
        dispatch(setLogin(''));
        history(paths.LOGIN);
      },
    },
  ];

  return (
      <div style={{ display: isTokenValid() ? 'block' : 'none' }}>
          {
            <Dropdown menu={{ items }} trigger={['click']}>
                <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    <AvatarUser imgSrc={userPictUrl} showLogin={widthWindow > 600} /> <DownOutlined />
                </a>
            </Dropdown>
          }
      </div>
  );
};
