import * as React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { faUserGroup, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { CardReasonJoin } from './Cards/CardReasonJoin';

const RowStyled = styled(Row)`
  padding: 40px 40px 0 40px;
  margin: 0 auto;
  display: flex;
  
  && .justify{
    text-align: justify;
  }
  
  @media (max-width: 768px) {
    padding: 20px 20px 0 20px;
    gap: 10px;
  }
`;

const ColStyled = styled(Col)`
  padding: 10px 15px; 
  display: flex;

  @media (max-width: 768px) {
  padding: 0;
}
`;

export const ReasonJoin: React.FC = () => {
  const { t } = useTranslation();

  const cards = [
    {
      icon: faUserGroup,
      textFirst: t('For Service Seekers'),
      textSecond: t('Find the Right Specialist, Hassle-Free'),
      textThird: <div><span>{t('Are you on the lookout for a reliable specialist? Look no further')}</span>. <span>{t('ServiceIn provides you with a directory of skilled professionals across various industries')}</span>. <span>{t('You can search for specialists based on your specific needs, view their profiles, and get in touch directly through our platform')}. </span><span>{t('Finding the right specialist has never been easier')}</span>.</div>,
      regAs: 'user',
    },
    {
      icon: faUserTie,
      textFirst: t('For Service Providers'),
      textSecond: t('Elevate Your Business, Expand Your Reach'),
      textThird: <div><span>{t('If you are a specialist in your field, our platform offers you a place to shine')}</span>. <span>{t('Registering your business with us allows you to create a professional profile, showcase your work, and highlight your qualifications')}</span>. <span>{t('You will gain exposure to a wide audience of potential clients actively seeking your services')}</span>.</div>,
      regAs: 'provider',
    },
  ];

  return (
            <RowStyled className={'item_row'}>
                {cards.map((card, index) => (
                    <ColStyled xs={24} sm={24} md={12} lg={12} xl={12} key={index}>
                        <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{
                              opacity: 1,
                              y: 0,
                              transition: { duration: 1 },
                            }}>
                            {<CardReasonJoin
                                icon={card.icon}
                                textFirst={card.textFirst}
                                textSecond={card.textSecond}
                                textThird={card.textThird}
                                regAs={card.regAs}
                            />}
                    </motion.div>
                    </ColStyled>
                ))}
            </RowStyled>
  );
};
