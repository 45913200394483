import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Flex } from 'antd';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { colors } from '../../common/constStyles';

import { API, paths } from '../../common';
import { QuestionType, ResponseResourcesType, UserTokenType } from '../../../../common/types';
import { ButtonConfirmationDelete } from '../Buttons/ButtonConfirmationDelete';
import { AppDispatch } from '../../store';
import { fetchListQuestion, setterQuestion } from '../../store/slices/questionsSlice';
import { WrapperMiniCard } from './WrapperMiniCard';
import { ShareBlock } from '../ShareBlock';
import { useResizeWidthWindow } from '../../hooks/useResizeWidthWindow';

type ICardQuestion = {
  question: QuestionType,
  decodedToken: UserTokenType,
};

const DivStyled = styled.div<{ $fullText?: boolean; }>`  
  .question_text{
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.43;
  }
  .question_answer{
    font-size: 1em;
    font-weight: 500;
    line-height: 1.43;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: ${(props) => (props.$fullText ? 'auto' : 'hidden')};
    -webkit-line-clamp: ${(props) => (props.$fullText ? 'auto' : 7)};
    text-align: justify;
    padding-top: 10px;
    white-space: pre-wrap;
  }
  
  .reaction_bar{
    padding-top: 20px
  }
  .read_more{
    text-decoration: underline;
    color: ${colors.colorGreenSuccess};
    cursor: pointer;
  }
  
  .read_more:hover{
    transition: opacity 0.3s ease;
  }
  
  .ant-collapse-header.ant-collapse-header-collapsible-only{
    align-items: center;
  }
`;

export const QuestionChildren: React.FC<ICardQuestion> = ({ question, decodedToken }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [expanded, setExpanded] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);

  useEffect(() => {
    setIsAuthor(decodedToken && decodedToken.sub === question.userId);
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const deleteActionOk = async () => {
    await axios.delete<ResponseResourcesType>(`${API.URL_REST_API}${paths.QUESTION}/${question.id}`);
    await dispatch(fetchListQuestion());
  };

  const editQuestion = () => {
    dispatch(setterQuestion({ currentQuestion: question, showModal: true }));
  };

  return (
      <DivStyled $fullText={expanded}>
          <div className={'question_answer'}>
              <div dangerouslySetInnerHTML={{ __html: question.answer }} />
          </div>
          <p className={'read_more'} onClick={handleExpandClick}>{expanded ? 'read less' : 'read more'}</p>
          <Flex wrap={'wrap'} gap={10} style={{ marginTop: 20 }} justify={'center'}>
              {
              question.resources.map((resource) => <WrapperMiniCard key={resource} serviceId={resource} />)
              }
          </Flex>
          <Flex justify={'space-between'} align={'center'} style={{ marginTop: 20 }} vertical={useResizeWidthWindow() < 500} gap={20}>
              <Flex align={'center'} gap={5} style={{ color: colors.colorTextDark, fontSize: '0.8em', fontWeight: 600 }}>
                  <span>Last updates: </span>
                  {DateTime.fromMillis(question.dateByUpdate || question.dateByCreate).toFormat('LLLL dd, yyyy')}
              </Flex>
              <ShareBlock question={question} />
          </Flex>
          {isAuthor
              && <Flex gap={5} justify={'flex-end'} style={{ marginTop: 20 }}>
                  <ButtonConfirmationDelete
                      typeButton={'full'}
                      callbackOk={deleteActionOk}
                      size={'small'}
                  />
                  <Button type="primary" onClick={editQuestion} size={'small'} icon={<EditOutlined />} aria-label={'Edit post'}>
                      {t('Edit')}
                  </Button>
              </Flex>
          }
      </DivStyled>
  );
};
