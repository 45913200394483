import * as React from 'react';
import { useEffect, useState } from 'react';
import { Badge } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { colors } from '../common/constStyles';

interface IPasswordCriteria {
  password: string
}

const regSymbols = new RegExp(/.*[.*&^%$#!+_-]/);
const regUppercase = new RegExp(/.*[A-Z]/);
const regLowercase = new RegExp(/.*[a-z]/);
const regNumbers = new RegExp(/.*[0-9]/);

export const PasswordCriteria: React.FC<IPasswordCriteria> = ({ password }) => {
  const [isLengthCorrect, setIsLengthCorrect] = useState(false);
  const [isSymbolsCorrect, setIsSymbolsCorrect] = useState(false);
  const [isLowercaseInclude, setIsLowercaseInclude] = useState(false);
  const [isNumberInclude, setIsNumberInclude] = useState(false);
  const [isUppercaseInclude, setIsUppercaseInclude] = useState(false);

  useEffect(() => {
    setIsLengthCorrect(password.length >= 8);
    setIsNumberInclude(regNumbers.test(password));
    setIsSymbolsCorrect(regSymbols.test(password));
    setIsLowercaseInclude(regUppercase.test(password));
    setIsUppercaseInclude(regLowercase.test(password));
  }, [password]);

  const buildBadge = (isCorrect: boolean, text: string) => {
    const icon = isCorrect
      ? <CheckCircleOutlined style={{ color: colors.colorGreenSuccess, paddingRight: 10 }} />
      : <CloseCircleOutlined style={{ color: colors.colorRedError, paddingRight: 10 }} />;

    return <p><Badge count={icon} />{text}</p>;
  };

  return (
    <div style={{ marginBottom: 30 }}>
        <p style={{ paddingBottom: 4 }}>The password must meet the following criteria:</p>
        {buildBadge(isLengthCorrect, 'At least 8 characters in length')}
        {buildBadge(isSymbolsCorrect, 'Contains at least one symbol *&^%$.#!+_-')}
        {buildBadge(isNumberInclude, 'Includes at least one number')}
        {buildBadge(isLowercaseInclude, 'Includes at least one lowercase letter')}
        {buildBadge(isUppercaseInclude, 'Includes at least one uppercase letter')}
    </div>
  );
};
