import * as React from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { PostForm } from '../Forms/PostForm';
import { useResizeWidthWindow } from '../../hooks/useResizeWidthWindow';

interface IModalPost{
  isModalVisible: boolean
  handleCancel: () => void;
}

export const ModalPost: React.FC<IModalPost> = ({ isModalVisible, handleCancel }) => {
  const { t } = useTranslation();
  return (
      <Modal
          title={t('Create a new post')}
          open={isModalVisible}
          onCancel={handleCancel}
          style={{ minWidth: useResizeWidthWindow() > 768 ? '50%' : '400px' }}
          width={'25%'}
          footer={[
              <Button key="cancel" onClick={handleCancel} aria-label={'Cancel'}>
                  {t('Cancel')}
              </Button>,
              <Button form="addPost" key="submit" type="primary" htmlType="submit" aria-label={'Save'}>
                  {t('Save')}
              </Button>,
          ]}>
          <PostForm onCloseParentModal={handleCancel} />
      </Modal>
  );
};
