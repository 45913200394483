import * as React from 'react';
import {
  Button, Col, Flex, Row,
} from 'antd';
import { Card, CardContent, Typography } from '@mui/material';
import { EyeFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import FlagIconFactory from 'react-flag-icon-css';
import { useTranslation } from 'react-i18next';
import { paths } from '../../common';
import { OfficeType, ServiceType } from '../../../../common/types';
import { RateComponent } from '../RateComponent';
import { AvatarUser } from '../Image/AvatarUser';
import { useCategory } from '../../hooks/useGategory';
import { LabelPremium } from '../LabelPremium';
import { colors } from '../../common/constStyles';
import { getReadableUrl } from '../../common/functions';

export interface IMiniCardBusinessContent {
  service: ServiceType,
  firstOffice: OfficeType
  imageSrcAvatar: string
}

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

const TypographyStyled = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
`;

const CardStyled = styled(Card)`
  width: 100%;
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: none;
  display: flex;
  flex-direction: column;

  & .card_header{
    margin: 0;
    line-height: 1.43;
    font-size: 1.5em;
    font-weight: 700;
  }

  &:hover{
    transform: scale(1.01);
    transition: all 0.1s ease-in 0s;
  }
`;

export const MiniCardStyledListContent: React.FC<IMiniCardBusinessContent> = ({
  service, firstOffice, imageSrcAvatar,
}) => {
  const { CATEGORY_SERVICES } = useCategory();
  const { t } = useTranslation();

  return (
      <CardStyled variant="outlined" style={{ backgroundColor: service.isResource ? colors.colorMiniCardRes : 'transparent' }}>
          <Link to={`${paths.PUBLIC_RESOURCE}/${getReadableUrl(service.profile.companyName)}`} style={{ all: 'unset' }}>
              <LabelPremium service={service} style={{ left: 170, top: 6 }} />
              <CardContent sx={{ paddingTop: 0.8 }}>
                  <Row align={'middle'}>
                      <Col md={3} lg={3} xl={3}>
                          <div>
                              <AvatarUser
                                  imgSrc={imageSrcAvatar}
                                  size={124}/>
                          </div>
                      </Col>
                      <Col md={21} lg={21} xl={21} style={{ padding: 16 }}>
                          <Row>
                              <Col md={24} lg={24} xl={24}>
                                  <Flex justify={'space-between'}>
                                      <Flex align={'center'} gap={10}>
                                          <p className={'card_header'}>{service.profile.companyName}</p>
                                          <RateComponent rate={0}/>
                                      </Flex>
                                      <div>
                                          <p style={{ paddingBottom: 10 }}>{firstOffice ? firstOffice.cityState : t('no location')}</p>
                                          <Flex gap={10} justify={'center'}>
                                              {
                                                  service.profile.supportLanguage && service.profile.supportLanguage.length
                                                    ? service.profile.supportLanguage.map((language) => <FlagIcon key={language}
                                                                                                                    code={language}/>)
                                                    : <span>{t('no data')}</span>
                                              }
                                          </Flex>
                                      </div>
                                  </Flex>
                                  <p className={'card_subheader'}>{CATEGORY_SERVICES[+service.profile.category]}</p>
                              </Col>
                          </Row>
                          <Row align={'middle'} >
                              <Col md={20} lg={20} xl={20} style={{ paddingTop: '16px' }}>
                                  <TypographyStyled gutterBottom style={{ fontSize: '1em' }}>
                                      {service.profile.presentationInfo}
                                  </TypographyStyled>
                              </Col>
                              <Col md={4} lg={4} xl={4} style={{ textAlign: 'right' }} >
                                  <Button type="primary" ghost icon={<EyeFilled/>} size={'middle'}>{t('View more')}</Button>
                              </Col>
                          </Row>
                      </Col>
                  </Row>
              </CardContent>
          </Link>
      </CardStyled>
  );
};
