import * as React from 'react';
import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { PostType, QuestionType } from '../../../common/types';

interface IShareBlock{
  post?: PostType;
  question?: QuestionType;
}

export const ShareBlock: React.FC<IShareBlock> = ({ post, question }) => {
  const { t } = useTranslation();

  const shareLink = (post && `https://${window.location.hostname}/public_post/${post.userId}/${post.id}`)
    || (question && `https://${window.location.hostname}/public_question/${question.userId}/${question.id}`);

  return (
      <Flex justify={'flex-end'}>
          <Flex align={'center'} gap={5}>
              <p style={{ fontWeight: 500 }}>{t('Share')} </p>
              <FacebookShareButton url={shareLink!} >
                  <FacebookIcon size={32} round />
              </FacebookShareButton>
              <LinkedinShareButton url={shareLink!} >
                  <LinkedinIcon size={32} round />
              </LinkedinShareButton>
              <TelegramShareButton url={shareLink!} >
                  <TelegramIcon size={32} round />
              </TelegramShareButton>
              <TwitterShareButton url={shareLink!} >
                  <TwitterIcon size={32} round />
              </TwitterShareButton>
              <WhatsappShareButton url={shareLink!} >
                  <WhatsappIcon size={32} round />
              </WhatsappShareButton>
          </Flex>
      </Flex>
  );
};
