import Resizer from 'react-image-file-resizer';
import { AllowFormatImageType, ImageType } from '../../../common/types';

export const useResizeImage = () => {
  const resizeFile = (file: any, width: number, height: number) => new Promise((resolve) => {
    let typFile: ImageType;
    switch (file.type as AllowFormatImageType) {
      case 'image/png': typFile = 'PNG'; break;
      case 'image/webp': typFile = 'WEBP'; break;
      case 'image/jpeg':
      default: typFile = 'JPEG';
    }
    Resizer.imageFileResizer(
      file,
      width,
      height,
      typFile,
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'blob',
    );
  });

  return async (file: any, width: number, height: number) => {
    try {
      if (file.type as AllowFormatImageType === 'image/svg+xml') {
        return file;
      }
      return await resizeFile(file, width, height) as any;
    } catch (err) {
      console.log(err);
    }
    return undefined;
  };
};
