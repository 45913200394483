import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Flex, Input } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { GooglePlace } from '../../../../common/types';

export type GooglePlaceRawDataType = {
  addressComponents: google.maps.GeocoderAddressComponent[],
  formattedAddress: string,
  lat?: number,
  lng?: number
};

interface IAutocomplete{
  setServiceTempAddress: Dispatch<SetStateAction<GooglePlace | undefined>>,
  serviceTempAddress?: GooglePlace,
}

export const FlexStyled = styled(Flex)`
  padding: 10px;
  position: absolute;
  z-index: 200;
  background-color: white;
  width: 100%;
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, 
              rgba(145, 158, 171, 0.1) 0px 8px 16px 6px;


  && .address_item:not(:last-child) {
    cursor: pointer;
    padding: 5px 0;
    border-bottom: 1px solid #cecdcd;
  }
`;

export const PlacesAutocomplete: React.FC<IAutocomplete> = React.memo(({
  setServiceTempAddress,
  serviceTempAddress,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions: {
      componentRestrictions: { country: 'ca' },
      // bounds: {
      //   south: currentLocation.lat - 1, north: currentLocation.lat + 1, west: currentLocation.lng - 1, east: currentLocation.lng + 1,
      // },
    },
  });

  const { t } = useTranslation();

  const handleInput = (e: any) => {
    if (!e.target.value) {
      setServiceTempAddress(undefined);
    }
    setValue(e.target.value);
  };

  const processTempAddress = (payload: GooglePlaceRawDataType) => {
    const addressComponent = payload.addressComponents;

    const getData = (param: string) => {
      const component = addressComponent
        .find((res) => res.types.includes(param));
      return param === 'administrative_area_level_1' ? component?.short_name : component?.long_name;
    };

    return {
      address: {
        streetNumber: getData('street_number') || '',
        route: getData('route') || '',
        locality: getData('locality') || '',
        administrativeAreaLevel1: getData('administrative_area_level_1') || '',
        country: getData('country') || '',
        postalCode: getData('postal_code') || '',
        lat: payload.lat,
        lng: payload.lng,
      },
      formattedAddress: payload.formattedAddress,
    } as GooglePlace;
  };

  const handleSelect = ({ description }: { description: string }) => () => {
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description }).then((results) => {
      const [resultGeo] = results;
      const { lat, lng } = getLatLng(resultGeo);
      // console.log('📍 Coordinates: ', { lat, lng }, results);

      setServiceTempAddress(processTempAddress({
        addressComponents: resultGeo.address_components,
        formattedAddress: resultGeo.formatted_address,
        lat,
        lng,
      }));
    });
  };

  const renderSuggestions = () => data.map((suggestion) => {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      place_id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      structured_formatting: { main_text, secondary_text },
    } = suggestion;

    return (
            <div className={'address_item'} key={place_id} onClick={handleSelect(suggestion)}>
              <strong>{main_text}</strong> <small>{secondary_text}</small>
            </div>
    );
  });

  return (
      <div>
        <Input
            value={serviceTempAddress?.formattedAddress || value}
            onChange={handleInput}
            disabled={!ready}
            placeholder={t('Start type your address here')}
            allowClear
        />
        {
          status === 'OK'
            && <FlexStyled className={'address_list'} vertical gap={10}>{renderSuggestions()}</FlexStyled>
        }
      </div>
  );
});
