import * as React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Flex } from 'antd';
import { colors } from '../common/constStyles';
import { MainTextBlockStyle } from './MainPage';
import { SearchText } from './SearchText';
import { CardViewer } from './Cards/CardViewer';

const FlexStyled = styled(Flex)`
  padding: 40px 40px 0 40px;
  //text-align: center;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 10px 20px 0 20px;
  }
`;
export const CategoriesPage: React.FC = () => (
        <>
        <Helmet>
            <title>Categories | ServiceIn.ca</title>
            <meta name="description"
                  content="Find the best professional and local businesses. Use ServiceIn for Search by name, produce, tag or location"/>
            <link rel="canonical" href="https://servicein.ca/search"/>
        </Helmet>
            <div style={{ backgroundColor: colors.colorSecond }}>
                <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                    <MainTextBlockStyle vertical justify={'center'}>
                        <SearchText/>
                    </MainTextBlockStyle>
                </div>
            </div>
            <div>
                <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                    <FlexStyled vertical>
                        <CardViewer/>
                    </FlexStyled>
                </div>
            </div>
    </>);
