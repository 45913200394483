import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Button, Flex, Typography } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Contacts } from '@mui/icons-material';
import { ContactType, ServiceType } from '../../../../common/types';
import { RootState } from '../../store';
import { useResizeWidthWindow } from '../../hooks/useResizeWidthWindow';
import { FormContact } from './FormContact';

const { Title } = Typography;

const defaultContact: ContactType = {
  email: '',
  telephone: '',
};

export const FormContacts: React.FC = () => {
  const { t } = useTranslation();
  const service = useSelector<RootState, ServiceType>((state) => state.services.service);

  const [contacts, setContacts] = useState<ContactType[]>([]);

  useEffect(() => {
    setContacts([...(service.contact || [])]);
  }, [service]);

  const addContact = useCallback(() => {
    const newContact = {
      ...defaultContact,
      id: uuidv4(),
    };
    setContacts([...contacts, newContact]);
  }, [contacts]);

  return <>
      <Flex gap={10} align={'center'} style={{ marginBottom: 20 }}>
          <Contacts />
          <Title level={4} style={{ margin: 0, textTransform: 'uppercase' }}>{t('Contacts')}</Title>
      </Flex>
      <Flex align={'center'} gap={30} wrap={'wrap'} justify={useResizeWidthWindow() > 600 ? 'flex-start' : 'center'}>
          {contacts.map((contact) => <FormContact key={contact.id} contact={contact}/>)}
              <Flex justify={'center'}>
                  <Button type={'primary'} icon={<PlusCircleFilled/>} size={'large'} onClick={addContact} aria-label={'Add contact'}></Button>
              </Flex>
      </Flex>
    </>;
};
