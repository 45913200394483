import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Collapse, Flex, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { COOKIE_ID_TOKEN, decodeToken, ROLE_ADMIN } from '../common';
import { ButtonOpenModal } from '../components/Modal/ButtonOpenModal';
import { DivStyled } from './CommunityPage';
import { AppDispatch, RootState } from '../store';
import { QuestionChildren } from '../components/Cards/QuestionChildren';
import { fetchListQuestion } from '../store/slices/questionsSlice';
import { colors } from '../common/constStyles';

const FlexStyled = styled(Flex)<{ $fullText?: boolean; }>`
  width: 100%;
  box-shadow: rgba(145, 158, 171, 0.3) 0 0 2px 0, rgba(145, 158, 171, 0.12) 0 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;
  padding: 20px;
  
  .question_text{
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.43;
  }

  .ant-collapse-header.ant-collapse-header-collapsible-only{
    align-items: center;
  }

  @media (max-width: 768px) {
    padding: 0;
    border: none;
    box-shadow: none;
  }
`;

export const NewInCanadaPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const listQuestion = useSelector((state: RootState) => state.question.listQuestion);
  const questionLoader = useSelector((state: RootState) => state.question.questionLoader);

  const token = Cookies.get(COOKIE_ID_TOKEN) || '';
  const decodedToken = decodeToken(token);

  useEffect(() => {
    (async () => {
      await dispatch(fetchListQuestion());
    })();
  }, []);

  const panelStyle: React.CSSProperties = {
    background: colors.colorGrey,
    borderRadius: 12,
  };

  return (
        <div>
            <Helmet>
                <title>For newcomers | ServiceIn.ca</title>
                <meta name="description" content="Help newcomers with information and right services"/>
                <link rel="canonical" href='https://servicein.ca/new_in_canada'/>
            </Helmet>
            <DivStyled>
                <Spin spinning={questionLoader} tip={t('Please wait')} style={{ width: '100%' }}>
                    <Flex justify={'flex-end'} style={{ marginBottom: '20px' }}>
                        {
                            decodedToken && decodedToken['custom:Role'] === ROLE_ADMIN && <ButtonOpenModal mode={'button'} title={t('Add question')} type={'question'} />
                        }
                    </Flex>
                    <FlexStyled vertical gap={20}>
                        <Collapse
                            collapsible="header"
                            style={{ backgroundColor: 'white', border: 'none' }}
                        items={
                            listQuestion ? listQuestion.map((question, index) => ({
                              key: question.id,
                              label:
                                <div className={'question_text'}>
                                    {question.question}
                                </div>,
                              style: { ...panelStyle, marginBottom: index !== listQuestion.length - 1 ? 24 : 0 },
                              children:
                                <QuestionChildren question={question} key={question.id} decodedToken={decodedToken}/>,
                            })) : []
                        }/>
                    </FlexStyled>
                </Spin>
            </DivStyled>
        </div>
  );
};
