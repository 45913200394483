import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ISpinnerState {
  spinnerList: string[];
  spinner: boolean,
}

const initialState: ISpinnerState = {
  spinnerList: [],
  spinner: false,
};

export const spinnerSlice = createSlice({
  name: 'spinner',
  initialState,
  reducers: {
    addSpinnerItem: (state: ISpinnerState, action: PayloadAction<string>) => {
      state.spinnerList = [...state.spinnerList, action.payload];
      state.spinner = true;
    },
    removeSpinnerItem: (state: ISpinnerState, action: PayloadAction<string>) => {
      state.spinnerList = state.spinnerList.filter((item) => item !== action.payload);
      if (!state.spinnerList.length) state.spinner = false;
    },
    clearSpinnerList: (state: ISpinnerState) => {
      state.spinnerList = [];
      state.spinner = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addSpinnerItem, removeSpinnerItem, clearSpinnerList } = spinnerSlice.actions;

export default spinnerSlice.reducer;
