import * as React from 'react';
import { Button, Flex } from 'antd';
import { Facebook, LinkedIn, Twitter } from '@mui/icons-material';

export const MainFooterSocial: React.FC = () => (
    <Flex gap={10} className={'social_box'} justify={'center'} align={'center'}>
        <a href="/" target={'_blank'} aria-label={'Twitter'}>
            <Button type="link" aria-label={'Twitter'}><Twitter/></Button>
        </a>
        <a href="/" target={'_blank'} aria-label={'Facebook'}>
            <Button type="link" aria-label={'Facebook'}><Facebook/></Button>
        </a>
        <a href='/'>
            <Button type="link" aria-label={'LinkedIn'}><LinkedIn/></Button>
        </a>
    </Flex>
);
