import {
  Button, Col, Modal, Row,
} from 'antd';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { BaseImage } from './Image/BaseImage';
import { LANG_EN, SIZE_IMAGE } from '../common';
import { CardBlock } from './Cards/CardBlock';
import { CardBlockOffice } from './Cards/CardBlockOffice';
import { SocialBlockService } from './SocialBlockService';
import { ServiceData, ServiceType } from '../../../common/types';
import { ContactForm } from './Forms/ContactForm';
import { RootState } from '../store';
import { CardBlockContact } from './Cards/CardBlockContact';

interface ITabProfile {
  servicePublic: ServiceType,
  serviceData: ServiceData[]
}

const TypographyStyled = styled(Typography)`
  @media (max-width: 768px) {
    font-size: 0.9rem !important;
  }
`;

export const TabProfile: React.FC<ITabProfile> = ({ servicePublic, serviceData }) => {
  const { t } = useTranslation();

  const refButtonForm = useRef<HTMLInputElement>(null);
  const languagePrefix = useRef<string>('');
  const currentLanguage = useSelector((state: RootState) => state.app.currentLanguageForFormService);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOk = () => {
    refButtonForm.current?.click();
  };

  const handleForm = (isOk: boolean) => {
    if (isOk) {
      setOpen(false);
    }
  };

  languagePrefix.current = currentLanguage === LANG_EN ? '' : `_${currentLanguage}`;

  const isServiceSocial = servicePublic.profile.socialLinks?.linkLinkedIn
        || servicePublic.profile.socialLinks?.linkYouTube
        || servicePublic.profile.socialLinks?.linkTwitter
        || servicePublic.profile.socialLinks?.linkTiktok
        || servicePublic.profile.socialLinks?.linkInstagram
        || servicePublic.profile.socialLinks?.linkFacebook;

  return (<>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {servicePublic.profile.presentationPicture
                        && <div style={{ height: '300px', overflow: 'hidden' }}>
                            {
                                servicePublic.userId && <BaseImage
                                    src={`${process.env.REACT_APP_S3_STORAGE_URL}/${process.env.REACT_APP_BUCKET}/${servicePublic.userId}/${SIZE_IMAGE['1400']}${servicePublic.profile.presentationPicture}`}
                                    alt={'logo'}
                                    loading="lazy"
                                    width={'100%'}
                                    height={'100%'}
                                    preview={false}
                                />
                            }
                        </div>
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div style={{ padding: '20px', textAlign: 'justify' }}>
                        <TypographyStyled
                            variant={'h6'}>{(servicePublic.profile as any)[`presentationInfo${languagePrefix.current}`]}</TypographyStyled>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {
                        serviceData.map((item, key) => (
                          (item.value || item.valueLink)
                            && <CardBlock
                                key={key}
                                label={item.label}
                                value={item.value}
                                valueLink={item.valueLink}
                            />
                        ))
                    }
                    {
                        servicePublic.office && !!servicePublic.office.length
                        && <CardBlockOffice label={t('Offices')} values={servicePublic.office}/>
                    }
                    {
                        servicePublic.contact && !!servicePublic.contact.length
                        && <CardBlockContact label={t('Contact')} values={servicePublic.contact}/>
                    }
                    {isServiceSocial
                        && <CardBlock label={'Social'}
                                      valueMultiLinks={<SocialBlockService
                                          links={servicePublic.profile.socialLinks}/>}/>
                    }
                    {servicePublic.isShowRequestButton
                      && <div className={'action_buttons'} style={{
                        padding: '30px 20px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                        <Button type="primary" size={'large'} aria-label={'Send request'}
                                onClick={handleOpen}><span
                          style={{ textTransform: 'uppercase' }}>{t('Send request to the company')}</span></Button>
                      </div>
                    }
                </Col>
            </Row>
            <Modal
                open={open}
                onOk={handleOk}
                onCancel={handleClose}
                okButtonProps={{ size: 'large' }}
                cancelButtonProps={{ size: 'large' }}
                okText={'Send'}
                footer={(_, { OkBtn, CancelBtn }) => (
                    <>
                        <CancelBtn/>
                        <OkBtn/>
                    </>
                )}
            >
                <ContactForm nameForm={'modalRequest'} title={'Request form'} service={servicePublic}
                             refButton={refButtonForm} handleForm={handleForm}/>
            </Modal>
        </>
  );
};
