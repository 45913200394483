import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Button, Flex, Typography } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MapsHomeWork } from '@mui/icons-material';
import { FormOffice } from './FormOffice';
import { OfficeType, ServiceType } from '../../../../common/types';
import { RootState } from '../../store';
import { useResizeWidthWindow } from '../../hooks/useResizeWidthWindow';

const { Title } = Typography;

const defaultOffice: OfficeType = {
  email: '',
  address: '',
  cityState: '',
  street: '',
  postalCode: '',
  telephone: '',
};

export const FormOffices: React.FC = () => {
  const { t } = useTranslation();
  const service = useSelector<RootState, ServiceType>((state) => state.services.service);

  const [offices, setOffices] = useState<OfficeType[]>([]);

  useEffect(() => {
    setOffices([...service.office]);
  }, [service]);

  const addOffice = useCallback(() => {
    const newOffice = {
      ...defaultOffice,
      id: uuidv4(),
    };
    setOffices([...offices, newOffice]);
  }, [offices]);

  return <>
      <Flex gap={10} align={'center'} style={{ marginBottom: 20 }}>
          <MapsHomeWork />
          <Title level={4} style={{ margin: 0, textTransform: 'uppercase' }}>{t('Offices')}</Title>
      </Flex>
      <Flex align={'center'} gap={30} wrap={'wrap'} justify={useResizeWidthWindow() > 600 ? 'flex-start' : 'center'}>
          {offices.map((office) => <FormOffice key={office.id} office={office}/>)}
              <Flex justify={'center'}>
                  <Button type={'primary'} icon={<PlusCircleFilled/>} size={'large'} onClick={addOffice} aria-label={'Add office'}></Button>
              </Flex>
      </Flex>
    </>;
};
