import * as React from 'react';
import { Typography } from '@mui/material';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { colors } from '../../common/constStyles';

type CardBlockProps = {
  label: string,
  value?: string,
  valueLink?: string,
  valueMultiLinks?: React.ReactElement
};

export const AStyled = styled.a`
  text-decoration: none;
  color: #0f7afc;
  border-bottom: 1px solid;
  border-bottom-color: rgba(15, 122, 252, 0.7);

  &:hover {
    text-decoration: none;
    color: #145575;
    border-bottom-color: rgb(13, 55, 77);
  }

  &:visited {
    text-decoration: none;
    color: #800080;
    border-bottom-color: rgba(128, 0, 128, 0.2);
  }
`;

const RowStyled = styled(Row)`
  padding: 30px 25px 20px;
  border-bottom: 1px solid ${colors.colorBorder};
  box-sizing: border-box;
  overflow: hidden;
  word-wrap: break-word;
  text-align: justify;

  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

const TypographyStyled = styled(Typography)`
  @media (max-width: 768px) {
    font-size: 0.8rem !important;
  }
`;

export const CardBlock: React.FC<CardBlockProps> = ({
  label, value, valueLink, valueMultiLinks,
}) => (
      <RowStyled>
          <Col xs={8} sm={8} md={8} lg={6} xl={6}>
              <TypographyStyled>{label}</TypographyStyled>
          </Col>
          <Col xs={16} sm={16} md={16} lg={18} xl={18}>
              {
                  valueLink && <TypographyStyled><AStyled href={valueLink} target={'_blank'} aria-label={valueLink}>{valueLink}</AStyled></TypographyStyled>
              }
              {
                  value && <TypographyStyled>{value}</TypographyStyled>
              }
              {
                  valueMultiLinks
              }
          </Col>
      </RowStyled>
);
