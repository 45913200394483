import * as React from 'react';
import { useState } from 'react';
import {
  Box, Card, CardContent, Typography,
} from '@mui/material';
import { Col, Flex, Row } from 'antd';
import { Email, LocationCity, Phone } from '@mui/icons-material';
import styled from 'styled-components';
import { colors } from '../../common/constStyles';
import { OfficeType } from '../../../../common/types';

type CardBlockOfficeProps = {
  label: string,
  values: OfficeType[],
};

const CardStyle = styled(Card)`
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: none;
  position: relative;
  width: 300px;
`;

const RowStyled = styled(Row)`
  padding: 30px 25px 20px;
  border-bottom: 1px solid ${colors.colorBorder};
  box-sizing: border-box;
  overflow: hidden;
  word-wrap: break-word;
  text-align: justify;

  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

const BoxStyled = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
`;

const AStyled = styled.a`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  width: 85%;

  @media (max-width: 768px) {
    font-size: 0.7rem !important;
  }
`;

const SpanStyled = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
`;

const TypographyStyled = styled(Typography)`
  @media (max-width: 768px) {
    font-size: 0.7rem !important;
  }
`;

export const CardBlockOffice: React.FC<CardBlockOfficeProps> = ({ label, values }) => {
  const [isVisiblePhone, setIsVisiblePhone] = useState(false);

  const handleShowPhoneNumber = () => {
    setIsVisiblePhone(!isVisiblePhone);
  };

  const handleCallPhone = (val:OfficeType) => {
    window.location.href = `tel:${val.telephone}`;
  };

  return (
        <RowStyled>
            <Col xs={8} sm={8} md={8} lg={6} xl={6}>
                <TypographyStyled>{label}</TypographyStyled>
            </Col>
            <Col xs={16} sm={16} md={16} lg={18} xl={18}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 30 }}>
                    {values.map((val, key) => (
                        <CardStyle key={key} variant="outlined">
                            <CardContent>
                                <Flex vertical gap={5}>
                                <Flex>
                                    <LocationCity color={'primary'} style={{ marginRight: '10px' }}/>
                                    <div>
                                        <TypographyStyled>{val.street}, {val.cityState}</TypographyStyled>
                                    </div>
                                </Flex>
                                    {val.email
                                    && <BoxStyled>
                                            <Email color={'primary'} style={{ marginRight: '10px' }}/>
                                            <AStyled href={`mailto:${val.email}`} aria-disabled="false">
                                                <SpanStyled>{val.email}</SpanStyled>
                                            </AStyled>
                                        </BoxStyled>
                                    }
                                    {
                                        val.telephone
                                        && <BoxStyled>
                                            <Phone color={'primary'} style={{ marginRight: '10px' }}/>
                                            {
                                                isVisiblePhone
                                                && <AStyled href={`tel:${val.telephone}`} onClick={(e:any) => {
                                                  e.preventDefault();
                                                  handleCallPhone(val);
                                                }}>
                                                    {val.telephone}
                                                </AStyled>
                                            }
                                            {
                                                !isVisiblePhone
                                                && <p onClick={handleShowPhoneNumber}>
                                                    <span style={{ color: colors.colorMain, cursor: 'pointer' }}>Show phone number</span>
                                                </p>
                                            }
                                        </BoxStyled>
                                    }
                                </Flex>
                            </CardContent>
                        </CardStyle>
                    ))}
                </div>
            </Col>
        </RowStyled>
  );
};
