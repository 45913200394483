import * as React from 'react';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  GoogleMap, InfoWindow, Marker, useJsApiLoader,
} from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { Button, Flex } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { ExportOutlined } from '@ant-design/icons';
import { OfficeType, ServiceType } from '../../../common/types';
import {
  API, LIBRARIES, paths, SIZE_IMAGE,
} from '../common';
import { RootState } from '../store';
import { AvatarUser } from './Image/AvatarUser';
import { LabelPremium } from './LabelPremium';
import { getReadableUrl } from '../common/functions';

interface IGoogleMapComponent {
  offices: OfficeType[],
  height?: string
}

export const GoogleMapComponent: React.FC<IGoogleMapComponent> = ({ offices, height }) => {
  const [selectedPin, setSelectedPin] = useState<OfficeType>();
  const mapRef = useRef(undefined);
  const location = useLocation();
  // const servicePublic = useSelector<RootState, ServiceType>((state) => state.services.servicePublic);
  const listService = useSelector<RootState, ServiceType[]>((state) => state.services.serviceGlobalList);
  const [servicePublic2, setServicePublic2] = useState<ServiceType>();
  const servicePublic = useSelector<RootState, ServiceType>((state) => state.services.servicePublic);

  const onLoad = useCallback((subMap: any) => {
    mapRef.current = subMap;
  },
  []);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API.GOOGLE_MAPS as string,
    libraries: LIBRARIES as any[],
  });
  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: API.GOOGLE_MAPS as string,
  // });

  const onUnmount = useCallback((subMap: any) => {
    console.log(subMap);
    mapRef.current = undefined;
  },
  []);

  const optionsMap = {
    disabledDefaultUI: true,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    keyboardShortcuts: false,
    scrollwheel: true,
    fullscreenControl: false,
    disableDoubleClickZoom: false,

  };

  const containerStyle = {
    width: '100%',
    height: height || '400px',
  };

  const center = { // Сделала центром - Бернаби
    lat: 49.248810,
    lng: -122.980507,
  };

  useEffect(() => {
    if (selectedPin) {
      const [newService] = listService.filter((service) => service.office.filter((office) => office.id === selectedPin.id)[0]);
      if (newService) {
        setServicePublic2(newService);
      } else {
        setServicePublic2(servicePublic);
      }
    }
  }, [selectedPin]);

  return (
        <div>
            {isLoaded
                && <GoogleMap
                    zoom={10}
                    center={center}
                    options={optionsMap}
                    mapContainerStyle={containerStyle}
                    onUnmount={onUnmount}
                    onLoad={onLoad}
                >
                     {offices.map((office) => (
                        <Marker key={office.id}
                                position={{ lat: office.lat!, lng: office.lng! }}
                                onClick={() => setSelectedPin(office)}
                        />
                     ))}
                     {selectedPin
                        && <InfoWindow position={{ lat: selectedPin.lat!, lng: selectedPin.lng! }}
                                       onCloseClick={() => {
                                         setSelectedPin(undefined);
                                       }}>
                            <div>
                              <Flex justify={'space-between'} align={'center'}>
                                {servicePublic2 && <LabelPremium service={servicePublic2} style={{ top: 1, right: 2, transform: 'scale(0.7)' }} />}
                                <AvatarUser
                                  imgSrc={`${process.env.REACT_APP_S3_STORAGE_URL}${process.env.REACT_APP_BUCKET}/${servicePublic2?.userId}/${SIZE_IMAGE['124']}${servicePublic2?.profile.avatar}`}
                                  size={34}/>
                                <p>{`${servicePublic2?.profile.companyName}`}</p>
                              </Flex>
                              <p style={{ marginTop: 10 }}>{`${selectedPin.street}, ${selectedPin.cityState}`}</p>
                              <p style={{ marginTop: 10, fontSize: 12 }}>phone: <strong>{selectedPin.telephone}</strong></p>
                              <p style={{ marginTop: 10, fontSize: 12 }}>email: <strong>{selectedPin.email}</strong></p>

                              { (!location.pathname.includes('public_service') || !location.pathname.includes('public_resource')) && servicePublic2
                                && <Flex justify={'flex-end'} style={{ marginTop: '10px' }}>
                                  <Link to={`${paths.PUBLIC_RESOURCE}/${getReadableUrl(servicePublic2.profile.companyName)}`} target="_blank">
                                    <Button icon={<ExportOutlined /> } size={'small'} ghost={true} type={'primary'} aria-label={'Open'}>Open</Button>
                                  </Link>
                                </Flex>
                              }
                            </div>
                        </InfoWindow>}
                </GoogleMap>

            }
        </div>
  );
};
