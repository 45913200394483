import * as React from 'react';
import { useEffect, useState } from 'react';
import { Skeleton, Spin } from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
  API, COOKIE_ID_TOKEN, decodeToken, paths,
} from '../../common';
import { ServiceType } from '../../../../common/types';
import { QuestionCard } from './QuestionCard';

export interface IWrapperMiniCard {
  serviceId: string,
}

export const WrapperMiniCard: React.FC<IWrapperMiniCard> = ({
  serviceId,
}) => {
  const [service, setService] = useState<ServiceType>();
  const [serviceNotExists, setServiceNotExists] = useState(false);
  const [spin, setSpin] = useState(false);

  const token = Cookies.get(COOKIE_ID_TOKEN) || '';
  const decodedToken = decodeToken(token);

  useEffect(() => {
    (async () => {
      try {
        setSpin(true);
        setService((await axios.get<ServiceType>(`${API.URL_REST_API}${paths.PUBLIC_SERVICE}/${serviceId}`)).data);
      } catch (e) {
        setServiceNotExists(true);
        console.log(e);
      }
      setSpin(false);
    })();
  }, [serviceId]);

  const SkeletonMiniCard = () => <>
  {
    (!serviceNotExists || (serviceNotExists && decodedToken && serviceId.includes(decodedToken.sub)))
    && <div style={{ width: '250px', height: '120px' }}>
      <Skeleton active />
    </div>
  }
  </>;

  return (
      <Spin spinning={spin} tip={'Please wait'}>
          {
            service
              ? <QuestionCard service={service}/>
              : <SkeletonMiniCard />
          }
      </Spin>
  );
};
