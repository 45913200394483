import React, { useCallback } from 'react';
import { Row } from 'antd';
import { AlertStyle } from '../style_component/AlertStyle';

type MsgType = 'error' | 'success';

export const useAlertMessage = (type: MsgType, msg: string) => useCallback(() => {
  if (msg) {
    return (<Row key={`key_${Math.random()}`}>
            <AlertStyle message={<div dangerouslySetInnerHTML={{ __html: `${msg}` }} />} type={type} showIcon />
        </Row>);
  }
  return null;
}, [msg])();
