import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LanguageType } from '../../../../common/types';
import { LANG_EN } from '../../common';

export interface IAppState {
  currentCategory?: string[],
  currentGroupCategory: string,
  filterCategory?: string[],
  searchParam?: string,
  searchText?: string,
  searchLocation?: string,
  currentLanguageForFormService?: LanguageType,
  goto?: string,
}

const initialState: IAppState = {
  currentCategory: [],
  currentGroupCategory: '',
  filterCategory: [],
  searchParam: '',
  searchText: '',
  searchLocation: '',
  currentLanguageForFormService: LANG_EN,
  goto: '',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCurrentCategory: (state, action: PayloadAction<string[]>) => {
      state.currentCategory = action.payload;
    },
    setSearchCategory: (state, action: PayloadAction<string[]>) => {
      state.filterCategory = action.payload;
    },
    setSearchParam: (state, action: PayloadAction<string>) => {
      state.searchParam = action.payload;
    },
    setterApp: (state, action: PayloadAction<Partial<IAppState>>) => ({ ...state, ...action.payload }),
  },
});

export const {
  setCurrentCategory,
  setSearchCategory,
  setSearchParam,
  setterApp,
} = appSlice.actions;

export default appSlice.reducer;
