import { Col, Row } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceType } from '../../../common/types';
import { MapBlock } from './MapBlock';
import { ContactForm } from './Forms/ContactForm';

interface ITabContact {
  servicePublic: ServiceType,
}

export const TabContact: React.FC<ITabContact> = ({ servicePublic }) => {
  const { t } = useTranslation();

  return (
        <>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div>
                        <div className={'google_map'}>
                            <MapBlock offices={servicePublic.office}/>
                        </div>
                        <ContactForm nameForm={'pageRequest'}
                                     title={t('Have questions or suggestions?')}
                                     service={servicePublic}/>
                    </div>
                </Col>
            </Row>
        </>
  );
};
