import * as React from 'react';
import { Link } from 'react-router-dom';
import { Card } from '@mui/material';
import styled from 'styled-components';
import { Flex, Tag } from 'antd';
import { ServiceType } from '../../../../common/types';
import { paths, SIZE_IMAGE } from '../../common';
import { useCategory } from '../../hooks/useGategory';
import { AvatarServiceCard } from '../Image/AvatarServiceCard';
import { getReadableUrl } from '../../common/functions';

interface IMiniCardBusiness {
  service: ServiceType
}

const CardStyled = styled(Card)`
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: none;
  display: flex;
  flex-direction: column;

  &:hover{
    transform: scale(1.01);
    transition: all 0.1s ease-in 0s;
  }
`;

const FlexStyled = styled(Flex)`
  flex-grow: 1; 
  padding: 20px 10px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const AdvCard: React.FC<IMiniCardBusiness> = ({ service }) => {
  const { CATEGORY_SERVICES } = useCategory();
  return <>
        <Link to={`${paths.PUBLIC_RESOURCE}/${getReadableUrl(service.profile.companyName)}`} target="_blank">
            <CardStyled variant="outlined" sx={{ border: 'none' }}>
                <FlexStyled vertical={true} align={'center'} gap={5}>
                        <AvatarServiceCard
                            imgSrc={`${process.env.REACT_APP_S3_STORAGE_URL}${process.env.REACT_APP_BUCKET}/${service.userId}/${SIZE_IMAGE['124']}${service.profile.avatar}`}
                            size={80}
                        />
                        <h3 style={{ margin: 0, paddingTop: 4, textAlign: 'center' }}>{service.profile.companyName}</h3>
                        <p style={{ textAlign: 'center' }}>{CATEGORY_SERVICES[service.profile.category]}</p>
                        <Flex style={{ margin: '10px 0', width: '100%' }} gap={5} justify={'center'} wrap={'wrap'} >
                            {service.profile.produce.slice(0, 3).map((prod, index) => (
                                <Tag key={index}
                                       style={{
                                         whiteSpace: 'nowrap',
                                         overflow: 'hidden',
                                         textOverflow: 'ellipsis',
                                       }}>
                                {prod}
                            </Tag>
                            ))}
                        </Flex>
                        <p style={{ textAlign: 'center' }}>{service.about.shortInfo}</p>
                </FlexStyled>
            </CardStyled>

        </Link>
    </>;
};
