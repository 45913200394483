import * as React from 'react';
import { useCallback } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { CustomFlagIconFactory } from 'react-flag-icon-css';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
  COOKIE_LANGUAGE, COUNTRY_CA, COUNTRY_LANGUAGE, COUNTRY_UA, COUNTRY_VN, LANGUAGE_COUNTRY,
} from '../common';
import { setLanguage } from '../store/slices/settingsSlice';
import { AppDispatch, selectLanguage } from '../store';
import { CountryType } from '../../../common/types';
import { codes } from '../example-custom-flags';

// const FlagIcon = FlagIconFactory(React, { useCssModules: false });
const options = { useCssModules: false, customCodes: codes };
const FlagIcon = CustomFlagIconFactory(React, options);

const countries: CountryType[] = [COUNTRY_CA, COUNTRY_VN, COUNTRY_UA];

export const LanguageDropdownMenu: React.FC = () => {
  const language = useSelector(selectLanguage);
  const dispatch = useDispatch<AppDispatch>();

  const onClick = useCallback((e: any) => {
    Cookies.set(COOKIE_LANGUAGE, e.key);
    dispatch(setLanguage(e.key));
  }, []);

  const items: MenuProps['items'] = countries.map((elem) => ({
    label: (<span>{elem ? elem.toUpperCase() : ''}</span>),
    key: COUNTRY_LANGUAGE[elem][0],
    icon: <FlagIcon code={elem}/>,
    onClick,
  }));

  return (<div style={{ marginLeft: '1em', width: '50px' }}>
            <Dropdown menu={{ items }} trigger={['click']}>
                <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    <FlagIcon size={'lg'} code={LANGUAGE_COUNTRY[language]}/>
                </a>
            </Dropdown>
        </div>
  );
};
