import * as React from 'react';
import { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Button, Flex, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { ExportOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import { green, magenta } from '@ant-design/colors';
import { useTranslation } from 'react-i18next';
import { ResponseResourcesType, ServiceType } from '../../../common/types';
import { API, paths, SIZE_IMAGE } from '../common';
import { delServiceFromList, setCurrentService } from '../store/slices/servicesSlice';
import { AppDispatch, RootState } from '../store';
import { colors } from '../common/constStyles';
import { ButtonConfirmationDelete } from './Buttons/ButtonConfirmationDelete';
import { AvatarUser } from './Image/AvatarUser';
import { useResizeWidthWindow } from '../hooks/useResizeWidthWindow';
import { useCategory } from '../hooks/useGategory';

type UserServiceCardType = {
  service: ServiceType
};

const CardStyled = styled(Card)`
  width: 100%;
  cursor: pointer;
  box-shadow: rgba(145, 158, 171, 0.3) 0 0 2px 0, rgba(145, 158, 171, 0.12) 0 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;
  
  &&.active {
    //border: 2px solid ${colors.colorMain};
    background-color: ${colors.colorSecond};
  }
  &&.notactive {
    //border: 2px solid rgba(145, 158, 171, 0.1);
  }
  
  &:hover{
    background-color: ${colors.colorSecond}
  }
`;

export const UserServiceCard: React.FC<UserServiceCardType> = ({ service }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const widthWindow = useResizeWidthWindow();
  const { CATEGORY_SERVICES } = useCategory();
  const currentService = useSelector<RootState, ServiceType>((state) => state.services.service);
  const [activeCard, setActiveCard] = useState<string>('');
  const [isHideCard, setIsHideCard] = useState(false);

  useEffect(() => {
    if (currentService.id !== service.id) {
      setActiveCard('');
      if (widthWindow < 768 && currentService.id) {
        setIsHideCard(true);
      } else {
        setIsHideCard(false);
      }
    } else {
      setActiveCard(service.id!);
      setIsHideCard(false);
    }
  }, [currentService, widthWindow]);

  const toggleActive = () => {
    setActiveCard(service.id!);
    dispatch(setCurrentService(service));
  };

  const deleteActionOk = async () => {
    const result = (await axios.delete<ResponseResourcesType>(`${API.URL_REST_API}${paths.SERVICE}/${service.id}`)).data;
    dispatch(delServiceFromList(service.id!));
    console.log(result);
  };

  const dateCreate = service.dateByCreate ? DateTime.fromMillis(service.dateByCreate).toFormat('yyyy.MM.dd') : '____.__.__';
  const dateUpdate = service.dateByUpdate ? DateTime.fromMillis(service.dateByUpdate).toFormat('yyyy.MM.dd') : '____.__.__';

  const isPremiumAction = (curService: ServiceType) => {
    if (!curService.isPremium) {
      return <Tag color="default">Not Premium</Tag>;
    }

    return <Tag color="success">Premium</Tag>;
  };

  return <>
      {
          !isHideCard
            && <CardStyled variant="outlined" key={service.id} onClick={toggleActive} className={service.id === activeCard ? 'active' : 'notactive'}>
            <div style={{ textAlign: 'right', marginTop: '7px' }}>{isPremiumAction(service)}</div>
            <CardHeader
                      avatar={
                          service.id && <AvatarUser
                          imgSrc={`${process.env.REACT_APP_S3_STORAGE_URL}${process.env.REACT_APP_BUCKET}/${service.userId}/${SIZE_IMAGE['124']}${service.profile.avatar}`}
                          size={64}/>
                      }
                      title={service.profile.companyName} titleTypographyProps={{ fontSize: '1.5em' }}
                      subheader={
                          <Flex vertical gap={10}>
                              <div>{CATEGORY_SERVICES[+service.profile.category]}</div>
                              <div><Tag color={service.status === 'online' ? green[7] : magenta[7]}><span style={{ fontWeight: 'bold' }}>{service.status}</span></Tag></div>
                          </Flex>
                      }
                  />
                  <CardContent>
                    <div style={{ color: 'gray', fontSize: 12 }}>
                      <div style={{ marginBottom: 5 }}>created: <span style={{ fontWeight: 'bold' }}>{dateCreate}</span></div>
                      <div>updated: <span style={{ fontWeight: 'bold' }}>{dateUpdate}</span></div>
                    </div>
                      <Flex justify={'end'} align={'center'} gap={10} style={{ paddingTop: '10px' }}>
                          <ButtonConfirmationDelete
                              typeButton={'full'}
                              callbackOk={deleteActionOk}
                              size={'small'}
                          />
                          <Link to={`${paths.PUBLIC_SERVICE}/${service.userId}/${service.id}`} target="_blank">
                              <Button icon={<ExportOutlined /> } size={'small'} ghost={true} type={'primary'} aria-label={'Open'}>{t('Open')}</Button>
                          </Link>
                      </Flex>
                  </CardContent>
              </CardStyled>
      }
      </>;
};
