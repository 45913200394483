import * as React from 'react';
import { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import { Button, Flex, Image } from 'antd';
import styled from 'styled-components';
// import { CommentOutlined, HeartOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import { colors } from '../../common/constStyles';

import { AvatarUser } from '../Image/AvatarUser';
import { API, paths, SIZE_IMAGE } from '../../common';
import { PostType, ResponseResourcesType, UserTokenType } from '../../../../common/types';
import { ButtonConfirmationDelete } from '../Buttons/ButtonConfirmationDelete';
import { AppDispatch } from '../../store';
import { fetchListPost, setterPost } from '../../store/slices/postsSlice';
import { ShareBlock } from '../ShareBlock';

type ICardPost = {
  post: PostType,
  decodedToken: UserTokenType,
};

const CardStyled = styled(Card)<{ $fullText?: boolean; }>`
  width: 100%;
  box-shadow: rgba(145, 158, 171, 0.3) 0 0 2px 0, rgba(145, 158, 171, 0.12) 0 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;
  padding: 20px;
  
  
  .post_text{
    font-size: 1em;
    font-weight: 500;
    line-height: 1.43;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: ${(props) => (props.$fullText ? 'auto' : 'hidden')};
    -webkit-line-clamp: ${(props) => (props.$fullText ? 'auto' : 7)};
    text-align: justify;
    padding-top: 10px;
    white-space: pre-wrap;
  }
  
  .read_more{
    text-decoration: underline;
    color: ${colors.colorGreenSuccess};
    cursor: pointer;
  }
  
  .read_more:hover{
    transition: opacity 0.3s ease;
  }
`;

const DivImgStyled = styled.div`
  height: 360px;
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
  
  .ant-image{
    width: 100%;
    height: 100%;
  }
  
  && img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    height: 230px;
  }
`;

export const CardPost: React.FC<ICardPost> = ({ post, decodedToken }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [expanded, setExpanded] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);

  useEffect(() => {
    setIsAuthor(decodedToken && decodedToken.sub === post.userId);
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const deleteActionOk = async () => {
    await axios.delete<ResponseResourcesType>(`${API.URL_REST_API}${paths.POST}/${post.id}`);
    await dispatch(fetchListPost());
  };

  const editPost = () => {
    dispatch(setterPost({ currentPost: post, showModal: true }));
  };

  return (
    <CardStyled $fullText={expanded} variant="outlined">
        {
            isAuthor
              && <Flex gap={5} justify={'flex-end'}>
                <ButtonConfirmationDelete
                  typeButton={'full'}
                  callbackOk={deleteActionOk}
                  size={'small'}
                />
                <Button type="primary" onClick={editPost} ghost size={'small'} icon={<EditOutlined />} aria-label={'Edit post'}>
                    {t('Edit')}
                </Button>
              </Flex>
        }
        <Flex style={{ padding: '0 10px' }} gap={20} align={'center'}>
            <div>
                <AvatarUser
                    imgSrc={`${process.env.REACT_APP_S3_STORAGE_URL}${process.env.REACT_APP_BUCKET}/${post.userId}/${SIZE_IMAGE['124']}${post.userPicture}`}
                    size={46}/>
            </div>
            <Flex vertical>
                <p style={{ fontSize: '1.2em' }}>{post.userName}</p>
                <p style={{ color: colors.colorTextDark, fontSize: '0.8em' }}>{DateTime.fromMillis(post.dateByCreate).toFormat('LLLL dd, yyyy')}</p>
            </Flex>
        </Flex>
        <div className={'post_text'}>
            {post.text}
        </div>
        <p className={'read_more'} onClick={handleExpandClick}>{expanded ? 'read less' : 'read more'}</p>
        {
            post.image
            && <DivImgStyled>
                <Image src={`${process.env.REACT_APP_S3_STORAGE_URL}${process.env.REACT_APP_BUCKET}/${post.userId}/${SIZE_IMAGE['1400']}${post.image}`} preview={false} loading="lazy" width={'100%'}/>
            </DivImgStyled>
        }
        <div style={{ paddingTop: '20px' }} >
            <ShareBlock post={post} />
        </div>

    </CardStyled>
  );
};
