import * as React from 'react';
import { Flex } from 'antd';
import { LocationOn } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { OfficeType } from '../../../common/types';
import { GoogleMapComponent } from './GoogleMapComponent';

interface IMapBlock {
  offices: OfficeType[]
}

export const MapBlock: React.FC<IMapBlock> = ({ offices }) => {
  const { t } = useTranslation();

  return (
      <>
        <div style={{ paddingBottom: '10px' }}>
           <p style={{ fontWeight: 'bold' }}>{t('Addresses')}</p>
             <Flex gap={20}>
              {offices.map((office) => (
                  <Flex align={'center'} key={office.id}>
                      <LocationOn color={'primary'} style={{ marginRight: '10px' }}/>
                      <p>{office.street}, {office.cityState}</p>
                  </Flex>
              ))}
             </Flex>
        </div>
        <div style={{ width: '100%' }}>
          <GoogleMapComponent offices={offices}/>
        </div>
      </>
  );
};
