import {
  Button, Flex, Form, Input, Spin,
} from 'antd';

import React, { useCallback, useState } from 'react';
import axios from 'axios';

import { ChangePasswordCommandInput } from '@aws-sdk/client-cognito-identity-provider';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { API, COOKIE_ACCESS_TOKEN, paths } from '../../common';
import { useAlertMessage } from '../../hooks/useAlertMessage';
import { ResponseLoginType } from '../../../../common/types';

type ChangePasswordType = {
  oldPassword: string,
  newPassword: string,
  newSecondPassword: string,
};

export const ChangePasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const [msgError, setMsgError] = useState('');
  const [msgOk, setMsgOk] = useState('');
  const [spin, setSpin] = useState(false);

  const onFinishChangePassword = useCallback(async (values: ChangePasswordType) => {
    setMsgError('');
    setMsgOk('');
    setSpin(true);

    const AccessToken = Cookies.get(COOKIE_ACCESS_TOKEN) || '';

    const request: ChangePasswordCommandInput = {
      AccessToken,
      PreviousPassword: values.oldPassword,
      ProposedPassword: values.newPassword,
    };

    try {
      const result = (await axios.post<ResponseLoginType>(`${API.URL_LOGIN}${paths.CHANGE_PASSWORD}`, JSON.stringify(request))).data;
      setSpin(false);
      setMsgOk(result.message);
    } catch (e: any) {
      setSpin(false);
      setMsgError(JSON.stringify(e.response.data.message));
    }
  }, []);

  const onFinishFailed = useCallback((errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }, []);

  const renderMessageError = useAlertMessage('error', msgError);
  const renderMessageOk = useAlertMessage('success', msgOk);

  return (
      <>
          <Spin spinning={spin} tip={'please wait ...'}>
              <Form
                  name="changePassword"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  onFinish={onFinishChangePassword}
                  onFinishFailed={onFinishFailed}
                  autoComplete="nope"
                  labelAlign="left"
                  labelWrap
                  colon={false}
              >
                  <Form.Item
                      label={t('Old password')}
                      name={'oldPassword'}
                      rules={[
                        { required: true, message: t('Please input your password!') },
                      ]}
                  >
                      <Input.Password />
                  </Form.Item>
                  <Form.Item
                      label={t('New password')}
                      name={'newPassword'}
                      rules={[
                        { required: true, message: t('Please input your password!') },
                      ]}
                  >
                      <Input.Password />
                  </Form.Item>
                  <Form.Item
                      label={t('Repeat New password')}
                      name={'newSecondPassword'}
                      rules={[
                        { required: true, message: t('Please repeat your password!') },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('The two passwords that you entered do not match!')));
                          },
                        }),
                      ]}
                  >
                      <Input.Password />
                  </Form.Item>
                  {[
                    renderMessageError,
                    renderMessageOk,
                  ]}
                  <Flex justify={'flex-end'} align={'flex-end'} flex={'1 0 auto'}>
                      <Button type="primary" htmlType="submit" aria-label={'Change password'}>
                          {t('Change password')}
                      </Button>
                  </Flex>
              </Form>
          </Spin>
      </>
  );
};
