import * as React from 'react';
import { Menu, MenuProps } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MenuOutlined } from '@ant-design/icons';
import { colors } from '../common/constStyles';
// import { paths } from '../common';

const MenuStyled = styled(Menu)`
  width: auto;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 0px;
    flex-direction: column;
    width: auto;
    margin-bottom: -4px!important;
    
    
    && .ant-menu-submenu-selected::after{
      border-bottom-color: white;
    }
  }
`;

export const MainHeaderMenu: React.FC = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const items: MenuProps['items'] = [
    {
      label: (
            <Link to={'/#'} >
                {t('Home')}
            </Link>
      ),
      key: 'home',
    },
    {
      label: (
            <Link to={'/about'}>
              {t('About Us')}
            </Link>
      ),
      key: 'about',
    },
    {
      label: (
            <Link to={'/contact'}>
              {t('Contact')}
            </Link>
      ),
      key: 'contact',
    },
    {
      label: (
              <Link to={'/blog'}>
                  {t('Blog')}
              </Link>
      ),
      key: 'blog',
    },
    {
      label: (
            <Link to={'/new_in_canada'}>
              {t('For Newcomers')}
            </Link>
      ),
      key: 'new_in_canada',
    },
  ];

  return (
          <MenuStyled
              selectedKeys={[location.pathname.replace('/', '') || 'home']}
              mode="horizontal"
              items={items}
              overflowedIndicator={<MenuOutlined style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: 30 }} />}
              style={{
                border: 'none',
                fontWeight: 600,
                fontSize: '1.3em',
                lineHeight: '1.6em',
                // marginBottom: '10px',
                // fontFamily: 'sans-serif',
                // textTransform: 'uppercase',
                color: colors.colorTextDark,
              }}
        />
  );
};
