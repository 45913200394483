import * as React from 'react';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { Flex } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { colors } from '../../common/constStyles';
import { CategoryServiceType } from '../../../../common/types';
import { setterApp } from '../../store/slices/appSlice';
import { AppDispatch } from '../../store';

const DivStyled = styled.div<{ color: string }>`
  cursor: pointer;
  text-align: center;
  padding: 10px;
  width: 120px;

  .link_service {
    width: 100%;
    height: 100%;
    color: ${({ color }) => color};
  }

  .main_text {
    font-weight: 500;
    font-size: 1rem;
    color: ${colors.colorTextDark};
    padding-top: 0.5em;
    height: 48px;
  }

  .link_service:hover svg {
    transform: scale(1.3);
    transition: all 0.1s ease-in 0s;
    
  }
  
  .link_service:hover .icon_service svg{
    color: white;
  }
  
  .link_service:hover .main_text{
    transform: scaleX(1.01);
    transition: all 0.1s ease-in 0s;
  }
  .link_service:hover .icon_service{
    color: white;
    background-color: ${({ color }) => color};
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .icon_service {
    color: ${colors.colorTextDark};
    height: 78px;
    width: 78px;
    border-radius: 10px;
    border: 1px solid ${colors.colorBorder};
    background-color: #ffffff;
  }
  
  .icon_service svg {
    width: 50%;
    height: 50%;
    color: ${({ color }) => color};
  }
    
  @media (max-width: 768px) {
      padding: 10px 5px;

      .main_text {
        font-weight: 500;
        font-size: 0.7rem;
      }
    }
`;

interface ICardService {
  category?: CategoryServiceType ;
  icon: ReactElement,
  color: string,
  linkTo?: string
}

export const CardService: React.FC<ICardService> = ({
  icon, color, linkTo, category,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const onClickService = (categoryService:CategoryServiceType) => {
    console.log('onClickService');
    dispatch(setterApp({ currentCategory: categoryService.category, currentGroupCategory: categoryService.caption, filterCategory: categoryService.category }));
    navigate(categoryService.path);
  };

  return (
      <>
          {
              !linkTo && category
                ? (<DivStyled color={color}>
                      <Flex className={'link_service'} vertical align={'center'} justify={'center'} onClick={() => onClickService(category)}>
                          <Flex className={'icon_service'} align={'center'} justify={'center'}>
                              {icon}
                          </Flex>
                          <p className={'main_text'}>{category.caption}</p>
                      </Flex>
                  </DivStyled>)
                : (<DivStyled color={color}>
                    <Link to={linkTo || 'home'}>
                          <Flex className={'link_service'} vertical align={'center'} justify={'center'}>
                              <Flex className={'icon_service'} align={'center'} justify={'center'}>
                                  {icon}
                              </Flex>
                              <p className={'main_text'}>{'More'}</p>
                          </Flex>
                  </Link>
                  </DivStyled>)
          }
      </>
  );
};
