import {
  blue,
  cyan,
  geekblue,
  gold,
  gray,
  green,
  grey,
  lime,
  magenta,
  orange,
  purple,
  red,
  volcano,
  yellow,
} from '@ant-design/colors';

export const colors = {
  colorTextDark: 'rgb(42, 53, 71)',
  colorTextLight: 'rgb(112 121 134)',
  colorViolet: 'rgb(93, 135, 255)',
  colorRed: 'rgb(250, 137, 107)',
  colorOrange: 'rgb(255, 174, 31)',
  colorLightBlue: 'rgb(73, 190, 255)',
  colorBorder: 'rgb(228, 234, 239)',
  colorMain: 'rgb(9 88 217)',
  colorSecond: 'rgb(239 244 251)',
  colorThird: 'rgb(244, 248, 255)',
  colorGrey: 'rgb(248, 249, 251)',
  colorGreyDark: 'rgb(217, 217, 217)',
  colorRedError: '#f5222d',
  colorGreenSuccess: '#35c417',
  colorGreenSuccessDark: '#35c41710',
  colorMiniCardRes: 'rgb(235 237 240)',
};

export const fonts = {
  font3: 'Plus Jakarta Sans, sans-serif',
};

export const COLORS = [red, volcano, orange, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, gray];
