import {
  Button, Flex, Form, Input, Spin,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { PanelShadow } from '../components/PanelShadow';
import { ResponseLoginType } from '../../../common/types';
import {
  API, COOKIE_ACCESS_TOKEN, COOKIE_ID_TOKEN, COOKIE_REFRESH_TOKEN, paths,
} from '../common';
import { useAlertMessage } from '../hooks/useAlertMessage';
import { TitleLoginStyle } from '../style_component/TitleLoginStyle';
import { Logo } from '../components/Logo';

export const ResetPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const [msgError, setMsgError] = useState('');
  const [msgOk, setMsgOk] = useState('');
  const [keyConfirmPassword, setKeyConfirmPassword] = useState(false);
  const [spin, setSpin] = useState(false);

  const clearToken = useCallback(() => {
    Cookies.remove(COOKIE_ID_TOKEN);
    Cookies.remove(COOKIE_REFRESH_TOKEN);
    Cookies.remove(COOKIE_ACCESS_TOKEN);
  }, []);

  useEffect(() => {
    clearToken();
  }, []);

  const onFinishResetPassword = useCallback(async (values: any) => {
    setMsgError('');
    setMsgOk('');

    setSpin(true);

    let dataRequest;
    let path;

    if (keyConfirmPassword) {
      dataRequest = {
        Username: values.Username.toLowerCase(),
        ConfirmationCode: values.ConfirmationCode,
        Password: values.NewPassword,
      };

      path = paths.CONFIRM_RESET_PASSWORD;
    } else {
      dataRequest = {
        Username: values.Username.toLowerCase(),
      };

      path = paths.RESET_PASSWORD;
    }

    try {
      const result = (await axios.post<ResponseLoginType>(`${API.URL_LOGIN}${path}`, JSON.stringify(dataRequest))).data;
      setSpin(false);

      if (keyConfirmPassword) {
        history(paths.MAIN);
        history(paths.RESET_PASSWORD);
      }

      if (result.message) {
        setKeyConfirmPassword(true);
        setMsgOk(result.message);
      }
    } catch (e: any) {
      setSpin(false);
      setMsgError(JSON.stringify(e.response.data.message));
    }
  }, [keyConfirmPassword]);

  const onFinishFailed = useCallback((errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }, []);

  const renderMessageError = useAlertMessage('error', msgError);
  const renderMessageOk = useAlertMessage('success', msgOk);

  return (
      <div style={{
        flex: '1 1 auto', padding: '40px',
      }}>
      <PanelShadow>
        <Logo margin={'0 0 30px 0'} type={'full'} />
          <div style={{ marginTop: '20px' }}>
            <Spin spinning={spin} tip={t('Please wait')}>
                <Form
                    name="resetPassword"
                    onFinish={onFinishResetPassword}
                    onFinishFailed={onFinishFailed}
                    autoComplete="nope"
                    size={'large'}
                    layout={'vertical'}
                >
                    {
                        keyConfirmPassword
                          ? <TitleLoginStyle level={5}>{t('Please enter code and new password')}</TitleLoginStyle>
                          : <TitleLoginStyle level={5} style={{ fontWeight: 'lighter' }}>
                                {t('Please enter the email address associated with your account and We will send you a link to reset your password')}
                          </TitleLoginStyle>
                    }
                    <Form.Item
                        label={t('Email')}
                        name={'Username'}
                        rules={[
                          { required: true, message: t('Please input your Email!') },
                          {
                            type: 'email',
                            message: t('The input is not valid Email!'),
                          },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    {
                        keyConfirmPassword
                          ? <>
                                <Form.Item
                                    label={t('Verification code')}
                                    name={'ConfirmationCode'}
                                    rules={[
                                      { required: true, message: t('Please input a verification code!') },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={t('New Password')}
                                    name={'NewPassword'}
                                    rules={[
                                      { required: true, message: t('Please input your a new Password!') },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </>
                          : null
                    }
                    {[
                      renderMessageError,
                      renderMessageOk,
                    ]}
                    <Flex justify={'space-between'} vertical={true} gap={10}>
                            <Button type="primary" htmlType="submit" aria-label={'Password'}>
                                {
                                    keyConfirmPassword
                                      ? t('Save password')
                                      : t('Reset password')
                                }
                            </Button>
                        <Button type="default" onClick={() => {
                          history(paths.LOGIN);
                        }} aria-label={'Back to Login'}>
                            {t('Back to Login')}
                        </Button>
                    </Flex>
                </Form>
            </Spin>
          </div>
    </PanelShadow>
      </div>
  );
};
