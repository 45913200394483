import * as React from 'react';
import { OfficeType } from '../../../common/types';
import { GoogleMapComponent } from './GoogleMapComponent';

interface IMapBlock {
  offices: OfficeType[]
}

export const MapPublicBlock: React.FC<IMapBlock> = ({ offices }) => (
      <>
        <div style={{
          width: '98vw', marginLeft: '-50%', marginRight: '-50%', overflowX: 'hidden',
        }}>
          <GoogleMapComponent offices={offices} height={'600px'}/>
        </div>
      </>
);
