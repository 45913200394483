import * as React from 'react';
import { Avatar, Flex } from 'antd';
import { EmailOutlined, FacebookOutlined, WhatsApp } from '@mui/icons-material';
import { styled } from 'styled-components';
import { colors } from '../common/constStyles';

const AvatarStyled = styled(Avatar)`
  background-color: ${colors.colorMain};
  color: white;
`;

export const SocialBlock: React.FC = () => (
    <Flex gap={20} justify={'center'}>
        <a href="mailto:info@servicein.ca" target={'_blank'} aria-label={'MailTo'}>
            <AvatarStyled
                size={80}
                icon={<EmailOutlined fontSize={'large'}/>}
            />
        </a>
        <a href="https://www.facebook.com/servicein_official" target={'_blank'} aria-label={'Facebook'}>
            <AvatarStyled
                size={80}
                icon={<FacebookOutlined fontSize={'large'}/>}
            />
        </a>
        <a href="https://chat.whatsapp.com/BmezOXd6iyH2fgNp8jq1H4" target={'_blank'} aria-label={'WhatsApp'}>
            <AvatarStyled
                size={80}
                icon={<WhatsApp fontSize={'large'}/>}
            />
        </a>
    </Flex>
);
