import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { ScrollUp } from '../components/ScrollUp';

const DivStyled = styled.div`
  margin: 0 auto;
`;

export const TermsPage: React.FC = () => (<>
          <Helmet>
              <title>Terms and Conditions | ServiceIn.ca</title>
              <meta name="description" content="We help people connect with local businesses through online-catalog of local businesses - from small businesses to huge companies" />
          </Helmet>
          <ScrollUp/>
          <DivStyled>
              <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                  <h1>Terms and Conditions</h1>
                  <h5>Last updated August 12, 2024</h5>
                  <p>These Terms and Conditions ("Agreement") govern the use of the ServiceIn website ("Website") and the services ("Services") offered by ServiceIn ("we," "us," or "our"). By accessing or using the Website, you ("User," "you") agree to comply with and be bound by these terms.</p>
                  <h2>1. Acceptance of Terms</h2>
                  <p>By accessing the Website and using our Services, you accept and agree to be bound by these Terms and Conditions. If you do not agree to these terms, you may not use our Website.</p>
                  <h2>2. Eligibility</h2>
                  <p>You must be at least 18 years old to use our Services. By using the Website, you represent and warrant that you meet this age requirement.</p>
                  <h2>3. Account Registration</h2>
                  <p>To use certain features of the Website, you may be required to register an account. You agree to provide accurate and complete information during registration and to update your information as necessary.</p>
                  <h2>4. Use of the Service</h2>
                  <p>You agree to use the Website and Services only for lawful purposes and in accordance with these Terms. You agree not to:</p>
                  <ul>
                      <li>
                          <p>Violate any applicable laws or regulations.</p>
                      </li>
                      <li>
                          <p>Use the Service to infringe upon the rights of others.</p>
                      </li>
                      <li>
                          <p>Engage in any activity that disrupts or interferes with the Website's functionality.</p>
                      </li>
                  </ul>
                  <h2>5. User-Generated Content</h2>
                  <p>You may be allowed to submit content ("User Content") to the Website. You retain ownership of your User Content but grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, and display it.</p>
                  <h2>6. Privacy</h2>
                  <p>Your use of the Website is also governed by our Privacy Policy, which is incorporated into these Terms by reference. Please review our Privacy Policy to understand our practices regarding your personal data.</p>
                  <h2>7. Intellectual Property</h2>
                  <p>All content, trademarks, and data on this Website, including but not limited to text, graphics, logos, icons, and software, are the property of ServiceIn and are protected by copyright and other intellectual property laws.</p>
                  <h2>8. Termination</h2>
                  <p>We reserve the right to suspend or terminate your access to the Website at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users.</p>
                  <h2>9. Disclaimers and Limitation of Liability</h2>
                  <p>The Website and Services are provided "as is" without any warranties of any kind. We do not guarantee the accuracy, completeness, or usefulness of any information provided through the Service.</p>
                  <h2>10. Governing Law</h2>
                  <p>These Terms shall be governed and construed in accordance with the laws of the Province of British Columbia, Canada, without regard to its conflict of law provisions.</p>
                  <h2>11. Changes to the Terms</h2>
                  <p>We may update these Terms and Conditions from time to time. We will notify you of any changes by posting the new Terms on this page with an updated "Last Updated" date.</p>
                  <h2>12. Contact Us</h2>
                  <p>If you have any questions about  these Terms and Conditions, You can contact us:</p>
                  <ul>
                      <li>By email: info@servicein.ca</li>
                  </ul>
              </div>
          </DivStyled>
      </>
);
