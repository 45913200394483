import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Flex } from 'antd';
import { ButtonLogin } from './Buttons/ButtonLogin';
import { Logo } from './Logo';
import { isTokenValid } from '../common';
import { UserDropdownMenu } from './UserDropdownMenu';
import { colors } from '../common/constStyles';
import { LanguageDropdownMenu } from './LanguageDropdownMenu';
import { setLogin } from '../store/slices/settingsSlice';
import { AppDispatch } from '../store';
import { MainHeaderMenu } from './MainHeaderMenu';

const DivStyled = styled.div`
  && .logo_full {
    display: block;
  }

  && .logo_short {
    display: none;
  }

  @media (max-width: 768px) {
    && .logo_full {
      display: none;
    }

    && .logo_short {
      display: block;
    }
  }
`;
export const MainHeader: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    isTokenValid();
    dispatch(setLogin(''));
  }, []);

  return (
        <Flex style={{
          borderBottom: `1px solid ${colors.colorBorder}`, padding: '10px', justifyContent: 'space-between', width: '100%',
        }} align={'center'}>
            <DivStyled>
                <div className={'logo_full'} style={{ flexShrink: '0' }}><Logo type={'full'} margin={'0px'}/></div>
                <div className={'logo_short'} style={{ flexShrink: '0' }}><Logo type={'short'} margin={'0px'}/></div>
            </DivStyled>
                <MainHeaderMenu/>
            <Flex align={'center'}>
                <LanguageDropdownMenu/>
                <UserDropdownMenu/>
                {
                    !isTokenValid() && <ButtonLogin withIcon={true}/>
                }
            </Flex>
        </Flex>
  );
};
