import * as React from 'react';
import { useCallback, useState } from 'react';
import {
  Col, Form, Input, notification, Row, Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { NotificationFilled } from '@ant-design/icons';
import Cookies from 'js-cookie';
import {
  API, COOKIE_REGISTRATION_EMAIL, COOKIE_REGISTRATION_ROLE, paths, ROLE_USER,
} from '../../common';
import { PasswordCriteria } from '../PasswordCriteria';
import { useAlertMessage } from '../../hooks/useAlertMessage';
import { ResponseLoginType } from '../../../../common/types';

type UserFromType = {
  email: string,
  password: string,
};

interface IRegistrationForm{
  onCloseParentModal: () => void;
}

export const RegistrationForm: React.FC<IRegistrationForm> = ({ onCloseParentModal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [msgRegistration, setMsgRegistration] = useState('');
  const renderMessageErrorRegistration = useAlertMessage('error', msgRegistration);
  const [spinModalRegistration, setSpinModalRegistration] = useState(false);
  const [password, setPassword] = useState('');

  const openNotification = (text: string) => {
    notification.open({
      message: t('Registration process'),
      description: <div dangerouslySetInnerHTML={{ __html: `${text}` }} />,
      icon: <NotificationFilled style={{ color: '#108ee9' }}/>,
    });
  };

  const onFinish = useCallback(async (values: UserFromType) => {
    setSpinModalRegistration(true);
    setMsgRegistration('');
    try {
      const email = values.email.toLowerCase().trim();
      const result = (await axios.post<ResponseLoginType>(`${API.URL_LOGIN}${paths.REGISTRATION}`, {
        email,
        password: values.password.trim(),
        role: Cookies.get(COOKIE_REGISTRATION_ROLE) || ROLE_USER,
      })).data;

      Cookies.set(COOKIE_REGISTRATION_EMAIL, email);
      openNotification(result.message);
      onCloseParentModal();
    } catch (e: any) {
      setMsgRegistration(e.response.data.message);
    }
    setSpinModalRegistration(false);
  }, []);

  const onFinishFailed = useCallback((errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }, []);

  const handlePassword = (e: any) => {
    setPassword(e.target.value);
  };

  return (
      <Spin spinning={spinModalRegistration} tip={t('Please wait')} style={{ width: '100%' }}>
      <Form
          form={form}
          name="addUser"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="nope"
          size={'large'}
      >
          <Row>
              <Col span={24}>
                  <Row gutter={16}>
                      <Col span={24}>
                          <Form.Item
                              label={t('Email')}
                              name={'email'}
                              rules={[
                                { required: true, message: t('Please input your Email!') },
                                {
                                  type: 'email',
                                  message: t('The input is not valid Email!'),
                                },
                              ]}
                              initialValue={''}
                          >
                              <Input/>
                          </Form.Item>
                          <Form.Item
                              label={t('Password')}
                              name={'password'}
                              rules={[
                                { required: true, message: t('Please input your Password!') },
                                {
                                  pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.*&^%$#!+_-]).{8,}$/,
                                  message: '',
                                },
                              ]}
                              initialValue={''}
                          >
                              <Input.Password onChange={handlePassword} allowClear/>
                          </Form.Item>
                          <PasswordCriteria password={password} />
                          {[
                            renderMessageErrorRegistration,
                          ]}
                      </Col>
                  </Row>
              </Col>
          </Row>
      </Form>
      </Spin>
  );
};
