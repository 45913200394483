import styled from 'styled-components';
import { Flex, Image } from 'antd';
import { colors } from '../../common/constStyles';

const imgWidth = 124;
const imgHeight = 124;

export const FlexStyled = styled(Flex)`
  padding: 10px;
  border: 2px dashed ${colors.colorBorder};
  border-radius: 12px;
`;

export const ImageStyled = styled(Image)`
  min-width: ${imgWidth}px;
  min-height: ${imgHeight}px;
  object-fit: cover;
`;

export const DivStyled = styled.div`
  width: ${imgWidth}px;
  height: ${imgHeight}px;
  overflow: hidden;
`;
