import * as React from 'react';
import { ServiceType, TypeViewType } from '../../../../common/types';
import { MiniCardStyledGrid } from './MiniCardStyledGrid';
import { MiniCardStyledList } from './MiniCardStyledList';
import { SIZE_IMAGE } from '../../common';

interface IMiniCardBusiness {
  service: ServiceType,
  typeView: TypeViewType;
}

export const MiniCardBusiness: React.FC<IMiniCardBusiness> = ({ service, typeView }) => {
  const [firstOffice] = service.office;

  const imageSrcAvatar = `${process.env.REACT_APP_S3_STORAGE_URL}${process.env.REACT_APP_BUCKET}/${service.userId}/${SIZE_IMAGE['244']}${service.profile.avatar}`;
  return <>
        {typeView === 'grid' && <MiniCardStyledGrid imageSrcAvatar={imageSrcAvatar} firstOffice={firstOffice} service={service} /> }
        {typeView === 'list' && <MiniCardStyledList imageSrcAvatar={imageSrcAvatar} firstOffice={firstOffice} service={service} /> }
    </>;
};
