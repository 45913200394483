import { Col, Row } from 'antd';
import * as React from 'react';
import { ServiceType } from '../../../common/types';
import { PriceBusiness } from './PriceBusiness';

interface ITabPrices {
  servicePublic: ServiceType,
}

export const TabPrices: React.FC<ITabPrices> = ({ servicePublic }) => (<>
          <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <PriceBusiness service={servicePublic}/>
              </Col>
          </Row>
        </>
);
