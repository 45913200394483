import {
  Button, Col, Form, Input, Row, Spin,
} from 'antd';

import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import axios from 'axios';

import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  API,
  COOKIE_ACCESS_TOKEN,
  COOKIE_ID_TOKEN,
  COOKIE_REFRESH_TOKEN,
  COOKIE_SESSION,
  isTokenValid,
  paths,
} from '../common';
import { PanelShadow } from '../components/PanelShadow';
import { ResponseLoginType } from '../../../common/types';
import { useAlertMessage } from '../hooks/useAlertMessage';
import { TitleLoginStyle } from '../style_component/TitleLoginStyle';
import { Logo } from '../components/Logo';
import { selectLogin } from '../store';

export const SetNewPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const [msgError, setMsgError] = useState('');
  const [spin, setSpin] = useState(false);
  const showLogin = useRef(false);
  const history = useNavigate();
  const login = useSelector(selectLogin);

  const clearToken = useCallback(() => {
    Cookies.remove(COOKIE_ID_TOKEN);
    Cookies.remove(COOKIE_REFRESH_TOKEN);
    Cookies.remove(COOKIE_ACCESS_TOKEN);
  }, []);

  useEffect(() => {
    if (!login) {
      history(paths.MAIN);
    }
    clearToken();
  }, []);

  const setToken = useCallback((authResult: AuthenticationResultType) => {
    Cookies.set(COOKIE_ID_TOKEN, authResult.IdToken || '');
    Cookies.set(COOKIE_REFRESH_TOKEN, authResult.RefreshToken || '');
    Cookies.set(COOKIE_ACCESS_TOKEN, authResult.AccessToken || '');
  }, []);

  const onFinishSetNewPassword = useCallback(async (values: any) => {
    setSpin(true);
    showLogin.current = false;

    try {
      const result = (await axios.post<ResponseLoginType>(`${API.URL_LOGIN}${paths.SET_NEW_PASSWORD}`, JSON.stringify({
        username: login,
        newPassword: values.password,
        session: Cookies.get(COOKIE_SESSION),
      }))).data;

      Cookies.remove(COOKIE_SESSION);
      setToken(result.authResult!);

      setSpin(false);
      if (isTokenValid()) {
        setTimeout(() => {
          history(paths.USER_PROFILE);
        }, 200);
      }
    } catch (e: any) {
      setSpin(false);
      setMsgError(e.response.data.message);
      if (e.response.data.message === 'Session timed out. Please try login again.') {
        showLogin.current = true;
      }
      setMsgError(JSON.stringify(e.response.data.message));
    }
  }, []);

  const onFinishFailed = useCallback((errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }, []);

  const renderMessageError = useAlertMessage('error', msgError);

  return (
    <PanelShadow>
        <Logo margin={'0 0 30px 0'} type={'full'} />
        {
            login
              ? <Spin spinning={spin} tip={t('Please wait')}>
            <Form
              name="setNewPassword"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinishSetNewPassword}
              onFinishFailed={onFinishFailed}
              autoComplete="nope"
            >
              <TitleLoginStyle level={5}>Please enter a new password</TitleLoginStyle>

              <Form.Item
                label={t('New password')}
                name={'password'}
                rules={[
                  { required: true, message: t('Please input your password!') },
                ]}
              >
                <Input.Password />
              </Form.Item>
                {
                    renderMessageError
                }
              <Row>
                <Col span={14} style={{ padding: 3 }}>
                  {
                    showLogin.current && <Button onClick={() => { history(`${paths.LOGIN}`); }} aria-label={'Login'}>
                        {t('Login')}
                      </Button>
                  }
                </Col>
                <Col span={10} style={{ textAlign: 'right' }}>
                  <Button type="primary" htmlType="submit" aria-label={'Save password'}>
                    {t('Save password')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
              : <Spin spinning={true} tip={t('Please wait')}>
                    <div style={{ height: '100px' }}></div>
                </Spin>
        }
    </PanelShadow>
  );
};
