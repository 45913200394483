export class API {
  public static STATUS_200 = 200;

  public static STATUS_302 = 302;

  public static STATUS_400 = 400;

  public static API_KEY = process.env.REACT_APP_API_KEY!;

  public static URL_LOGIN = process.env.REACT_APP_URL_API_LOGIN!;

  public static URL_REST_API = process.env.REACT_APP_REST_API!;

  public static URL_USER = process.env.REACT_APP_URL_USER!;

  public static GOOGLE_MAPS = process.env.REACT_APP_GOOGLE_API_KEY;
}
