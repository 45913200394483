import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ModalRegistration } from './ModalRegistration';
import { ModalPost } from './ModalPost';
import { AppDispatch, RootState } from '../../store';
import { initialPost, setterPost } from '../../store/slices/postsSlice';
import { ModalQuestion } from './ModalQuestion';
import { initialQuestion, setterQuestion } from '../../store/slices/questionsSlice';

const AStyled = styled.a`
  text-decoration: underline;
  margin: 0 16px;
  color: #172145;
  transition: .4s
`;

interface IButtonOpenModal{
  mode: 'link' | 'button',
  type: 'registration' | 'post' | 'question',
  title: string,
  disabled?: boolean
}

export const ButtonOpenModal: React.FC<IButtonOpenModal> = ({
  mode, disabled, title,
  type = 'registration',
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const showModalPost = useSelector((state: RootState) => state.post.showModal);
  const showModalQuestion = useSelector((state: RootState) => state.question.showModal);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isRegistration = type === 'registration';
  const isPost = type === 'post';
  const isQuestion = type === 'question';

  const showModal = (event: any) => {
    setIsModalVisible(true);
    if (isPost) {
      dispatch(setterPost({
        currentPost: {
          ...initialPost,
          id: uuidv4(),
        },
      }));
    } else if (isQuestion) {
      dispatch(setterQuestion({
        currentQuestion: {
          ...initialQuestion,
          id: uuidv4(),
        },
      }));
    }
    console.log(event);
  };

  const handleCancel = async () => {
    setIsModalVisible(false);
    dispatch(setterPost({ showModal: false }));
    dispatch(setterQuestion({ showModal: false }));
  };

  return (
      <>
        {
            mode === 'link' && <AStyled onClick={showModal}>
                {title}
            </AStyled>
        }
        {
            mode === 'button' && <Button type={'primary'} onClick={showModal} disabled={disabled} style={{ margin: 0 }} aria-label={'Sign up'}>{title}</Button>
        }
        {
            isRegistration && <ModalRegistration isModalVisible={isModalVisible} handleCancel={handleCancel} />
        }
        {
            isPost && <ModalPost isModalVisible={isModalVisible || showModalPost} handleCancel={handleCancel} />
        }
        {
            isQuestion && <ModalQuestion isModalVisible={isModalVisible || showModalQuestion} handleCancel={handleCancel} />
        }
      </>
  );
};
