import {
  Col, Flex, Image, Radio, RadioChangeEvent, Row,
} from 'antd';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FlagIconFactory from 'react-flag-icon-css';
import { Diversity2 } from '@mui/icons-material';
import { ServiceType } from '../../../common/types';
import { SIZE_IMAGE } from '../common';
import { setterApp } from '../store/slices/appSlice';
import { useCategory } from '../hooks/useGategory';
import { AppDispatch, RootState } from '../store';

interface IFullCardHeaderService {
  servicePublic: ServiceType,
}
const FlagIcon = FlagIconFactory(React, { useCssModules: false });

export const FullCardHeaderService: React.FC<IFullCardHeaderService> = ({ servicePublic }) => {
  const { CATEGORY_SERVICES } = useCategory();
  const dispatch = useDispatch<AppDispatch>();

  const currentLanguage = useSelector((state: RootState) => state.app.currentLanguageForFormService);

  const onChangeLanguage = (e: RadioChangeEvent) => {
    const newLanguage = e.target.value;
    dispatch(setterApp({ currentLanguageForFormService: newLanguage }));
  };

  return (<>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Flex className={'header_fullcard'} align={'center'} gap={20}>
                        {
                            servicePublic.userId
                            && (servicePublic.profile.logo
                              ? <Image
                                src={`${process.env.REACT_APP_S3_STORAGE_URL}${process.env.REACT_APP_BUCKET}/${servicePublic.userId}/${SIZE_IMAGE['400']}${servicePublic.profile.logo}`} preview={false}
                                style={{ maxHeight: 77 }}
                                />
                              : <Diversity2 color={'info'} style={{ width: '56px', height: '56px' }} />)
                            }
                        <Flex className={'header_fullcard__content'} justify={'space-between'}>
                            <div>
                                <p className={'text_header'}>{servicePublic.profile.companyName}</p>
                                <p className={'text_subheader'}>{CATEGORY_SERVICES[servicePublic.profile.category]}</p>
                            </div>
                            <Flex vertical={true}>
                                {servicePublic.profile.supportLanguage
                                && <div>
                                        <Radio.Group onChange={onChangeLanguage} value={currentLanguage} size={'large'} style={{ paddingTop: '10px' }}>
                                            {servicePublic.profile.supportLanguage.map((language) => (
                                                <Radio.Button key={language} value={language} style={{
                                                  backgroundColor: 'transparent', padding: '0 10px',
                                                }}>
                                                    <FlagIcon key={language} code={language} />
                                                </Radio.Button>
                                            ))}
                                        </Radio.Group>
                                    </div>
                                }
                            </Flex>
                        </Flex>
                    </Flex>
                </Col>
            </Row>
        </>
  );
};
