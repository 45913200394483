import * as React from 'react';
import { Button, Flex } from 'antd';
import {
  Facebook, Instagram, LinkedIn, Twitter, YouTube,
} from '@mui/icons-material';
import { TikTokOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { SocialLinks } from '../../../common/types';
import { colors } from '../common/constStyles';

interface ISocialBlockService{
  links: SocialLinks
}

const styleIcon = {
  fontSize: '3em',
};

const FlexStyled = styled(Flex)`

  && .ant-btn-link{
    color: ${colors.colorTextDark}
  }
`;

export const SocialBlockService: React.FC<ISocialBlockService> = ({ links }) => (
    <FlexStyled gap={20} align={'start'} wrap={'wrap'}>
        {links.linkInstagram
            && <a href={links.linkInstagram} target={'_blank'} aria-label={'Instagram'}>
            <Button type="link" aria-label={'Instagram'}><Instagram sx={styleIcon }/></Button>
        </a>}
        {links.linkFacebook
            && <a href={links.linkFacebook} target={'_blank'} aria-label={'Facebook'}>
                <Button type="link" aria-label={'Facebook'}><Facebook sx={styleIcon}/></Button>
            </a>}
        {links.linkTwitter
            && <a href={links.linkTwitter} target={'_blank'} aria-label={'Twitter'}>
                <Button type="link" aria-label={'Instagram'}><Twitter sx={styleIcon}/></Button>
            </a>}
        {links.linkLinkedIn
            && <a href={links.linkLinkedIn} target={'_blank'} aria-label={'Twitter'}>
                <Button type="link" aria-label={'Instagram'}><LinkedIn sx={styleIcon}/></Button>
            </a>}
        {links.linkTiktok
            && <a href={links.linkTiktok} target={'_blank'} aria-label={'Tiktok'}>
                <Button type="link" aria-label={'Instagram'}><TikTokOutlined style={styleIcon}/></Button>
            </a>}
        {links.linkYouTube
            && <a href={links.linkYouTube} target={'_blank'} aria-label={'Tiktok'}>
                <Button type="link" aria-label={'Instagram'}><YouTube style={styleIcon}/></Button>
            </a>}
    </FlexStyled>
);
