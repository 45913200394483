import * as React from 'react';
import { ReactElement } from 'react';
import { Button, Modal } from 'antd';
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { IconButton } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const { confirm } = Modal;

interface IButtonConfirmationDelete {
  typeButton: 'btn' | 'btnIcon' | 'full',
  callbackOk: () => void,
  callbackCancel?: () => void,
  icon?: ReactElement
  size?: 'small' | 'medium' | 'large',
}

export const ButtonConfirmationDelete: React.FC<IButtonConfirmationDelete> = ({
  typeButton,
  callbackOk,
  callbackCancel,
  icon,
  size,
}) => {
  const { t } = useTranslation();
  const showDeleteConfirm = (e: any) => {
    e.stopPropagation();
    confirm({
      title: t('Are you sure delete?'),
      icon: <ExclamationCircleFilled/>,
      content: t('Data will be deleted with no recovery'),
      okText: t('Yes'),
      okType: 'danger',
      cancelText: t('No'),
      onOk: callbackOk,
      onCancel: callbackCancel,
    });
  };

  const showButtonByType = (type: string) => {
    switch (type) {
      case 'btn':
        return (<Button type="primary" onClick={showDeleteConfirm} danger ghost size={'large'} aria-label={'Delete'}>
                    {t('Delete')}
                </Button>);
      case 'btnIcon':
        return (<IconButton aria-label="del_service" color={'error'} onClick={showDeleteConfirm} size={size}>
                    {icon || <DeleteOutline/>}
                </IconButton>);
      case 'full':
        return (<Button type="primary" onClick={showDeleteConfirm} danger ghost size={'small'} icon={<DeleteOutlined />} aria-label={'Delete'}>
                    {t('Delete')}
                </Button>);
      default:
        return null;
    }
  };

  return (
        <>
            {showButtonByType(typeButton)}
        </>
  );
};
