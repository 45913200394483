import React, { useCallback, useEffect, useState } from 'react';
import {
  Button, Flex, message, Spin,
} from 'antd';

import Cookies from 'js-cookie';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { FileUpload } from './FileUpload';
import {
  API, COOKIE_ID_TOKEN, COOKIE_REFRESH_TOKEN, decodeToken, refreshToken,
} from '../common';
import { DataUserType, ResponseLoginType } from '../../../common/types';
import { AppDispatch, selectUser } from '../store';
import { setCurrentUserChanges, setFlagUpdateIcon } from '../store/slices/userSlice';

export const ChangeAvatar: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectUser);
  const [spin, setSpin] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const token = Cookies.get(COOKIE_ID_TOKEN) || '';
  const decodedToken = decodeToken(token);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setCurrentUserChanges({ picture: '' } as DataUserType));
    }, 2000);
  }, [user.flagUpdateIcon]);

  const save = useCallback(async () => {
    setSpin(true);
    try {
      const data = { picture: user.picture };
      await axios.post<ResponseLoginType>(`${API.URL_USER}/update`, data);

      const subRefreshToken = Cookies.get(COOKIE_REFRESH_TOKEN);
      await refreshToken(subRefreshToken!);
      dispatch(setFlagUpdateIcon());

      messageApi.open({
        type: 'success',
        content: 'Data have been saved successfully',
      });
    } catch (e) {
      console.log(e);
    } finally {
      setSpin(false);
    }
  }, [user]);

  const reset = useCallback(async () => {
    setSpin(true);
    try {
      dispatch(setCurrentUserChanges({ picture: '' } as DataUserType));
    } catch (e) {
      console.log(e);
    } finally {
      setSpin(false);
    }
  }, [user]);

  return (
    <>
      {contextHolder}
      <Spin spinning={spin} tip={'please wait ...'}>
        <Flex vertical={true} gap={20}>
            <Flex justify={'center'} gap={20}>
                <FileUpload
                    type={'avatarUser'}
                    dirName={decodedToken?.sub}/>
            </Flex>
            <Flex justify={'center'}>
              <p>Allowed JPG, GIF or PNG. Max size of 800K</p>
            </Flex>
          <Flex justify={'flex-end'} align={'flex-end'} flex={'1 0 auto'}>
            <Button style={{ marginRight: 20, display: !user.picture ? 'none' : 'block' }}
                    type="default" htmlType="submit" onClick={reset} aria-label={'Reset'}
                    >
              Reset
            </Button>
            <Button type="primary" htmlType="submit" onClick={save} disabled={!user.picture} aria-label={'Save'}>
              Save
            </Button>
          </Flex>
        </Flex>
      </Spin>
    </>
  );
};
