import * as React from 'react';
import { Avatar } from 'antd';
import { Diversity2 } from '@mui/icons-material';
import { FlexStyled } from './ImageStyled';

type AvatarServiceProps = {
  size?: number,
  imgSrc?: string
};

export const AvatarService: React.FC<AvatarServiceProps> = ({ size, imgSrc }) => (
        <FlexStyled>
            <Avatar src={imgSrc} icon={<Diversity2 />} size={size}/>
        </FlexStyled>
);
