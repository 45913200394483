import React, { useEffect, useState } from 'react';
import {
  Badge, Col, Divider, Empty, Flex, Form, Row, Spin, Typography,
} from 'antd';
import Search from 'antd/es/input/Search';
import styled from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Card, CardContent, IconButton } from '@mui/material';
import { Send } from '@mui/icons-material';
import TextArea from 'antd/es/input/TextArea';
import { ChatType, MessageType } from '../../../common/types';
import { colors } from '../common/constStyles';
import {
  API, COOKIE_ID_TOKEN, decodeToken, paths, SIZE_IMAGE,
} from '../common';
import { AvatarUser } from './Image/AvatarUser';
import { MessageCard } from './Cards/MessageCard';

export const DivStyled = styled.div`
  border: 1px solid ${colors.colorBorder};
  
  && .message_header{
    color: ${colors.colorTextLight};
    font-size: 0.8em;
    font-weight: 600;
    margin-bottom: 5px;
  }

  && .message_from .author{
    text-align: left;
  }

  && .message_from.not_author{
    text-align: right;
  }
  
  && .message_text{
    border: 1px solid ${colors.colorGrey};
    background-color: ${colors.colorSecond};
    padding: 20px;
    width: 500px;
    border-radius: 10px;
  }
  
  && .custom_textarea{
    padding-right: 50px;
  }
  
  && .box_notifications{

  }

  @media (max-width: 800px) {
    && .message_text{
      width: 100%;
    }
  }
`;

const CardStyled = styled(Card)`
  width: 100%;
  cursor: pointer;
  box-shadow: rgba(145, 158, 171, 0.3) 0 0 2px 0, rgba(145, 158, 171, 0.12) 0 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;
  
  &&.active {
    background-color: ${colors.colorSecond};
  }
  &&.notactive {
    //border: 2px solid rgba(145, 158, 171, 0.1);
  }
  
  &:hover{
    background-color: ${colors.colorSecond}
  }
`;

export const NotificationsPage: React.FC = () => {
  const [servicesNameTable, setServicesNameTable] = useState<{ [key: string]: string }>({});
  const [chats, setChats] = useState<ChatType[]>([]);
  const [currentChat, setCurrentChat] = useState<ChatType>();
  const token = Cookies.get(COOKIE_ID_TOKEN) || '';
  const decodedToken = decodeToken(token);
  const [form] = Form.useForm();
  const [spin, setSpin] = useState(false);

  const initialValues: Partial<MessageType> = {
    text: '', authorId: '', isRead: false, datetime: 0,
  };

  useEffect(() => {
    (async () => {
      const response = await axios.get<ChatType[]>(`${API.URL_REST_API}${paths.LIST_MESSENGER}`);
      const services: { [key: string]: string } = {};

      for (const chat of response.data) {
        services[chat.toServiceId] = chat.toServiceName;
      }

      setServicesNameTable(services);
      setChats(response.data);
    })();
  }, []);

  const onFinish = async (values: MessageType) => {
    try {
      setSpin(true);
      const result = (await axios.post(`${API.URL_REST_API}${paths.MESSENGER}/${currentChat?.fromUserId}/${currentChat?.toServiceId}`, values)).data;
      console.log(result, values);
      setSpin(false);
      form.resetFields();
    } catch (e: any) {
      console.log(e);
      setSpin(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onClickChatPanel = (chat: ChatType) => () => {
    setCurrentChat(chat);
  };

  const buildChatPanel = (chat: ChatType, linkPart: string, name: string) => {
    const countNewMessage = chat.messages.filter((message) => !message.isRead && decodedToken.sub !== message.authorId).length;

    return (
          <CardStyled variant="outlined"
                      key={chat.fromUserId}
                      onClick={onClickChatPanel(chat)}
                      className={currentChat?.fromUserId === chat.fromUserId && currentChat.toServiceId === chat.toServiceId ? 'active' : 'notactive'}>
              <CardContent>
                  <Flex gap={20} align={'center'} justify={'space-between'}>
                      <Flex gap={20} align={'center'}>
                          <AvatarUser
                              imgSrc={`${process.env.REACT_APP_S3_STORAGE_URL}${process.env.REACT_APP_BUCKET}/${linkPart}`}
                              size={42}/>
                          <p>{name}</p>
                      </Flex>
                      {
                          !!countNewMessage && <Badge count={countNewMessage}/>
                      }
                  </Flex>
              </CardContent>
          </CardStyled>
    );
  };

  const buildSetChatPanel = () => (
    Object.keys(servicesNameTable)
      .map((serviceId) => chats.filter((chat) => chat.toServiceId === serviceId)
        .map((chat) => (
            <div key={chat.fromUserId + chat.toServiceId}>
              {
                  decodedToken.sub === chat.fromUserId
                    ? buildChatPanel(chat, `${chat.toServiceId.split('/')[0]}/${SIZE_IMAGE['124']}${chat.toServiceIcon}`, chat.toServiceName)
                    : buildChatPanel(chat, `${chat.fromUserId}/${SIZE_IMAGE['124']}${chat.fromUserIcon}`, chat.fromUserName)
              }
            </div>
        ))));

  const buildMessages = () => currentChat?.messages.map((message, index) => {
    const isAuthor = decodedToken.sub === message.authorId;
    const authorName = (currentChat.fromUserId === message.authorId ? currentChat.fromUserName : currentChat.toServiceName);

    return (
              <MessageCard key={index} message={message} isAuthor={isAuthor} authorName={authorName} />
    );
  });

  return <DivStyled>
      <Row gutter={20} style={{ margin: '1.5em 0' }}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}
               style={{ borderRight: `1px solid ${colors.colorBorder}`, padding: '10px' }}>
              <div>
                  <Search placeholder="Search contact" size={'large'} enterButton width={'100%'}/>
              </div>
              <div style={{ padding: '20px 0' }}>
                  <Typography.Title level={4} style={{ margin: 0 }}>Recent chats</Typography.Title>
              </div>
              <Flex vertical gap={10}>
              {
                  chats.length
                    ? buildSetChatPanel()
                    : <Empty/>
              }
              </Flex>
          </Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} style={{
            display: 'flex', flex: '1 1 0%', overflowY: 'auto', flexDirection: 'column',
          }}>
              {
                  !currentChat?.messages.length
                    ? <Empty/>
                    : <div>
                          <Flex className={'header_notifications'} align={'center'} gap={20}>
                              {
                                  <AvatarUser
                                      imgSrc={`${process.env.REACT_APP_S3_STORAGE_URL}${process.env.REACT_APP_BUCKET}/${currentChat?.fromUserId}/${SIZE_IMAGE['124']}${currentChat?.fromUserIcon}`}
                                      size={42}/>
                              }
                              <Flex className={'header_notifications__content'} justify={'space-between'}>
                                  <div>
                                      <p className={'text_header'}>{currentChat?.fromUserName}</p>
                                  </div>
                              </Flex>
                          </Flex>
                          <Divider />
                        <div className={'box_notifications'}>
                          {
                              buildMessages()
                          }
                        </div>
                          <div style={{ width: '100%', position: 'relative' }}>
                              <Spin spinning={spin} tip={'Please wait ...'}>
                                  <Form
                                      form={form}
                                      name={'messageFromService'}
                                      style={{ width: '100%' }}
                                      initialValues={{ initialValues }}
                                      onFinish={onFinish}
                                      onFinishFailed={onFinishFailed}
                                      autoComplete="off"
                                      labelAlign={'left'}
                                  >
                                      <div>
                                          <Form.Item<MessageType>
                                              name="text"
                                              rules={[{ required: true, message: 'Please, fill this field!' }]}
                                              style={{ width: ' 100%' }}
                                          >
                                              <TextArea rows={2} maxLength={300} className={'custom_textarea'}/>
                                          </Form.Item>
                                          <IconButton color="primary"
                                                      aria-label="send_notification"
                                                      style={{ position: 'absolute', right: 20, top: 10 }} type={'submit'}>
                                              <Send />
                                          </IconButton>
                                      </div>
                                  </Form>
                              </Spin>
                          </div>
                    </div>
              }
          </Col>
      </Row>
  </DivStyled>;
};
