import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  Flex, FormInstance, message, Spin, Upload,
} from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { uploadFileApi } from '../uploadFile';
import { AvatarUser } from './Image/AvatarUser';
import { setCurrentUserChanges } from '../store/slices/userSlice';
import {
  AllowFormatImageType, DataUserType, imageSizeTypeType, ImageUploadType,
} from '../../../common/types';
import { useUserPictUrl } from '../hooks/useUserPictUrl';
import { AvatarService } from './Image/AvatarService';
import { LogoService } from './Image/LogoService';
import { ImageGalleryService } from './Image/ImageGalleryService';
import { defaultPict } from '../common/defaultPictConst';
import { COOKIE_ID_TOKEN, decodeToken, SIZE_IMAGE } from '../common';
import { useResizeImage } from '../hooks/useResizeImage';
import { AppDispatch, selectUser } from '../store';

const allowFormat: AllowFormatImageType[] = ['image/png', 'image/jpeg', 'image/webp', 'image/svg+xml'];

type UploadFilesProps = {
  form?: FormInstance<any>,
  nameField?: string,
  dirName: string;
  type: ImageUploadType;
  multiple?: boolean
};

export const FileUpload: React.FC<UploadFilesProps> = ({
  dirName, type, form, nameField, multiple = false,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const resizeImage = useResizeImage();
  const user = useSelector(selectUser);
  const [fileLinkList, setFileLinkList] = useState<string[]>([]);
  const [spin, setSpin] = useState(false);
  const userPictUrl = useUserPictUrl(244);

  const getLink = useCallback((fileName: string) => {
    const token = Cookies.get(COOKIE_ID_TOKEN) || '';
    const decodedToken = decodeToken(token);

    return [
      process.env.REACT_APP_S3_STORAGE_URL,
      process.env.REACT_APP_BUCKET,
      decodedToken['cognito:username'],
      SIZE_IMAGE[244] + fileName,
    ].join('/');
  }, []);

  useEffect(() => {
    if (user.picture) {
      setFileLinkList([getLink(user.picture)]);
    }
  }, [user]);

  useEffect(() => {
    if (form) {
      const arrayElementPath: string[] = (form.getFieldValue(nameField)?.split('/') || [])
        .filter((fileName: string) => fileName)
        .map((fileName: string) => getLink(fileName));

      setFileLinkList(arrayElementPath);
    } else {
      setFileLinkList([]);
    }
  }, [form, form && form.getFieldValue(nameField)]);

  // const getBase64 = useCallback(async (file: any): Promise<string> => new Promise((resolve, reject) => {
  //   console.log('>>>>>>>>>', file);
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file.originFileObj);
  //   reader.onload = () => resolve(reader.result as string);
  //   reader.onerror = (error) => reject(error);
  // }), []);

  const handleUpload = useCallback(async (file: File) => {
    const partOfFileName = file.name.split('.');
    const newFileName = `${uuidv4()}.${partOfFileName[partOfFileName.length - 1]}`;

    const linkSave = `${process.env.REACT_APP_S3_STORAGE_URL}/${process.env.REACT_APP_BUCKET}/${dirName}`;
    const prefixImageName: imageSizeTypeType = 1400;
    const newResizeImage = await resizeImage(file, prefixImageName, prefixImageName);

    let allowSizeImage: imageSizeTypeType[] = [];

    switch (type) {
      case 'logo': allowSizeImage = [244, 124, 400]; break;
      case 'gallery': allowSizeImage = [244]; break;
      case 'avatarUser': allowSizeImage = [60, 124, 244]; break;
      case 'avatarService': allowSizeImage = [244, 124]; break;
      default:
    }

    if (await uploadFileApi(newResizeImage as any, `${linkSave}/${SIZE_IMAGE[prefixImageName]}${newFileName}`)) {
      for (const subPrefixImageName of allowSizeImage) {
        await uploadFileApi(await resizeImage(newResizeImage, subPrefixImageName, subPrefixImageName), `${linkSave}/${SIZE_IMAGE[subPrefixImageName]}${newFileName}`);
      }

      // const newFileBase64 = await getBase64(file);
      // setFileBase64List((oldState) => [...oldState, newFileBase64]);
      if (type === 'avatarUser') {
        dispatch(setCurrentUserChanges({ picture: newFileName } as DataUserType));
      }

      if (form) {
        const oldValueFormField = form.getFieldValue(nameField) || '';

        let newValueFormField;
        if (multiple) {
          newValueFormField = oldValueFormField ? [...oldValueFormField.split('/'), newFileName].join('/') : newFileName;
        } else {
          newValueFormField = newFileName;
        }

        form.setFieldsValue({
          [`${nameField}`]: newValueFormField,
        });
      }
    }
  }, [form]);

  const propsUpload: UploadProps = {
    onRemove: (file: UploadFile<any>) => {
      console.log(file);
    },
    beforeUpload: async (file) => {
      if (!allowFormat.includes(file.type! as AllowFormatImageType)) {
        message.error(`Format file ${file.type} is not correct. Only png, jpeg, webp files.`);
      } else {
        setSpin(true);
        await handleUpload(file);
        setSpin(false);
      }

      return false;
    },
  };

  const buildImage = useCallback(() => {
    const [firstFileFromList] = fileLinkList;

    switch (type) {
      case 'avatarService': return <AvatarService key={2} imgSrc={firstFileFromList} size={124}/>;
      case 'avatarUser': return <AvatarUser key={1} imgSrc={firstFileFromList || userPictUrl} size={124}/>;
      case 'logo': return <LogoService key={3} imgSrc={firstFileFromList || defaultPict} size={124}/>;
      case 'gallery': return <ImageGalleryService key={4} form={form!}
                                                  nameField={nameField!}
                                                  multiple={multiple}
                                                  fileNameList={fileLinkList.length ? fileLinkList : [defaultPict]}
                                                  size={124}/>;
      default: return null;
    }
  }, [type, userPictUrl, fileLinkList]);

  return <>
    <Upload {...propsUpload} listType="text" multiple={multiple} accept={allowFormat.join()} showUploadList={false}>
      <Spin spinning={spin} tip={'load...'} style={{ width: '100%' }}>
        <Flex gap={10} style={{ cursor: 'pointer' }} wrap={'wrap'}>
          {
            buildImage()
          }
        </Flex>
      </Spin>
    </Upload>
  </>;
};
