import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Button, Flex, Tag } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { paths } from '../common';
import { colors } from '../common/constStyles';
import { AppDispatch, RootState } from '../store';
import { setSearchCategory, setterApp } from '../store/slices/appSlice';
import { fetchListServiceGlobal } from '../store/slices/servicesSlice';
import { useCategory } from '../hooks/useGategory';
import { FolderCategoryService } from './Cards/FolderCategoryService';

const DivStyled = styled.div`
  padding: 10px 40px 40px 40px;
    // background-color: ${colors.colorMain};
  width: 100%;

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

const SubCategoryStyled = styled.div`
  padding: 10px 10px 0 10px;
`;

const FlexStyled = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
`;

export const GroupFolderCategoryService: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const history = useNavigate();
  const { CATEGORY_SERVICES, GROUP_CATEGORY_SERVICES } = useCategory();
  const currentCategory = useSelector<RootState, string[]>((state) => state.app.currentCategory!);
  const currentGroupCategory = useSelector<RootState, string>((state) => state.app.currentGroupCategory);
  const filterCategory = useSelector<RootState, string[]>((state) => state.app.filterCategory!);
  const counterCategory = useSelector<RootState, { [key: string]: number }>((state) => state.services.counterCategory);
  const searchParam = useSelector<RootState, string>((state) => state.app.searchParam!);
  const [modeGroupFolder, setModeGroupFolder] = useState<'folder' | 'category'>();
  const [isHideFilter, setIsHideFilter] = useState(true);

  useEffect(() => {
    setModeGroupFolder('category');
  }, [currentCategory]);

  useEffect(() => {
    if (!searchParam) {
      return;
    }
    if (!currentCategory.length) {
      dispatch(fetchListServiceGlobal(`${searchParam}&counter=true`));
    } else {
      dispatch(fetchListServiceGlobal(`category=${[...filterCategory, '000'].join(',')}&${searchParam}&counter=true`));
    }
  }, [filterCategory, searchParam]);

  const onClickCategory = useCallback((category: string) => () => {
    if (filterCategory.includes(category)) {
      dispatch(setSearchCategory(filterCategory.filter((subCategory) => subCategory !== category)));
    } else {
      dispatch(setSearchCategory([...filterCategory, category]));
    }
    history(paths.SEARCH);
  }, [filterCategory]);

  const onClickBackToGroupFolderCategory = () => {
    setModeGroupFolder('folder');
  };

  const styleTag = {
    cursor: 'pointer',
    marginBottom: 10,
    margin: '5px',
    fontSize: 14,
    padding: '3px 10px',
    opacity: 1,
  };

  const buildTagBack = useCallback(() => (
        <Tag style={styleTag}
             onClick={onClickBackToGroupFolderCategory}
             color={'blue-inverse'}
        >{'< back'}</Tag>
  ), [filterCategory, counterCategory]);

  const buildTag = useCallback((category: string) => (
        <Tag icon={filterCategory.includes(category)
          ? <CheckBox style={{ fontSize: '12px', marginBottom: '-2px', marginRight: '5px' }}/>
          : <CheckBoxOutlineBlank style={{ fontSize: '12px', marginBottom: '-2px', marginRight: '5px' }}/>
        } key={category}
             style={{
               ...styleTag,
               opacity: !filterCategory.includes(category) ? 0.5 : 1,
             }}
             onClick={onClickCategory(category)}
             color={filterCategory.includes(category) ? 'processing' : 'default'}
        >
            {CATEGORY_SERVICES[category]}
            {counterCategory[category]
                && <span style={{ fontWeight: 'bold', marginLeft: 3 }}>{`(${counterCategory[category]})`}</span>}
        </Tag>
  ), [filterCategory, counterCategory]);

  const handleFilter = () => {
    if (!isHideFilter) {
      setModeGroupFolder(modeGroupFolder || 'folder');
    }
    setIsHideFilter(!isHideFilter);
  };

  const clearFilter = () => {
    dispatch(setterApp({ currentCategory: [], currentGroupCategory: '', filterCategory: [] }));
  };

  const isFolder = modeGroupFolder === 'folder';
  const isCategory = modeGroupFolder === 'category';

  return (
        <DivStyled>
            <Flex justify={'space-between'} align={'center'}>
                {
                    <div>
                        {
                            currentGroupCategory
                            && <div style={{ fontWeight: 'bold', marginLeft: 10 }}>
                                {currentGroupCategory}
                                <Button style={{ marginLeft: 10 }} onClick={clearFilter} size={'small'} shape="circle"
                                        icon={<CloseOutlined/>}/>
                            </div>
                        }
                    </div>
                }
                <Button onClick={handleFilter}>
                    <FilterOutlined/> {isHideFilter ? t('Filter') : t('Close Filter')}
                </Button>
            </Flex>
            {
                !isHideFilter && (isFolder || !currentGroupCategory) && <FlexStyled align={'center'}>
                    {
                        Object.keys(GROUP_CATEGORY_SERVICES).map((categoryKey) => {
                          const groupCategoryService = GROUP_CATEGORY_SERVICES[categoryKey];

                          return <FolderCategoryService
                                key={categoryKey}
                                groupCategoryService={groupCategoryService}
                            />;
                        })
                    }
                </FlexStyled>
            }
            {
                !isHideFilter && isCategory
                && <SubCategoryStyled style={{ display: currentCategory.length ? 'block' : 'none' }}>
                    {buildTagBack()}
                    {currentCategory.map((category) => buildTag(category))}
                </SubCategoryStyled>
            }
        </DivStyled>
  );
};
