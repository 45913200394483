import * as React from 'react';
import { useCallback, useState } from 'react';
import { Button, Flex } from 'antd';
import { GridView, Map, ViewList } from '@mui/icons-material';
import { useResizeWidthWindow } from '../../hooks/useResizeWidthWindow';
import { TypeViewType } from '../../../../common/types';

interface ITypeView {
  onSwitch: (type: TypeViewType) => void
}

export const TypeView: React.FC<ITypeView> = ({ onSwitch }) => {
  const [active, setActive] = useState<TypeViewType>('grid');

  const onClickSwitch = useCallback((type: TypeViewType) => () => {
    onSwitch(type);
    setActive(type);
  }, [active]);

  return (
        <>
          <Flex gap={10}>
            <Button className={'grid_view'}
                    type={active === 'grid' ? 'primary' : 'default'}
                    ghost={active === 'grid'}
                    icon={<GridView/>} size={'large'}
                    onClick={onClickSwitch('grid')}>
            </Button>
            {
                useResizeWidthWindow() > 800
                && <Button className={'list_view'}
                            type={active === 'list' ? 'primary' : 'default'}
                            ghost={active === 'list'}
                            icon={<ViewList/>}
                            color={active === 'list' ? 'green' : 'grey'}
                            size={'large'} onClick={onClickSwitch('list')}>
                    </Button>
            }
                    <Button className={'map_view'}
                          type={active === 'map' ? 'primary' : 'default'}
                          ghost={active === 'map'}
                          icon={<Map />}
                          color={active === 'map' ? 'green' : 'grey'}
                          size={'large'} onClick={onClickSwitch('map')}>
                    </Button>
                </Flex>
        </>
  );
};
