import jwt from 'jsonwebtoken';
import { DateTime } from 'luxon';
import axios from 'axios';
import Cookies from 'js-cookie';
import { InitiateAuthCommandOutput } from '@aws-sdk/client-cognito-identity-provider';
import { UserTokenType } from '../../../common/types';
import { API } from './constsAPI';
import {
  COOKIE_ACCESS_TOKEN,
  COOKIE_ID_TOKEN,
  COOKIE_REFRESH_TOKEN,
  COOKIE_START_REFRESH_TOKEN,
  paths,
  REST_TIME_LIFE_OF_TOKEN, TRUE_STRING,
} from './consts';

const setToCookie = (name: string, data: string | undefined) => {
  Cookies.set(name, data || '');
};

const removeFromCookie = (keys: string[]) => {
  keys.forEach((key) => {
    Cookies.remove(key);
  });
};

export const clearTokens = () => {
  removeFromCookie(
    [
      COOKIE_ID_TOKEN,
      COOKIE_REFRESH_TOKEN,
      COOKIE_ACCESS_TOKEN,
      COOKIE_START_REFRESH_TOKEN,
    ],
  );
  return false;
};

export const refreshToken = async (subRefreshToken: string) => {
  if (Cookies.get(COOKIE_START_REFRESH_TOKEN) === TRUE_STRING) {
    return true;
  }

  setToCookie(COOKIE_START_REFRESH_TOKEN, TRUE_STRING);

  const payload = { refreshToken: subRefreshToken };
  try {
    const result = (await axios.post<InitiateAuthCommandOutput>(`${API.URL_LOGIN}${paths.REFRESH_TOKEN}`, JSON.stringify(payload))).data;

    if (result.AuthenticationResult) {
      setToCookie(COOKIE_ID_TOKEN, result.AuthenticationResult.IdToken);
      setToCookie(COOKIE_ACCESS_TOKEN, result.AuthenticationResult.AccessToken);
      setToCookie(COOKIE_START_REFRESH_TOKEN, '');

      return true;
    }
  } catch (e) {
    console.log(e);
  }
  return clearTokens();
};

export const decodeToken = (token: string) => jwt.decode(token) as UserTokenType;

export const isTokenValid = (): boolean => {
  let token: string = '';

  if (typeof window !== 'undefined') {
    token = Cookies.get(COOKIE_ID_TOKEN) || '';
  } else {
    return clearTokens();
  }

  const decodedToken = decodeToken(token);

  if (!decodedToken) {
    return clearTokens();
  }

  if (decodedToken.exp - DateTime.now().toSeconds() < REST_TIME_LIFE_OF_TOKEN) {
    const subRefreshToken = Cookies.get(COOKIE_REFRESH_TOKEN);
    if (!subRefreshToken) {
      return clearTokens();
    }

    refreshToken(subRefreshToken);
  }

  return true;
};
