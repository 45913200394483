import * as React from 'react';
import { DivStyled, FlexStyled } from './ImageStyled';
import { BaseImage } from './BaseImage';

type LogoServiceProps = {
  size?: number,
  imgSrc?: string;
};

export const LogoService: React.FC<LogoServiceProps> = ({ imgSrc }) => (
        <FlexStyled>
            <DivStyled>
                <BaseImage preview={false} src={imgSrc} />
            </DivStyled>
        </FlexStyled>
);
