import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import { Flex, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { COOKIE_ID_TOKEN, decodeToken, ROLE_ADMIN } from '../common';
import { CardPost } from '../components/Cards/CardPost';
import { ButtonOpenModal } from '../components/Modal/ButtonOpenModal';
import { AppDispatch, RootState } from '../store';
import { fetchListPost } from '../store/slices/postsSlice';

export const DivStyled = styled.div`
  width: 900px;
  margin: 0 auto;
  position: relative;
  padding-top: 40px;
  padding-bottom: 20px;
  min-height: 500px;

  @media (max-width: 768px) {
    padding-top: 20px;
    width: 90%;
  }
`;

export const CommunityPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const listPost = useSelector((state: RootState) => state.post.listPost);
  const postLoader = useSelector((state: RootState) => state.post.postLoader);

  const token = Cookies.get(COOKIE_ID_TOKEN) || '';
  const decodedToken = decodeToken(token);

  useEffect(() => {
    (async () => {
      await dispatch(fetchListPost());
    })();
  }, []);

  return (
        <div style={{ maxWidth: '1200px', margin: '0 auto', position: 'relative' }}>
            <Helmet>
                <title>Community | ServiceIn.ca</title>
                <meta name="description" content="News and events on ServiceIn"/>
                <link rel="canonical" href='https://servicein.ca/blog'/>
            </Helmet>
            <DivStyled>
                <Spin spinning={postLoader} tip={t('Please wait')} style={{ width: '100%' }}>
                    <Flex justify={'flex-end'} style={{ marginBottom: '20px' }}>
                        {
                            decodedToken && decodedToken['custom:Role'] === ROLE_ADMIN && <ButtonOpenModal mode={'button'} title={t('Add new post')} type={'post'} />
                        }
                    </Flex>
                    <Flex vertical gap={20}>
                        {listPost && listPost.map((post) => <CardPost post={post} key={post.id} decodedToken={decodedToken} />)}
                    </Flex>
                </Spin>
            </DivStyled>
    </div>
  );
};
