import * as React from 'react';
import { Avatar, Flex } from 'antd';
import styled from 'styled-components';
import { Diversity2 } from '@mui/icons-material';
import { colors } from '../../common/constStyles';

interface IAvatarService {
  size?: number,
  imgSrc?: string
}

const AvatarStyled = styled(Avatar)`
  border: 1px solid ${colors.colorBorder};
  box-shadow: 0 12px 14px -7px rgba(0, 0, 0, .15);

  .MuiSvgIcon-root.MuiSvgIcon-colorInfo.MuiSvgIcon-fontSizeMedium{
    width: 56px;
    height: 56px;
  }
`;

export const AvatarServiceCard: React.FC<IAvatarService> = ({ size, imgSrc }) => (
    <Flex>
        <AvatarStyled
            src={imgSrc} icon={<Diversity2 color={'info'}/>} size={size}
            style={{ backgroundColor: !imgSrc ? 'white' : 'transparent' }}/>
    </Flex>
);
