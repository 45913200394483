import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ContainerServices } from './ContainerServices';

export const SearchPage: React.FC = () => <>
    <Helmet>
        <title>Search | ServiceIn.ca</title>
        <meta name="description"
              content="Find the best professional and local businesses. Use ServiceIn for Search by name, produce, tag or location"/>
        <link rel="canonical" href="https://servicein.ca/search"/>
    </Helmet>
    <>
        <ContainerServices/>
    </>
</>;
