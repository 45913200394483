import * as React from 'react';
import {
  Flex, Form, Input, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Groups3 } from '@mui/icons-material';
import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  TikTokFilled,
  TwitterSquareFilled,
  YoutubeFilled,
} from '@ant-design/icons';
import { SocialLinks } from '../../../../common/types';

const { Title } = Typography;

export const FormSocial: React.FC = () => {
  const { t } = useTranslation();

  return <>
      <Flex gap={10} align={'center'} style={{ marginBottom: 20 }}>
          <Groups3 />
          <Title level={4} style={{ margin: 0, textTransform: 'uppercase' }}>{t('Social')}</Title>
      </Flex>
      <Form.Item<SocialLinks>
          label='Instagram'
          name="linkInstagram"
          rules={[
            {
              pattern: /^(https?:\/\/)(www\.)?instagram\.com\/[a-zA-Z0-9_.]+\/?$/,
              message: t('Link is not correct'),
            },
          ]}
      >
          <Input placeholder='' allowClear addonBefore={<InstagramFilled />}/>
      </Form.Item>
      <Form.Item<SocialLinks>
          label='Facebook'
          name="linkFacebook"
          rules={[
            {
              pattern: /^(https?:\/\/)(www\.)?facebook\.com\/[a-zA-Z0-9_.]+\/?$/,
              message: t('Link is not correct'),
            },
          ]}
      >
          <Input placeholder='' allowClear addonBefore={<FacebookFilled />}/>
      </Form.Item>
      <Form.Item<SocialLinks>
          label='Twitter'
          name="linkTwitter"
          rules={[
            {
              pattern: /^(https?:\/\/)(www\.)?twitter\.com\/[a-zA-Z0-9_.]+\/?$/,
              message: t('Link is not correct'),
            },
          ]}
      >
          <Input placeholder='' allowClear addonBefore={<TwitterSquareFilled />}/>
      </Form.Item>
      <Form.Item<SocialLinks>
          label='LinkedIn'
          name="linkLinkedIn"
          rules={[
            {
              pattern: /^(https?:\/\/)(www\.)?linkedin\.com\/(in|company|companies)\/[a-zA-Z0-9_-]+(?:\/)?$/,
              message: t('Link is not correct'),
            },
          ]}
      >
          <Input placeholder='' allowClear addonBefore={<LinkedinFilled />}/>
      </Form.Item>
      <Form.Item<SocialLinks>
          label='Tiktok'
          name="linkTiktok"
          rules={[
            {
              pattern: /^(https?:\/\/)(www\.)?tiktok\.com\/[@a-zA-Z0-9_.]+\/?$/,
              message: t('Link is not correct'),
            },
          ]}
      >
          <Input placeholder='' allowClear addonBefore={<TikTokFilled />}/>
      </Form.Item>
      <Form.Item<SocialLinks>
          label='YouTube'
          name="linkYouTube"
          rules={[
            {
              pattern: /^(https?:\/\/)(www\.)?youtube\.com\/(c|channel|user)?\/?[a-zA-Z0-9_-]+(\?:\/)?$/,
              message: t('Link is not correct'),
            },
          ]}
      >
          <Input placeholder='' allowClear addonBefore={<YoutubeFilled />}/>
      </Form.Item>
    </>;
};
