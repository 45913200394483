import * as React from 'react';
import { Flex, Image } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ButtonRegistration } from './Buttons/ButtonRegistration';

const FlexStyled = styled(Flex)`
  && p{
    margin: 0px 0px 32px;
    font-weight: 600;
    line-height: 1.6rem;
  }
  
  && .not_register_main{
    font-size: 1.5rem;
  }
  
  && .not_register_second{
    font-size: 1rem;
  }
`;

export const MessageNoFullAccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FlexStyled vertical={true} align={'center'}>
        <Image width={'50%'} src="/Error.svg" preview={false}/>
        <h1>{t('Opps!!!')}</h1>
        <p className={'not_register_main'}>{t('It looks like you are not authorized')}</p>
        <p className={'not_register_second'}>{t('Please authorize or register to be able to contact the service')}</p>
        <ButtonRegistration withIcon={true}/>
    </FlexStyled>
  );
};
