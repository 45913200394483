import React, { useEffect } from 'react';

interface IScrollUp {
  data?: any
}

export const ScrollUp: React.FC<IScrollUp> = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  return null;
};
