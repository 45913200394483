import * as React from 'react';
import { Col, Flex, Row } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComments, faHandshakeSimple, faIdCardClip, faStar,
} from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { colors } from '../common/constStyles';

const FlexStyled = styled(Flex)`
  padding: 40px 40px 0 40px;
  margin: 0 auto;

  && .benefit_caption {
    font-size: 3rem;
    font-weight: bold;
    padding: 40px 0;
    text-align: center;
  }
  
  && .benefit_item_row{
  padding: 20px 0;  
  }
  
  && .caption{
    font-weight: 700;
    font-size: 1rem ;
  }
  
  && .description{
    text-align: justify;
  }
  .icon_container {
    align-items: center;
    display: flex;
    height: 60px;
    width: 60px;
    justify-content: center;
    transition: all .5s ease;
    border-radius: 6px !important;
    background-color: ${colors.colorSecond};
  }
  
  @media (max-width: 768px) {
    padding: 20px 20px 0 20px;

    && .adv_item_row{
      padding: 0;
    }

    && .benefit_caption{
      padding: 20px 0;
      font-size: 2rem;
    }

    && .benefit_item_row{
      padding: 0;
    }
  }
`;

const ColStyled = styled(Col)`
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const Benefits: React.FC = () => {
  const { t } = useTranslation();

  return (
      <FlexStyled vertical>
            <Flex justify={'center'} className={'benefit_caption'}>
                <motion.h1 className={'text_main regular'}
                          initial={{ opacity: 0, margin: 0 }}
                          whileInView={{
                            opacity: 1,
                            transition: { duration: 1 },
                          }}
                >
                    {t('Benefits of our platform')}
                </motion.h1>
            </Flex>
            <Row className={'benefit_item_row'}>
                <ColStyled xs={24} sm={24} md={12} lg={12} xl={12}>
                    <motion.div
                        initial={{ opacity: 0, x: -40 }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          transition: { duration: 1 },
                        }}
                    >
                        <Flex gap={20} align={'center'}>
                            <Flex align={'center'}>
                                <div className={'icon_container'}>
                                    <FontAwesomeIcon icon={faHandshakeSimple} color={colors.colorMain} size="lg"/>
                                </div>
                            </Flex>
                            <Flex vertical gap={5}>
                                <p className={'caption'}>{t('Comprehensive Directory')}</p>
                                <p className={'description'}>{t('Explore a diverse directory of professionals from legal services to creative professionals')}</p>
                            </Flex>
                        </Flex>
                    </motion.div>
                </ColStyled>
                <ColStyled xs={24} sm={24} md={12} lg={12} xl={12}>
                    <motion.div
                        initial={{ opacity: 0, x: 40 }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          transition: { duration: 1 },
                        }}
                    >
                    <Flex gap={20} align={'center'}>
                        <Flex align={'center'}>
                            <div className={'icon_container'}>
                                <FontAwesomeIcon icon={faIdCardClip} color={colors.colorMain} size="lg"/>
                            </div>
                        </Flex>
                        <Flex vertical gap={5}>
                            <p className={'caption'}>{t('Professional Profiles')}</p>
                            <p className={'description'}>{t('Get to know specialists through detailed profiles, including their skills, experience, and portfolio')}</p>
                        </Flex>
                    </Flex>
                    </motion.div>
                </ColStyled>
            </Row>
          <Row className={'benefit_item_row'}>
              <ColStyled xs={24} sm={24} md={12} lg={12} xl={12}>
                  <motion.div
                      initial={{ opacity: 0, x: -40 }}
                      whileInView={{
                        opacity: 1,
                        x: 0,
                        transition: { duration: 1 },
                      }}
                  >
                  <Flex gap={20} align={'center'}>
                      <Flex align={'center'}>
                          <div className={'icon_container'}>
                              <FontAwesomeIcon icon={faComments} color={colors.colorMain} size="lg"/>
                          </div>
                      </Flex>
                      <Flex vertical gap={5}>
                          <p className={'caption'}>{t('Direct Communication')}</p>
                          <p className={'description'}>{t('Connect with clients and experts directly through our platform - manage requests and discuss details')}</p>
                      </Flex>
                  </Flex>
                  </motion.div>
              </ColStyled>
              <ColStyled xs={24} sm={24} md={12} lg={12} xl={12}>
                  <motion.div
                      initial={{ opacity: 0, x: 40 }}
                      whileInView={{
                        opacity: 1,
                        x: 0,
                        transition: { duration: 1 },
                      }}
                  >
                  <Flex gap={20} align={'center'}>
                      <Flex align={'center'}>
                          <div className={'icon_container'}>
                              <FontAwesomeIcon icon={faStar} color={colors.colorMain} size="lg"/>
                          </div>
                      </Flex>
                      <Flex vertical gap={5}>
                          <p className={'caption'}>{t('Verified Reviews')}</p>
                          <p className={'description'}>{t('Make sure you choose a professional with a proven track record')}</p>
                      </Flex>
                  </Flex>
                  </motion.div>
              </ColStyled>
          </Row>
      </FlexStyled>
  );
};
