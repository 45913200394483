import * as React from 'react';
import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import {
  Button, Divider, Flex, Form, Input, Modal, notification, Spin, Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { MaskedInput } from 'antd-mask-input';
import { Mail, Phone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Cookies from 'js-cookie';
import { NotificationFilled } from '@ant-design/icons';
import { OfficeType, RequestToServiceType, ServiceType } from '../../../../common/types';
import { selectLogin } from '../../store';
import { useAlertMessage } from '../../hooks/useAlertMessage';
import { MessageNoFullAccess } from '../MessageNoFullAccess';
import {
  API, COOKIE_ID_TOKEN, decodeToken, paths,
} from '../../common';

const { Title } = Typography;

interface IContactForm {
  title?: string,
  service: ServiceType,
  refButton?: React.MutableRefObject<any>,
  nameForm: string,
  handleForm?: Dispatch<SetStateAction<any>>
}

const Context = React.createContext({ name: 'Default' });

export const ContactForm: React.FC<IContactForm> = ({
  title, service, refButton, nameForm, handleForm,
}) => {
  const { t } = useTranslation();
  const [spin, setSpin] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [form] = Form.useForm();
  const login = useSelector(selectLogin);
  const [open, setOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const token = Cookies.get(COOKIE_ID_TOKEN) || '';
  const decodedToken = decodeToken(token);

  const initialValues: Partial<RequestToServiceType> = {
    firstName: '', text: '', subject: '', companyName: '', phone: '', email: '',
  };

  const openNotification = useCallback((text: string) => {
    api.info({
      message: 'Request process',
      description: <Context.Consumer>{() => `${text}`}</Context.Consumer>,
      icon: <NotificationFilled style={{ color: '#108ee9' }}/>,
    });
  }, []);

  const onFinish = async (values: RequestToServiceType) => {
    if (!login) {
      setOpen(true);
    }
    setSpin(true);
    try {
      const result = (await axios.post(`${API.URL_REST_API}${paths.MESSENGER}/${decodedToken.sub}/${service.userId}/${service.id}`, values)).data;
      setSpin(false);

      if (handleForm) {
        handleForm(true);
      }
      form.resetFields();
      openNotification(result.message);
    } catch (e: any) {
      console.log(e);
      setSpin(false);
      setMsgError(JSON.stringify(e.response.data.message));
    }
  };

  const handleClose = () => setOpen(false);

  const renderMessageError = useAlertMessage('error', msgError);

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
      <>
          {service.isShowRequestButton
              && <div>
                  {contextHolder}
              <Spin spinning={spin} tip={'Please wait ...'}>
                <Form
                  form={form}
                  name={nameForm}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  style={{ width: '100%' }}
                  initialValues={{ initialValues }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  labelAlign={'left'}
                  size={'large'}
                >
                    {title && <Title level={3} style={{ margin: 0 }}>{title}</Title>}
                  <Divider/>
                  <Form.Item<RequestToServiceType>
                    label="Your Name"
                    name="firstName"
                    rules={[{ required: true, message: 'Please, fill this field!' }]}
                  >
                    <Input/>
                  </Form.Item>
                  <Form.Item<RequestToServiceType>
                    label="Company Name"
                    name="companyName"
                  >
                    <Input/>
                  </Form.Item>
                  <Form.Item<RequestToServiceType>
                    label="Phone Number"
                    name="phone"
                    key={uuidv4()}
                    rules={[
                      { required: true, message: 'Please input data!' },
                    ]}
                  >
                    <MaskedInput
                      addonBefore={<Phone color={'primary'}/>}
                      size={'large'}
                      mask={
                          '+1(000)000-0000'
                      }
                      type={'tel'}
                    />
                  </Form.Item>

                  <Form.Item<OfficeType>
                    label="E-mail"
                    name="email"
                    rules={[
                      { required: true, message: 'Please input data!' },
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                    ]}
                  >
                    <Input type={'email'} addonBefore={<Mail color={'primary'}/>} maxLength={20}/>
                  </Form.Item>

                  <Form.Item<RequestToServiceType>
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: 'Please, fill this field!' }]}
                  >
                    <Input/>
                  </Form.Item> <Form.Item<RequestToServiceType>
                  label="Your Inquiry"
                  name="text"
                  rules={[{ required: true, message: 'Please, fill this field!' }]}
                >
                  <TextArea rows={3} showCount maxLength={170}/>
                </Form.Item>
                    {[
                      renderMessageError,
                    ]}
                  <Flex justify={'flex-end'}>
                    <Button ref={refButton} type="primary" htmlType="submit" size={'large'}
                            style={{ opacity: refButton ? 0 : 1 }} aria-label={'Send'}>
                        {t('Send')}
                    </Button>
                  </Flex>
                  <Modal
                    open={open}
                    onCancel={handleClose}
                    footer=""
                  >
                    <MessageNoFullAccess/>
                  </Modal>
                </Form>
              </Spin>
            </div>
          }
      </>
  );
};
