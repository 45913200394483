import * as React from 'react';
import { useEffect } from 'react';
import {
  Col, Empty, Flex, Spin,
} from 'antd';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { ServiceType, TypeViewType } from '../../../common/types';
import { MiniCardBusiness } from './Cards/MiniCardBusiness';
import { fetchListServiceGlobal } from '../store/slices/servicesSlice';
import { MapPublicBlock } from './MapPublicBlock';

interface IContainerMiniCardBusiness{
  typeView: TypeViewType
}

export const ContainerMiniCardBusiness: React.FC<IContainerMiniCardBusiness> = ({ typeView }) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentCategory = useSelector<RootState, string[]>((state) => state.app.currentCategory!);
  const filterCategory = useSelector<RootState, string[]>((state) => state.app.filterCategory!);
  const listService = useSelector<RootState, ServiceType[]>((state) => state.services.serviceGlobalList);
  const serviceLoader = useSelector<RootState, boolean>((state) => state.services.serviceLoader);
  const searchParam = useSelector<RootState, string>((state) => state.app.searchParam!);

  useEffect(() => {
    if (!currentCategory.length) {
      dispatch(fetchListServiceGlobal(searchParam));
    } else {
      dispatch(fetchListServiceGlobal(`category=${[...filterCategory, '000'].join(',')}&${searchParam}`));
    }
  }, [filterCategory, searchParam]);

  const newListServices = listService;
  // .filter((serv) => !filterCategory.length || filterCategory.includes(serv.profile.category));

  const listOffices = listService.length
    ? listService.map((item) => item.office).reduce((a, b) => a.concat(b))
    : [];

  return <>
      <Spin spinning={serviceLoader} tip={'please wait ...'}>
        <Box>
            <Col span={24}>
                 <Flex justify={'center'}>
                    {
                        !newListServices.length && typeView !== 'map' && <Empty description={'Sorry :( but we cannot find any services for your request.'}/>
                    }
                 </Flex>
                <Flex gap={20} wrap={'wrap'} justify={'center'} style={{ paddingBottom: 10 }}>
                    {
                        typeView !== 'map'
                          ? newListServices.map((service) => <MiniCardBusiness key={service.id} service={service} typeView={typeView}/>)
                          : <MapPublicBlock offices={listOffices}/>
                    }
                </Flex>
            </Col>
        </Box>
      </Spin>
    </>;
};
