import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { PostType } from '../../../../common/types';
import { API, paths } from '../../common';

export const initialPost: PostType = {
  id: '',
  userId: '',
  dateByUpdate: 0,
  dateByCreate: 0,
  dateByDelete: 0,
  text: '',
  image: '',
  userName: '',
  userPicture: '',
  caption: '',
};

export interface IPostState {
  currentPost: PostType,
  postPublic: PostType,
  listPost?: PostType[],
  postLoader: boolean,
  showModal: boolean,
  postNotFound: boolean,
}

const initialState: IPostState = {
  currentPost: initialPost,
  postPublic: initialPost,
  listPost: [],
  postLoader: false,
  showModal: false,
  postNotFound: false,
};

export const fetchListPost = createAsyncThunk('listPost/fetch', async () => {
  const response = await axios.get(`${API.URL_REST_API}${paths.LIST_POST}`);
  return response.data as any;
});

export const fetchPublicPost = createAsyncThunk('public_post/fetch', async (param: { idAuthor: string, id: string }) => {
  try {
    const response = await axios.get(`${API.URL_REST_API}${paths.PUBLIC_POST}/${param.idAuthor}/${param.id}`);
    return response.data as any;
  } catch (e) {
    return initialPost;
  }
});

export const postsSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setterPost: (state, action: PayloadAction<Partial<IPostState>>) => ({ ...state, ...action.payload }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListPost.pending, (state) => {
      state.postLoader = true;
    });

    builder.addCase(fetchListPost.fulfilled, (state, action: PayloadAction<PostType[]>) => {
      state.listPost = action.payload;
      state.postLoader = false;
    });

    builder.addCase(fetchPublicPost.pending, (state) => {
      state.postLoader = true;
    });

    builder.addCase(fetchPublicPost.fulfilled, (state, action: PayloadAction<PostType>) => {
      if (!action.payload.id) {
        state.postNotFound = true;
      } else {
        state.postNotFound = false;
      }
      state.postPublic = action.payload;
      state.postLoader = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setterPost,
} = postsSlice.actions;

export default postsSlice.reducer;
