import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataUserType } from '../../../../common/types';

const initialState: DataUserType = {
  email: '',
  picture: '',
  permissions: [],
  family_name: '',
  birthdate: '',
  name: '',
  role: '',
  flagUpdateIcon: false,
};

export const userSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setCurrentUserChanges: (state, action: PayloadAction<DataUserType>) => {
      state.picture = action.payload.picture;
    },
    setFlagUpdateIcon: (state) => {
      state.flagUpdateIcon = !state.flagUpdateIcon;
    },
  },
  extraReducers: () => {},
});

// Action creators are generated for each case reducer function
export const {
  setCurrentUserChanges,
  setFlagUpdateIcon,
} = userSlice.actions;

export default userSlice.reducer;
