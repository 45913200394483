import * as React from 'react';
import styled from 'styled-components';
import { Flex } from 'antd';
import { colors } from '../common/constStyles';
import { CardViewer } from './Cards/CardViewer';
import { SearchText } from './SearchText';
import { MainTextBlockStyle } from './MainPage';
import { GroupFolderCategoryService } from './GroupFolderCategoryService';

const FlexStyled = styled(Flex)`
  padding: 40px 40px 0 40px;
  //text-align: center;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 10px 20px 0 20px;
  }
`;

export const ContainerServices: React.FC = () => (
    <>
        <div style={{ backgroundColor: colors.colorSecond }}>
            <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                <MainTextBlockStyle vertical justify={'center'}>
                    <SearchText/>
                    <GroupFolderCategoryService/>
                </MainTextBlockStyle>
            </div>
        </div>
        <div>
            <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                <FlexStyled vertical>
                    <CardViewer/>
                </FlexStyled>
            </div>
        </div>
    </>
);
