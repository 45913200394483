import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { QuestionType } from '../../../../common/types';
import { API, paths } from '../../common';

export const initialQuestion: QuestionType = {
  id: '',
  userId: '',
  dateByUpdate: 0,
  dateByCreate: 0,
  dateByDelete: 0,
  userName: '',
  userPicture: '',
  answer: '',
  question: '',
  resources: [],
};

export interface IQuestionState {
  currentQuestion: QuestionType,
  questionPublic: QuestionType,
  listQuestion?: QuestionType[],
  questionLoader: boolean,
  showModal: boolean,
  postNotFound: boolean,
}

const initialState: IQuestionState = {
  currentQuestion: initialQuestion,
  questionPublic: initialQuestion,
  listQuestion: [],
  questionLoader: false,
  showModal: false,
  postNotFound: false,
};

export const fetchListQuestion = createAsyncThunk('listQuestion/fetch', async () => {
  const response = await axios.get(`${API.URL_REST_API}${paths.LIST_QUESTIONS}`);
  return response.data as any;
});

export const fetchPublicQuestion = createAsyncThunk('public_question/fetch', async (param: { idAuthor: string, id: string }) => {
  try {
    const response = await axios.get(`${API.URL_REST_API}${paths.PUBLIC_QUESTION}/${param.idAuthor}/${param.id}`);
    return response.data as any;
  } catch (e) {
    return initialQuestion;
  }
});

export const questionsSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    setterQuestion: (state, action: PayloadAction<Partial<IQuestionState>>) => ({ ...state, ...action.payload }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListQuestion.pending, (state) => {
      state.questionLoader = true;
    });

    builder.addCase(fetchListQuestion.fulfilled, (state, action: PayloadAction<QuestionType[]>) => {
      state.listQuestion = action.payload;
      state.questionLoader = false;
    });

    builder.addCase(fetchPublicQuestion.pending, (state) => {
      state.questionLoader = true;
    });

    builder.addCase(fetchPublicQuestion.fulfilled, (state, action: PayloadAction<QuestionType>) => {
      if (!action.payload.id) {
        state.postNotFound = true;
      } else {
        state.postNotFound = false;
      }
      state.questionPublic = action.payload;
      state.questionLoader = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setterQuestion,
} = questionsSlice.actions;

export default questionsSlice.reducer;
