import * as React from 'react';
import { Rate } from 'antd';

interface IRate {
  rate: number
}

export const RateComponent: React.FC<IRate> = ({ rate }) => (
      <span>
      <Rate value={rate} disabled />
    </span>
);
