import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LANG_EN } from '../../common';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    lang: LANG_EN,
    login: '',
  },
  reducers: {
    setLanguage: (state, action) => ({ ...state, lang: action.payload }),
    setLogin: (state, action: PayloadAction<string>) => {
      state.login = action.payload;
    },
  },
});

export const {
  setLanguage,
  setLogin,
} = settingsSlice.actions;

export default settingsSlice.reducer;
