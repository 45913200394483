import * as React from 'react';
import { Badge } from 'antd';
import { IMiniCardBusiness } from './MiniCardStyledGrid';
import { MiniCardStyledListContent } from './MiniCardStyledListContent';

export const MiniCardStyledList: React.FC<IMiniCardBusiness> = ({
  service, firstOffice, imageSrcAvatar,
}) => (
      <div style={{
        border: 'none', minWidth: 350, width: '100%', position: 'relative', cursor: 'pointer',
      }}>
      {
          service.isResource
            ? <Badge.Ribbon text="Resource" placement={'start'}>
                  <MiniCardStyledListContent service={service} firstOffice={firstOffice} imageSrcAvatar={imageSrcAvatar} />
              </Badge.Ribbon>
            : <MiniCardStyledListContent service={service} firstOffice={firstOffice} imageSrcAvatar={imageSrcAvatar} />
      }
      </div>
);
