import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Button, Flex, Form, Input,
} from 'antd';
import { Mail, Phone } from '@mui/icons-material';
import { Card, CardContent } from '@mui/material';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { MaskedInput } from 'antd-mask-input';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { GooglePlace, OfficeType, ServiceType } from '../../../../common/types';
import { RootState } from '../../store';
import { ButtonConfirmationDelete } from '../Buttons/ButtonConfirmationDelete';
import { useSaveData } from '../../hooks/useSaveData';
import { PlacesAutocomplete } from './PlacesAutocomplete';

interface IFormOffice {
  office: OfficeType
}

export const initialValuesFofFormOffice = {
  address: '',
  telephone: '',
  email: '',
  postalCode: '',
  cityState: '',
  street: '',
} as (OfficeType);

const CardStyled = styled(Card)`
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: none;
  position: relative;
  width: 300px;
`;

export const FormOffice: React.FC<IFormOffice> = ({ office }) => {
  const {
    RenderMessageError, saveData, SpinnerSaveData, contextHolder,
  } = useSaveData();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const service = useSelector<RootState, ServiceType>((state) => state.services.service);
  // const serviceTempAddress = useSelector<RootState, GooglePlace | undefined>((state) => state.services.serviceTempAddress);
  const [serviceTempAddress, setServiceTempAddress] = useState<GooglePlace>();

  useEffect(() => {
    form.setFieldsValue({
      ...office,
    } as OfficeType);
  }, [office]);

  useEffect(() => {
    if (!serviceTempAddress) {
      return;
    }

    form.setFieldsValue({
      street: `${serviceTempAddress?.address.streetNumber} ${serviceTempAddress?.address.route}`,
      cityState: `${serviceTempAddress?.address.locality}, ${serviceTempAddress?.address.administrativeAreaLevel1}`,
      postalCode: serviceTempAddress?.address.postalCode,
      lat: serviceTempAddress?.address.lat,
      lng: serviceTempAddress?.address.lng,
    } as OfficeType);
  }, [serviceTempAddress]);

  const preparedData = (values: any): ServiceType => {
    const oldOffices = service.office;
    const isEdit = oldOffices.filter((subOffice) => subOffice.id === office.id).length;

    let newOffices: OfficeType[] = [];

    if (isEdit) {
      newOffices = [...oldOffices].map((subOffice) => {
        if (subOffice.id === office.id) {
          return { ...office, ...values, telephone: values.telephone === '+1(___)___-____' ? '' : values.telephone };
        }
        return subOffice;
      });
    } else {
      newOffices = [...oldOffices, { ...office, ...values }];
    }

    return {
      ...service,
      office: newOffices,
    };
  };

  const preparedDeleteOffice = (): ServiceType => ({
    ...service,
    office: service.office.filter((subOffice) => subOffice.id !== office.id),
  });

  const onFinish = async (values: any) => {
    const newService = preparedData(values);
    await saveData(newService, 'office');
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const deleteOffice = async () => {
    const newService = preparedDeleteOffice();
    await saveData(newService, 'office');
  };

  return <Flex vertical={true} gap={30}>
      {contextHolder}
        <CardStyled variant="outlined">
            <CardContent>
                <SpinnerSaveData >
                    <Form
                        name={`OfficeService${office?.id}`}
                        style={{ width: '100%' }}
                        initialValues={{ initialValuesFofFormOffice }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        labelAlign={'left'}
                        size={'large'}
                        form={form}
                    >
                        <Form.Item<OfficeType>
                            name="address"
                        >
                            <PlacesAutocomplete
                                setServiceTempAddress={setServiceTempAddress}
                                serviceTempAddress={serviceTempAddress}
                            />
                        </Form.Item>
                        <Form.Item<OfficeType>
                            name="lat"
                            style={{ opacity: 0 }}
                        >
                            <Input placeholder={'lat'} disabled />
                        </Form.Item>
                        <Form.Item<OfficeType>
                            name="lng"
                            style={{ opacity: 0 }}
                        >
                            <Input placeholder={'lng'} disabled />
                        </Form.Item>
                        <Form.Item<OfficeType>
                            name="street"
                            style={{ marginTop: -128 }}
                            rules={[
                              { required: true, message: t('Please input data!') },
                            ]}
                            >
                            <Input placeholder={'Street'} disabled />
                        </Form.Item>
                        <Form.Item<OfficeType>
                            name="cityState"
                            rules={[
                              { required: true, message: t('Please input data!') },
                            ]}
                        >
                            <Input placeholder={'City and State'} disabled />
                        </Form.Item>
                        <Form.Item<OfficeType>
                            name="postalCode"
                            rules={[
                              { required: true, message: t('Please input data!') },
                            ]}
                        >
                            <Input placeholder={'Postal Code'} disabled />
                        </Form.Item>
                        <Form.Item<OfficeType>
                            name="telephone"
                            key={uuidv4()}
                        >
                            <MaskedInput
                                addonBefore={<Phone color={'primary'}/>}
                                size={'large'}
                                mask={
                                    '+1(000)000-0000'
                                }
                                type={'tel'}
                            />
                        </Form.Item>
                            <Form.Item<OfficeType>
                                name="email"
                                rules={[
                                  {
                                    type: 'email',
                                    message: t('The input is not valid E-mail!'),
                                  },
                                ]}
                            >
                                <Input type={'email'} addonBefore={<Mail color={'primary'}/>} maxLength={30}/>
                            </Form.Item>
                        <RenderMessageError />
                        <Flex justify={'flex-end'} gap={'small'} align={'center'}>
                            <ButtonConfirmationDelete
                                typeButton={'btn'}
                                callbackOk={deleteOffice}
                            />
                            <Button type="primary" htmlType="submit" size={'large'} aria-label={'Save'}>
                                {t('Save')}
                            </Button>
                        </Flex>
                    </Form>
                </SpinnerSaveData>
            </CardContent>
        </CardStyled>
    </Flex>;
};
