import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  Button, Col, Flex, Form, Input, InputNumber, Row,
} from 'antd';
import { useSelector } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import styled from 'styled-components';
import { Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PriceFormType, ServiceType } from '../../../../common/types';
import { LANG_EN } from '../../common';
import { RootState } from '../../store';
import { ButtonConfirmationDelete } from '../Buttons/ButtonConfirmationDelete';
import { useSaveData } from '../../hooks/useSaveData';

interface IFormPrice {
  pricesForm: PriceFormType,
}

export const initialValuesForFormPrice = {
  caption: '',
  caption_vn: '',
  caption_ua: '',
  description: '',
  description_vn: '',
  description_ua: '',
  price: 0,
} as (PriceFormType);

const CardStyled = styled(Card)`
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: none;
  position: relative;
  margin-bottom: 10px;
`;

const RowStyled = styled(Row)`
  & .price_page__caption {
    font-size: 1rem;
    text-align: center;
    font-weight: bold;
  }

  & .price_card__main {
    font-size: 0.9rem;
    font-weight: bolder;
  }

  & .price_card__second {
    font-size: 0.7rem;
    margin-top: 5px;
  }

  & .price_card__amount {
    font-size: 1rem;
    font-weight: bolder;
  }

  & .price_card {
    padding: 10px 0;
  }
`;

export const FormPrice: React.FC<IFormPrice> = ({ pricesForm }) => {
  const {
    RenderMessageError, saveData, SpinnerSaveData, contextHolder,
  } = useSaveData();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editable, setEditable] = useState(false);
  const currentLanguage = useSelector((state: RootState) => state.app.currentLanguageForFormService);

  // const insideService = useInsideService();
  const service = useSelector<RootState, ServiceType>((state) => state.services.service);

  useEffect(() => {
    if (editable) {
      form.setFieldsValue({
        ...pricesForm,
      } as PriceFormType);
    }
  }, [pricesForm, editable]);

  const preparedData = (values: any): ServiceType => {
    const oldPrices = service.pricesForm ? service.pricesForm : [];
    const isEdit = oldPrices.filter((subPrice) => subPrice.id === pricesForm.id).length;

    let newPrice: PriceFormType[] = [];

    if (isEdit) {
      newPrice = [...oldPrices].map((subPrice) => {
        if (subPrice.id === pricesForm.id) {
          return { ...pricesForm, ...values };
        }
        return subPrice;
      });
    } else {
      newPrice = [...oldPrices, { ...pricesForm, ...values }];
    }

    return {
      ...service,
      pricesForm: newPrice,
    };
  };

  const preparedDeletePrice = useCallback((): ServiceType => ({
    ...service,
    pricesForm: service.pricesForm.filter((subPrice) => subPrice.id !== pricesForm.id),
  }), [service]);

  const onFinish = useCallback(async (values: any) => {
    const newService = preparedData(values);
    await saveData(newService, 'price');
    setEditable(false);
  }, [service]);

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const deletePrice = useCallback(async () => {
    const newService = preparedDeletePrice();
    await saveData(newService, 'price');
  }, []);

  const languagePrefix = currentLanguage === LANG_EN ? '' : `_${currentLanguage}`;

  const getForm = () => <Form
          name={`PriceService${pricesForm?.id}`}
          style={{ width: '100%' }}
          initialValues={{ initialValuesForFormPrice }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size={'large'}
          form={form}
      >
          <Flex style={{ width: '100%' }} gap={5} justify={'space-between'}>
              <Form.Item<PriceFormType>
                  name={`caption${languagePrefix}` as any}
                  rules={[
                    { required: true, message: t('Please input data!') },
                  ]}
                  style={{ flex: '70% 1 3' }}
              >
                  <Input placeholder={t('Caption')}/>
              </Form.Item>
              <Form.Item<PriceFormType>
                  name="price"
                  rules={[
                    { required: true, message: t('Please input data!') },
                  ]}
                  style={{ flex: '30% 1 1' }}
              >
                  <InputNumber<string> placeholder={t('Price')} step={0.01} addonAfter="$" stringMode />
              </Form.Item>
          </Flex>
          <Form.Item<PriceFormType>
              name={`description${languagePrefix}` as any}
              rules={[
                { required: true, message: t('Please input data!') },
              ]}
          >
              <TextArea rows={2} placeholder={t('Description')} showCount
                        maxLength={400}/>
          </Form.Item>
          <RenderMessageError />
          <Flex justify={'flex-end'} gap={'small'} align={'center'}>
              <ButtonConfirmationDelete
                  typeButton={'btn'}
                  callbackOk={deletePrice}
              />
              <Button type="primary" htmlType="submit" size={'large'} aria-label={'Save'}>
                  {t('Save')}
              </Button>
          </Flex>
      </Form>;

  const getViewerData = () => (
      <RowStyled>
          <Col className="gutter-row" xs={18} sm={18} md={18} lg={18} xl={18}>
              <p className={'price_card__main'}>{(pricesForm as any)[`caption${languagePrefix}`]}</p>
              <p className={'price_card__second'}>{(pricesForm as any)[`description${languagePrefix}`]}</p>
          </Col>
          <Col className="gutter-row" xs={6} sm={6} md={6} lg={6} xl={6} style={{ textAlign: 'right' }}>
              <p className={'price_card__amount'}>CA${pricesForm.price}</p>
          </Col>
          <Flex style={{ marginTop: 5 }}>
              <Button type="primary" size={'small'} onClick={() => { setEditable(true); }} aria-label={'Edit'}>
                    {t('Edit')}
              </Button>
          </Flex>
      </RowStyled>
  );

  return (
      <div>
          <CardStyled variant="outlined">
              <CardContent>
                  {contextHolder}
                  <SpinnerSaveData>
                      {
                          editable && getForm()
                      }
                      {
                          !editable && getViewerData()
                      }
                  </SpinnerSaveData>
            </CardContent>
        </CardStyled>
      </div>
  );
};
