import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Flex } from 'antd';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { Mail, Phone } from '@mui/icons-material';
import { MessageType, RequestToServiceType } from '../../../../common/types';

export const FlexStyled = styled(Flex)`
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

interface IMessageCard{
  message: MessageType | RequestToServiceType
  isAuthor: boolean,
  authorName: string,
}

export const MessageCard: React.FC<IMessageCard> = ({ isAuthor, authorName, message }) => {
  const [isVisible, setIsVisible] = useState(false);
  const messageWrapperRef = useRef(null);

  useEffect(() => {
    if (isVisible && !message.isRead) {
      console.log('isVisible', isVisible);
    }
  }, [isVisible]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 1 },
    );

    if (messageWrapperRef.current) {
      observer.observe(messageWrapperRef.current);
    }

    return () => {
      if (messageWrapperRef.current) {
        observer.unobserve(messageWrapperRef.current);
      }
    };
  }, []);

  const getDataFromMessage = () => {
    try {
      return JSON.parse(message.text);
    } catch (e) {
      return { text: message.text };
    }
  };

  const time = DateTime.fromMillis(message.datetime).toFormat('MM.dd.yyyy hh:mm a');
  const isJson = getDataFromMessage().email && getDataFromMessage().phone && getDataFromMessage().subject && getDataFromMessage().text;

  return <Flex ref={messageWrapperRef} justify={isAuthor ? 'flex-end' : 'start'} style={{ marginBottom: '30px' }}>
      <div className={`message_from ${isAuthor}`}>
          <Flex gap={10} className={'message_header'} align={'center'}>
              <span>{isAuthor ? 'YOU' : authorName}</span>
              <span>{time}</span>
          </Flex>
          {isJson && <Flex align={'center'} gap={5} className={'message_header'}>
              <Mail sx={{ fontSize: '1em' }} /><span>{getDataFromMessage().email}</span> /
              <Phone sx={{ fontSize: '1em' }} /><span>{getDataFromMessage().phone}</span>
          </Flex>
          }
          <div className={'message_text'}>
               <p >{getDataFromMessage().text}</p>
              <p style={{ textAlign: 'right', fontSize: '0.8em' }}>{message.isRead ? 'read' : 'unread'}</p>
          </div>
      </div>
  </Flex>;
};
