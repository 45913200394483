import React from 'react';
import { Col, Row } from 'antd';
import { Card, CardContent, Typography } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ChangePasswordForm } from './Forms/ChangePasswordForm';
import { ChangeAvatar } from './ChangeAvatar';

const CardStyled = styled(Card)`
    box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: none;
    min-height: 300px;
    position: relative;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  `;

export const ProfileInfo: React.FC = () => {
  const { t } = useTranslation();

  return <Row gutter={20} style={{ margin: '1.5em 0' }}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <CardStyled variant="outlined">
          <CardContent>
            <Typography fontSize={'large'} gutterBottom={true} style={{ fontWeight: 'bold' }}>
              {t('Change Profile Picture')}
            </Typography>
            <ChangeAvatar />
          </CardContent>
        </CardStyled>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <CardStyled variant="outlined">
          <CardContent>
            <Typography fontSize={'large'} gutterBottom={true} style={{ fontWeight: 'bold' }}>
              {t('Change Password')}
            </Typography>
            <ChangePasswordForm/>
          </CardContent>
        </CardStyled>
      </Col>
    </Row>;
  // { /* <Row gutter={20} style={{ margin: '1.5em 0' }}> */ }
  // { /* <Col xs={24} sm={24} md={24} lg={24} xl={24}> */ }
  // { /*   <CardStyled variant="outlined"> */ }
  // { /*     <CardContent> */ }
  // { /*       <Typography fontSize={'large'} gutterBottom={true} style={{ fontWeight: 'bold' }}> */ }
  // { /*         Personal Details */ }
  // { /*       </Typography> */ }
  // { /*       <PersonalDetailForm /> */ }
  // { /*     </CardContent> */ }
  // { /*   </CardStyled> */ }
  // { /* </Col> */ }
  // { /* </Row> */ }
};
