import * as React from 'react';
import { useState } from 'react';
import { Flex } from 'antd';
import styled from 'styled-components';
import { ContainerMiniCardBusiness } from '../ContainerMiniCardBusiness';
import { TypeViewType } from '../../../../common/types';
import { TypeView } from './TypeView';

export const FlexStyled = styled(Flex)`
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const CardViewer: React.FC = () => {
  const [typeView, setTypeView] = useState<TypeViewType>('grid');

  const onSwitch = (type: TypeViewType) => {
    setTypeView(type);
  };

  return <>
      <FlexStyled justify={'end'}>
          <TypeView onSwitch={onSwitch} />
      </FlexStyled>
      <ContainerMiniCardBusiness typeView={typeView}/>
    </>;
};
