import * as React from 'react';
import { useCallback } from 'react';
import { FormInstance } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { ButtonConfirmationDelete } from '../Buttons/ButtonConfirmationDelete';
import { DivStyled, FlexStyled } from './ImageStyled';
import { BaseImage } from './BaseImage';

type ImageServiceProps = {
  size?: number,
  fileNameList: string[],
  form: FormInstance<any>,
  nameField: string,
  multiple: boolean,
};

export const DeleteDivStyled = styled.div`
  position: absolute;
  background: rgba(250, 252, 250, 0.7);
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 10px 14px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: none;
  right: 0;
`;

export const ImageGalleryService: React.FC<ImageServiceProps> = ({
  fileNameList, form, nameField, multiple,
}) => {
  const deleteImage = useCallback((img: string) => () => {
    const oldFileName = img.split('/').pop()!.slice(1);
    const newValue = form.getFieldValue(nameField)?.split('/').filter((fileName: string) => oldFileName !== fileName).join('/');
    form.setFieldsValue({
      [`${nameField}`]: newValue,
    });
  }, [form, form.getFieldValue(nameField)]);

  return (
        <>
            {
                fileNameList.map((img, index) => (
                    <FlexStyled key={index} >
                        <DivStyled>
                            <BaseImage preview={false} src={img}/>
                        </DivStyled>
                      <div style={{ position: 'relative' }}>
                        <DeleteDivStyled>
                        {
                            multiple && form.getFieldValue(nameField)
                            && <ButtonConfirmationDelete typeButton={'btnIcon'}
                                                         callbackOk={deleteImage(img)}
                                                         icon={<CloseCircleFilled/>}
                                                         size={'small'}
                            />
                        }
                        </DeleteDivStyled>
                      </div>
                    </FlexStyled>
                ))
            }
        </>
  );
};
