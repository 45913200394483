import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  Col, Form, notification, Row, Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { NotificationFilled } from '@ant-design/icons';
import Cookies from 'js-cookie';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import {
  API, COOKIE_ID_TOKEN, decodeToken, paths,
} from '../../common';
import { useAlertMessage } from '../../hooks/useAlertMessage';
import { PostType, ResponseResourcesType } from '../../../../common/types';
import { FileUpload } from '../FileUpload';
import { fetchListPost } from '../../store/slices/postsSlice';
import { AppDispatch, RootState } from '../../store';

interface IPostForm{
  onCloseParentModal: () => void;
}

export const PostForm: React.FC<IPostForm> = ({ onCloseParentModal }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [msgPost, setMsgPost] = useState('');
  const renderMessageErrorPost = useAlertMessage('error', msgPost);
  const [spinModalPost, setSpinModalPost] = useState(false);
  const currentPost = useSelector((state: RootState) => state.post.currentPost);

  const token = Cookies.get(COOKIE_ID_TOKEN) || '';
  const decodedToken = decodeToken(token);

  useEffect(() => {
    form.setFieldsValue({
      text: currentPost.text,
      image: currentPost.image,
      caption: currentPost.caption,
    });
  }, [currentPost]);

  const openNotification = (text: string) => {
    notification.open({
      message: t('Post creation process'),
      description: <div dangerouslySetInnerHTML={{ __html: `${text}` }} />,
      icon: <NotificationFilled style={{ color: '#108ee9' }}/>,
    });
  };

  const onFinish = async (values: PostType) => {
    setSpinModalPost(true);
    setMsgPost('');
    try {
      const newPost: Partial<PostType> = {
        ...currentPost,
        userId: decodedToken.sub,
        text: values.text.toLowerCase().trim(),
        image: values.image,
      };

      const result = (await axios.post<ResponseResourcesType>(`${API.URL_REST_API}${paths.POST}/${newPost.id}`, newPost)).data;

      openNotification(result.message);
      onCloseParentModal();
      await dispatch(fetchListPost());
    } catch (e: any) {
      setMsgPost(e.response.data.message);
    }
    setSpinModalPost(false);
  };

  const onFinishFailed = useCallback((errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }, []);

  return (
      <Spin spinning={spinModalPost} tip={t('Please wait')} style={{ width: '100%' }}>
          <Form
              form={form}
              name="addPost"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="nope"
              size={'large'}
          >
              <Row>
                  <Col span={24}>
                      <Row gutter={16}>
                          <Col span={24}>
                              <Form.Item<PostType>
                                  label={t('Text')}
                                  name="text"
                                  rules={[{ required: true, message: t('Please, fill this field!') }]}
                              >
                                  <TextArea rows={6} placeholder={t('Text')} showCount
                                            maxLength={1000} allowClear />
                              </Form.Item>

                              <Form.Item<PostType>
                                  label={t('Picture')}
                                  name="image"
                              >
                                  <FileUpload
                                      form={form}
                                      nameField={'image'}
                                      type={'gallery'}
                                      dirName={decodedToken.sub}/>
                              </Form.Item>

                              {[
                                renderMessageErrorPost,
                              ]}
                          </Col>
                      </Row>
                  </Col>
              </Row>
          </Form>
      </Spin>
  );
};
