import * as React from 'react';
import { Flex } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Business, Cottage, Devices, Diversity2, MoreHoriz, Villa,
} from '@mui/icons-material';
import {
  cyan, green, orange, purple, red,
} from '@ant-design/colors';
import { paths } from '../common';
import { CardService } from './Cards/CardService';
import { useCategory } from '../hooks/useGategory';
import { useResizeWidthWindow } from '../hooks/useResizeWidthWindow';
import { colors } from '../common/constStyles';

const FlexStyled = styled(Flex)`
  padding: 40px 40px 0 40px;
  text-align: center;
  
  @media (max-width: 768px) {
    padding: 20px 20px 0 20px;
  }
`;

const FlexCardStyled = styled(Flex)`
  gap: 20px;
  margin-bottom: 10px;
  
  @media (max-width: 768px) {
    gap: 0;
  }
`;

export const BrowseServices: React.FC = () => {
  const { t } = useTranslation();
  const { GROUP_CATEGORY_SERVICES } = useCategory();

  return (
      <FlexStyled vertical justify={'center'} gap={30}>
          <h1 className={'text_main regular'}>{t('Browse the categories')}</h1>
          <FlexCardStyled justify={'space-around'} align={'stretch'} wrap={useResizeWidthWindow() > 400 ? 'nowrap' : 'wrap'}>
              <CardService icon={<Villa />} color={cyan[2]} category={GROUP_CATEGORY_SERVICES.IS} />
              <CardService icon={<Cottage />} color={red[2]} category={GROUP_CATEGORY_SERVICES.HM}/>
              <CardService icon={<Devices />} color={green[2]} category={GROUP_CATEGORY_SERVICES.IT}/>
              <CardService icon={<Diversity2 />} color={purple[2]} category={GROUP_CATEGORY_SERVICES.LS}/>
              <CardService icon={<Business />} color={orange[2]} category={GROUP_CATEGORY_SERVICES.SB}/>
              <CardService icon={<MoreHoriz />} color={colors.colorTextDark} linkTo={paths.ALL_SERVICES}/>
          </FlexCardStyled>
      </FlexStyled>
  );
};
