import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { setterApp } from '../store/slices/appSlice';

export const GoTo: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useNavigate();

  const gotoPath = useSelector((state: RootState) => state.app.goto);

  useEffect(() => {
    if (gotoPath) {
      dispatch(setterApp({ goto: '' }));
      history(gotoPath);
    }
  }, [gotoPath]);

  return null;
};
