import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { ServiceType } from '../../../common/types';

export const useInsideService = () => {
  const service = useSelector<RootState, ServiceType>((state) => state.services.service);

  const [insideService, setInsideService] = useState<ServiceType>(service);

  useEffect(() => {
    if (insideService?.id !== service.id) {
      setInsideService(service);
    }
  }, [service]);

  return insideService;
};
