import * as React from 'react';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router';
import {
  API,
  COOKIE_REGISTRATION_CONFIRMED_EMAIL,
  COOKIE_REGISTRATION_EMAIL, COOKIE_REGISTRATION_FIRST_LOGIN, COOKIE_REGISTRATION_FIRST_SERVICE,
  COOKIE_REGISTRATION_ROLE, FALSE_STRING,
  paths, ROLE_USER, TRUE_STRING,
} from '../common';
import { RegistrationDataType } from '../../../common/types';

export const CheckStatusRegistration: React.FC = () => {
  const history = useNavigate();

  useEffect(() => {
    (async () => {
      const emailFromCookie = Cookies.get(COOKIE_REGISTRATION_EMAIL);
      if (emailFromCookie) {
        const result = (await axios.post<RegistrationDataType>(`${API.URL_LOGIN}${paths.STATUS_REGISTRATION}`, { email: emailFromCookie })).data;
        Cookies.set(COOKIE_REGISTRATION_ROLE, result.role);
        Cookies.set(COOKIE_REGISTRATION_CONFIRMED_EMAIL, result.confirm ? TRUE_STRING : FALSE_STRING);
        Cookies.set(COOKIE_REGISTRATION_FIRST_SERVICE, result.createFirstService ? TRUE_STRING : FALSE_STRING);
        Cookies.set(COOKIE_REGISTRATION_FIRST_LOGIN, result.firstLogin ? TRUE_STRING : FALSE_STRING);

        if (!result.firstLogin) {
          if (result.role === ROLE_USER) {
            history(paths.REGISTRATION_USER);
          } else {
            history(paths.REGISTRATION_PROVIDER);
          }
        }
      }
    })();
  }, []);

  return null;
};
