import * as React from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { RegistrationForm } from '../Forms/RegistrationForm';

interface IModalRegistration{
  isModalVisible: boolean
  handleCancel: () => void;
}

export const ModalRegistration: React.FC<IModalRegistration> = ({ isModalVisible, handleCancel }) => {
  const { t } = useTranslation();
  return (
      <Modal
          title={t('Create an account')}
          open={isModalVisible}
          onCancel={handleCancel}
          style={{ minWidth: '400px' }}
          width={'25%'}
          footer={[
              <Button key="cancel" onClick={handleCancel} aria-label={'Cancel'}>
                  {t('Cancel')}
              </Button>,
              <Button form="addUser" key="submit" type="primary" htmlType="submit" aria-label={'Save'}>
                  {t('Save')}
              </Button>,
          ]}>
          <RegistrationForm onCloseParentModal={handleCancel} />
      </Modal>
  );
};
