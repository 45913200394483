import * as React from 'react';
import { Card, Flex } from 'antd';
import styled from 'styled-components';
import { useResizeWidthWindow } from '../hooks/useResizeWidthWindow';
import { BaseImage } from './Image/BaseImage';
import { SIZE_IMAGE } from '../common';

interface IGalleryBlock {
  images: string[],
  basePath: string
}

export const FlexStyled = styled(Flex)`
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  
  &.card_container .ant-image{
    display: block;
  }

  &.card_container img{
    background-size: cover;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    
  & .ant-card .ant-card-body{
    padding: 10px;
  }
`;

export const GalleryBlock: React.FC<IGalleryBlock> = ({ images, basePath }) => (
      <FlexStyled className={'card_container'}>
         {images.length && images.map((img) => (
             <Card key={img.slice(0, -3)} style={useResizeWidthWindow() < 400 ? { width: '100%' } : { width: 300 }}>
                 <BaseImage height={194}
                   src={`${basePath}/${SIZE_IMAGE[1400]}${img}`}
                   preview={true}
                 />
             </Card>
         ))}
    </FlexStyled>
);
