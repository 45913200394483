import * as React from 'react';
import { Typography } from '@mui/material';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { colors } from '../../common/constStyles';
import { ContactType } from '../../../../common/types';
import { CardBlockContactItem } from './CardBlockContactItem';

interface ICardBlockContact {
  label: string,
  values: ContactType[],
}

const RowStyled = styled(Row)`
  padding: 30px 25px 20px;
  border-bottom: 1px solid ${colors.colorBorder};
  box-sizing: border-box;
  overflow: hidden;
  word-wrap: break-word;
  text-align: justify;

  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

const TypographyStyled = styled(Typography)`
  @media (max-width: 768px) {
    font-size: 0.7rem !important;
  }
`;

export const CardBlockContact: React.FC<ICardBlockContact> = ({ label, values }) => (
        <RowStyled>
            <Col xs={8} sm={8} md={8} lg={6} xl={6}>
                <TypographyStyled>{label}</TypographyStyled>
            </Col>
            <Col xs={16} sm={16} md={16} lg={18} xl={18}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 30 }}>
                    {values.map((val, key) => <CardBlockContactItem contact={val} key={key}/>)}
                </div>
            </Col>
        </RowStyled>
);
