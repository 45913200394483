import * as React from 'react';
import { Button, Flex } from 'antd';
import {
  Card, CardActions, CardContent, Typography,
} from '@mui/material';
import { EyeFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import FlagIconFactory from 'react-flag-icon-css';
import { useTranslation } from 'react-i18next';
import { paths } from '../../common';
import { OfficeType, ServiceType } from '../../../../common/types';
import { RateComponent } from '../RateComponent';
import { useResizeWidthWindow } from '../../hooks/useResizeWidthWindow';
import { useCategory } from '../../hooks/useGategory';
import { LabelPremium } from '../LabelPremium';
import { colors } from '../../common/constStyles';
import { AvatarServiceCard } from '../Image/AvatarServiceCard';
import { getReadableUrl } from '../../common/functions';

export interface IMiniCardBusinessContent {
  service: ServiceType,
  firstOffice: OfficeType
  imageSrcAvatar: string
}

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

const TypographyStyled = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
`;

const CardStyled = styled(Card)`
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: none;
  display: flex;
  flex-direction: column;

  &:hover{
    transform: scale(1.01);
    transition: all 0.1s ease-in 0s;
  }
  
  .company_name{
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.43;
    overflow: hidden;
    //display: -webkit-box;
    //-webkit-box-orient: vertical;
    //-webkit-line-clamp: 1;
    width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: end;
  }
  
  .info{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    color: rgba(0, 0, 0, 0.6);
    text-align: end;
  }
`;

export const MiniCardStyledGridContent: React.FC<IMiniCardBusinessContent> = ({
  service, firstOffice, imageSrcAvatar,
}) => {
  const { CATEGORY_SERVICES } = useCategory();
  const { t } = useTranslation();

  const checkCountOffices = () => {
    if (service.office.length > 1) {
      return t('Multiple locations');
    }
    return firstOffice ? firstOffice.cityState : t('no location');
  };

  return (
      <CardStyled variant="outlined" style={{ backgroundColor: service.isResource ? colors.colorThird : 'transparent' }}>
          <Link to={`${paths.PUBLIC_RESOURCE}/${getReadableUrl(service.profile.companyName)}`} style={{ all: 'unset' }}>
              <div style={{ flexGrow: 1, position: 'relative' }}>
                  <LabelPremium service={service} style={{ right: 4, top: 6 }}/>
                  <Flex align={'center'} style={{ padding: '16px' }} justify={'space-between'}>
                      <div style={{ marginRight: '10px' }}>
                          <AvatarServiceCard
                              imgSrc={imageSrcAvatar}
                              size={useResizeWidthWindow() > 800 ? 124 : 80}/>
                      </div>
                      <Flex vertical align={'end'}>
                          <p className={'company_name'}>{service.profile.companyName}</p>
                          <p className={'info'}>{CATEGORY_SERVICES[+service.profile.category]}</p>
                          <div style={{ marginTop: '10px' }}>
                              <RateComponent rate={0}/>
                          </div>
                          <p className={'info'}>
                              {checkCountOffices()}
                          </p>
                      </Flex>
                  </Flex>
                  <CardContent sx={{ paddingTop: 0, textAlign: 'left' }}>
                      <TypographyStyled gutterBottom style={{ fontSize: '1em' }}>
                          {service.profile.presentationInfo}
                      </TypographyStyled>
                  </CardContent>
                  <div>
                      <Flex justify={'space-between'} align={'center'} style={{ padding: ' 0 16px 0' }}>
                          <Flex gap={10}>
                              {
                                  service.profile.supportLanguage && service.profile.supportLanguage.length
                                    ? service.profile.supportLanguage.map((language) => <FlagIcon key={language}
                                                                                                    code={language}/>)
                                    : <span>{t('no data')}</span>
                              }
                          </Flex>
                          <CardActions>
                              <Button type="primary" ghost icon={<EyeFilled/>}
                                      size={'middle'}>{t('View more')}</Button>
                          </CardActions>
                      </Flex>
                  </div>
              </div>
          </Link>
      </CardStyled>
  );
};
