import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Flex } from 'antd';
import { ServiceType } from '../../../common/types';
import { fetchListServiceAdv } from '../store/slices/servicesSlice';
import { AppDispatch, RootState } from '../store';
import { AdvCard } from './Cards/AdvCard';

export const FlexStyled = styled(Flex)`
  padding: 0 10px;

  .adv_caption {
    font-weight: 600;
    font-size: 1rem;
    text-transform: capitalize;
    padding: 10px 5px;
    text-align: center;
  }

  @media (max-width: 768px) {
    padding: 0;

    .adv_caption {
      font-size: 1.2rem;
      padding: 20px 5px;
    }
  }

`;

export const AdvBlock: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const servicePublic = useSelector<RootState, ServiceType>((state) => state.services.servicePublic);
  const listServicesAdv = useSelector<RootState, ServiceType[]>((state) => state.services.serviceListAdv);

  useEffect(() => {
    if (!servicePublic) return;
    const searchParam = [
      `category=${servicePublic.profile.category}`,
      'adv=true',
    ];

    dispatch(fetchListServiceAdv(searchParam.join('&')));
  }, [servicePublic]);

  return (
        <FlexStyled vertical={true}>
            <p className={'adv_caption'}>You might also be interested</p>
            <Flex vertical gap={10}>
                {
                    listServicesAdv
                      .filter((service) => service.id !== servicePublic.id)
                      .map((service, index) => <AdvCard key={index} service={service}/>)
                }
            </Flex>
        </FlexStyled>
  );
};
