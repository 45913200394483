import React, { useState } from 'react';
import { message, Spin } from 'antd';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { ResponseResourcesType, PartDataForSaveType, ServiceType } from '../../../common/types';
import { API, paths } from '../common';
import { setCurrentService } from '../store/slices/servicesSlice';
import { AppDispatch } from '../store';
import { useAlertMessage } from './useAlertMessage';

export const useSaveData = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [msgError, setMsgError] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const RenderMessageError = () => (<>{useAlertMessage('error', msgError)}</>);
  const [spin, setSpin] = useState(false);

  const SpinnerSaveData: React.FC<{ children: any }> = ({ children }) => (<Spin spinning={spin} tip={'please wait ...'} style={{ width: '100%' }}>
      {children}
    </Spin>);

  const saveData = async (newService: ServiceType, partDataForSave: PartDataForSaveType) => {
    setSpin(true);
    try {
      const result = (await axios.post<ResponseResourcesType>(`${API.URL_REST_API}${paths.SERVICE}/${newService.id}?partData=${partDataForSave}`, newService)).data;
      dispatch(setCurrentService(newService));
      messageApi.open({
        type: 'success',
        content: result.message,
        duration: 3,
      });
      setSpin(false);
      return true;
    } catch (e: any) {
      setMsgError(JSON.stringify(e.response.data.message));
    }
    setSpin(false);
    return false;
  };

  return {
    RenderMessageError, saveData, SpinnerSaveData, contextHolder,
  };
};
