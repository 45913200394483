import * as React from 'react';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Col, Form, notification, Row, Select, Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { NotificationFilled } from '@ant-design/icons';
import Cookies from 'js-cookie';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { RichTextEditor, RichTextEditorRef } from 'mui-tiptap';
import { v4 as uuidv4 } from 'uuid';
import {
  API, COOKIE_ID_TOKEN, decodeToken, paths, ROLE_ADMIN,
} from '../../common';
import { useAlertMessage } from '../../hooks/useAlertMessage';
import { QuestionType, ResponseResourcesType } from '../../../../common/types';
import { AppDispatch, RootState } from '../../store';
import { fetchListServiceGlobal } from '../../store/slices/servicesSlice';
import { fetchListQuestion } from '../../store/slices/questionsSlice';
import { useCategory } from '../../hooks/useGategory';
import EditorMenuControls from './EditorMenuControls';
import useExtensions from './useExtensions';

interface IQuestionForm {
  onCloseParentModal: () => void;
}

export const QuestionForm: React.FC<IQuestionForm> = ({ onCloseParentModal }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { GROUP_CATEGORY_SERVICES, getGroupCategoryByCategory } = useCategory();
  const [msgQuestion, setMsgQuestion] = useState('');
  const renderMessageErrorQuestion = useAlertMessage('error', msgQuestion);
  const [spinModalPost, setSpinModalPost] = useState(false);
  const currentQuestion = useSelector((state: RootState) => state.question.currentQuestion);
  const serviceGlobalList = useSelector((state: RootState) => state.services.serviceGlobalList);
  const rteRef = useRef<RichTextEditorRef>(null);

  const token = Cookies.get(COOKIE_ID_TOKEN) || '';
  const decodedToken = decodeToken(token);

  useEffect(() => {
    if (decodedToken && decodedToken['custom:Role'] === ROLE_ADMIN) {
      dispatch(fetchListServiceGlobal());
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      question: currentQuestion.question,
      answer: '{}',
      resources: currentQuestion.resources,
    });

    // rteRef.current?.editor?.setOptions();
  }, [currentQuestion]);

  const openNotification = (text: string) => {
    notification.open({
      message: t('Question creating process'),
      description: <div dangerouslySetInnerHTML={{ __html: `${text}` }}/>,
      icon: <NotificationFilled style={{ color: '#108ee9' }}/>,
    });
  };

  const onFinish = async (values: QuestionType) => {
    setSpinModalPost(true);
    setMsgQuestion('');
    try {
      const newQuestion: Partial<QuestionType> = {
        ...currentQuestion,
        userId: decodedToken.sub,
        question: values.question,
        answer: rteRef.current?.editor?.getHTML(),
        resources: values.resources,
      };

      const result = (await axios.post<ResponseResourcesType>(`${API.URL_REST_API}${paths.QUESTION}/${newQuestion.id}`, newQuestion)).data;

      openNotification(result.message);
      onCloseParentModal();
      await dispatch(fetchListQuestion());
    } catch (e: any) {
      setMsgQuestion(e.response.data.message);
    }
    setSpinModalPost(false);
  };

  const onFinishFailed = useCallback((errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }, []);

  const extensions = useExtensions({
    placeholder: 'Add your own content here...',
  });

  return (
        <Spin spinning={spinModalPost} tip={t('Please wait')} style={{ width: '100%' }}>
            <Form
                form={form}
                name="addQuestion"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="nope"
                size={'large'}
            >
                <Row>
                    <Col span={24}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item<QuestionType>
                                    label={t('Question')}
                                    name="question"
                                    rules={[{ required: true, message: t('Please, fill this field!') }]}
                                >
                                    <TextArea rows={3} placeholder={t('Text')} showCount
                                              maxLength={101} allowClear/>
                                </Form.Item>
                                <Form.Item<QuestionType>
                                    label={t('Answer')}
                                    name="answer"
                                    rules={[
                                      { required: true },
                                      {
                                        validator: () => {
                                          const richTextValue = rteRef.current?.editor?.getJSON();

                                          if (!richTextValue?.content
                                                    || (richTextValue.content.length === 1
                                                        && richTextValue.content[0].type === 'paragraph'
                                                        && !richTextValue.content[0].content?.[0].text)) {
                                            return Promise.reject(new Error(t('Please, fill this field!')));
                                          }
                                          return Promise.resolve();
                                        },
                                      },
                                    ]}
                                >
                                    <RichTextEditor
                                        key={uuidv4()}
                                        ref={rteRef}
                                        content={currentQuestion.answer}
                                        extensions={extensions}
                                        renderControls={() => <EditorMenuControls/>}
                                    />
                                </Form.Item>
                                <Form.Item<QuestionType>
                                    label={t('Resources and services')}
                                    name="resources"
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Please select"
                                        filterOption={(input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={
                                            serviceGlobalList.map((service) => ({
                                              value: `${service.userId}/${service.id}`,
                                              label: `${service.profile.companyName} (${GROUP_CATEGORY_SERVICES[getGroupCategoryByCategory(service.profile.category)!].caption})`,
                                            }))
                                        }
                                    />
                                </Form.Item>
                                {[
                                  renderMessageErrorQuestion,
                                ]}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Spin>
  );
};
