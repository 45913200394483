import Cookies from 'js-cookie';
import { COOKIE_ID_TOKEN } from './common';

export const uploadFileApi = async (file: File, linkSave: string): Promise<boolean> => {
  try {
    const token = Cookies.get(COOKIE_ID_TOKEN) || '';
    const response = await fetch(linkSave, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
        Authorization: `Bearer ${token}`,
      },
      body: new Blob([file], { type: file.type }),
    });

    if (response.ok) {
      console.log('File uploaded successfully');
      return true;
    }

    console.log('File upload failed');
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};
