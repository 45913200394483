import * as React from 'react';
import { Tag } from 'antd';
import { ServiceType } from '../../../common/types';

interface ILabelPremium{
  service: ServiceType
  style: { right?: number, top: number, left?: number, transform?: string }
}

export const LabelPremium: React.FC<ILabelPremium> = ({ service, style }) => <div style={{
  textAlign: 'right', position: 'absolute', ...style,
}}>{service.isPremium && <Tag color="green-inverse">Premium</Tag>}</div>;
