import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Flex, Typography } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PriceChangeOutlined } from '@mui/icons-material';
import { PriceFormType, ServiceType } from '../../../../common/types';
import { FormPrice } from './FormPrice';
import { RootState } from '../../store';

const { Title } = Typography;

const defaultPricing: PriceFormType = {
  caption: '',
  caption_vn: '',
  caption_ua: '',
  description: '',
  description_vn: '',
  description_ua: '',
  price: 0,
};

export const FormPrices: React.FC = () => {
  const { t } = useTranslation();
  const service = useSelector<RootState, ServiceType>((state) => state.services.service);

  const [prices, setPrices] = useState<PriceFormType[]>([]);

  useEffect(() => {
    setPrices([...(service.pricesForm || [])]);
  }, [service]);

  const addRow = () => {
    const newRow = {
      ...defaultPricing,
      id: uuidv4(),
    };
    setPrices([...prices, newRow]);
  };

  return <>
        <Flex gap={10} align={'center'} style={{ marginBottom: 20 }}>
            <PriceChangeOutlined/>
            <Title level={4} style={{ margin: 0, textTransform: 'uppercase' }}>{t('Pricing')}</Title>
        </Flex>
        {prices.map((price) => <FormPrice key={price.id} pricesForm={price}/>)}
        <Flex style={{ paddingTop: 20 }}>
            <Button type={'primary'} icon={<PlusCircleFilled/>} size={'large'} onClick={addRow}
                    aria-label={'Add price'}></Button>
        </Flex>
    </>;
};
