import { Result, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';
import { PanelShadow } from '../components/PanelShadow';

import { Logo } from '../components/Logo';
import {
  API,
  COOKIE_REGISTRATION_CONFIRMED_EMAIL,
  COOKIE_REGISTRATION_EMAIL,
  COOKIE_REGISTRATION_ROLE,
  paths,
  TRUE_STRING,
} from '../common';
import { ResponseLoginType, RoleType } from '../../../common/types';

export const ConfirmRegistrationPage: React.FC = () => {
  const { t } = useTranslation();
  const [spin, setSpin] = useState(false);
  const [isOk, setIsOk] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [url, setUrl] = useState('');
  const email = useRef<string>('');
  const history = useNavigate();

  useEffect(() => {
    if (email.current) {
      return;
    }
    setSpin(true);

    setTimeout(async () => {
      const urlParams = new URLSearchParams(window.location.search);
      email.current = urlParams.get('email') || '';
      const params = ['email', 'hash'];
      const paramsToString = params.map((item) => `${item}=${urlParams.get(item)}`).join('&');

      setUrl(`${API.URL_LOGIN}${paths.CONFIRM_REGISTRATION}?${paramsToString}`);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!url) {
      return;
    }
    (async () => {
      try {
        await axios.post<ResponseLoginType>(url);
        Cookies.set(COOKIE_REGISTRATION_EMAIL, email.current);
        Cookies.set(COOKIE_REGISTRATION_CONFIRMED_EMAIL, TRUE_STRING);
        setIsOk(true);
        setMsgError('');
      } catch (e: any) {
        if (!Cookies.get(COOKIE_REGISTRATION_CONFIRMED_EMAIL)) {
          Cookies.set(COOKIE_REGISTRATION_EMAIL, '');
        }

        setMsgError(e.response.data.message);
        setIsOk(false);
      }
      setTimeout(() => {
        if (Cookies.get(COOKIE_REGISTRATION_ROLE) === 'user' as RoleType) {
          history(paths.REGISTRATION_USER);
        } else if (Cookies.get(COOKIE_REGISTRATION_ROLE) === 'provider' as RoleType) {
          history(paths.REGISTRATION_PROVIDER);
        }
      }, 3000);
      setSpin(false);
    })();
  }, [url]);

  const extraSpinner = <div style={{ width: '100%' }}>
        <Spin spinning={true} tip={t('Please wait')} style={{ width: '100%' }} size={'large'} />
      </div>;

  return (
      <PanelShadow>
        <Logo margin={'0 0 30px 0'} type={'full'}/>
        <Spin spinning={spin} tip={t('Please wait')} style={{ width: '100%' }}>
            {
                isOk && <Result
                status='success'
                title={t('Successfully confirm!')}
                subTitle={<span>{t('Please wait, redirecting')}</span>}
                extra={[extraSpinner]}
              />
            }
            {
                !!msgError && <Result
                status='error'
                title={t('Something is wrong!')}
                subTitle={`${msgError}, redirecting`}
                extra={extraSpinner}
              />
            }
        </Spin>
      </PanelShadow>
  );
};
