import * as React from 'react';
import { useState } from 'react';
import { Flex } from 'antd';
import { Email, Phone } from '@mui/icons-material';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { colors } from '../../common/constStyles';
import { ContactType } from '../../../../common/types';
import { useResizeWidthWindow } from '../../hooks/useResizeWidthWindow';

interface ICardBlockContactItem {
  contact: ContactType;
}

const BoxStyled = styled(Box)`
  display: flex;
  box-sizing: border-box;
  align-items: center;
`;

const AStyled = styled.a`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  width: 85%;

  @media (max-width: 768px) {
    font-size: 0.7rem !important;
  }
`;

const SpanTelStyled = styled.span`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  width: 85%;
  color: ${colors.colorMain};
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 0.7rem !important;
  }
`;

const SpanStyled = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
`;

export const CardBlockContactItem: React.FC<ICardBlockContactItem> = ({ contact }) => {
  const [isVisiblePhone, setIsVisiblePhone] = useState(false);

  const handleShowPhoneNumber = () => {
    setIsVisiblePhone(!isVisiblePhone);
  };

  const handleCallPhone = (val: ContactType) => {
    window.location.href = `tel:${val.telephone}`;
  };

  const isFilledNumber = (phoneNumber: string) => {
    const filledPhoneRegex = /\+1\(\d{3}\)\d{3}-\d{4}/;

    return filledPhoneRegex.test(phoneNumber);
  };

  return <Flex gap={useResizeWidthWindow() < 600 ? 10 : 20} justify={'start'} vertical={useResizeWidthWindow() < 600} >
        {
            contact.email
            && <BoxStyled>
                <Email color={'primary'} style={{ marginRight: '10px' }}/>
                <AStyled href={`mailto:${contact.email}`} aria-disabled="false">
                    <SpanStyled>{contact.email}</SpanStyled>
                </AStyled>
            </BoxStyled>
        }
        {
            isFilledNumber(contact.telephone)
            && <BoxStyled>
                <Phone color={'primary'} style={{ marginRight: '10px' }}/>
                {
                    isVisiblePhone
                    && <AStyled href={`tel:${contact.telephone}`} onClick={(e: any) => {
                      e.preventDefault();
                      handleCallPhone(contact);
                    }}>
                        {contact.telephone}
                    </AStyled>
                }
                {
                    !isVisiblePhone
                    && <div onClick={handleShowPhoneNumber}>
                        <SpanTelStyled>Show phone number</SpanTelStyled>
                    </div>
                }
            </BoxStyled>
        }
    </Flex>;
};
