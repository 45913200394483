import { Col, Empty, Row } from 'antd';
import * as React from 'react';
import { ServiceType } from '../../../common/types';
import { GalleryBlock } from './GalleryBlock';

interface ITabGallery {
  servicePublic: ServiceType,
}

export const TabGallery: React.FC<ITabGallery> = ({ servicePublic }) => {
  const images = servicePublic.gallery ? servicePublic.gallery.split('/') : [];

  return (<>
          <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {
                      !images.length && <Empty />
                  }
                  {
                      images.length > 0 && <GalleryBlock
                          images={images}
                          basePath={`${process.env.REACT_APP_S3_STORAGE_URL}/${process.env.REACT_APP_BUCKET}/${servicePublic.userId}`}/>
                  }
              </Col>
          </Row>
        </>
  );
};
