import React from 'react';
import { Helmet } from 'react-helmet';
import { Flex } from 'antd';
import { StepsRegistrationUser } from '../components/StepsRegistrationUser';
import { RoleType } from '../../../common/types';
import { colors } from '../common/constStyles';
import { RegistrationHeaderSeeker } from '../components/RegistrationHeaderSeeker';
import { RegistrationHeaderProvider } from '../components/RegistrationHeaderProvider';

interface IRegistrationPage {
  role: RoleType
}

export const RegistrationPage: React.FC<IRegistrationPage> = ({ role }) => (
        <div>
            <Helmet>
                <title>Sign up | ServiceIn.ca</title>
                <meta name="description" content="Sign up for your ServiceIn.ca account now"/>
                <link rel="canonical" href='https://servicein.ca/registration_provider'/>
            </Helmet>
            <div>
                <div style={{
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  background: `${colors.colorSecond}`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  padding: '0',
                }}>
                    {
                        role === 'user'
                          ? <RegistrationHeaderSeeker/>
                          : <RegistrationHeaderProvider/>
                    }
                </div>
            </div>
            <Flex justify={'center'} style={{ marginTop: 20, padding: '0 15px' }}>
                <span style={{ fontSize: '1.5rem', textTransform: 'uppercase', fontWeight: 700 }}>Let's start your
                    registration process!</span>
            </Flex>
            <StepsRegistrationUser role={role}/>
        </div>
);
