import {
  Button, Checkbox, Col, Form, Input, Row, Spin,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import {
  API, COOKIE_ACCESS_TOKEN, COOKIE_ID_TOKEN, COOKIE_REFRESH_TOKEN, COOKIE_SESSION, paths,
} from '../common';
import { PanelShadow } from '../components/PanelShadow';
import { ResponseLoginType } from '../../../common/types';
import { useAlertMessage } from '../hooks/useAlertMessage';
import { setLogin } from '../store/slices/settingsSlice';
import { AppDispatch, selectLogin } from '../store';
import { colors } from '../common/constStyles';
import { ButtonOpenModal } from '../components/Modal/ButtonOpenModal';
import { useResizeWidthWindow } from '../hooks/useResizeWidthWindow';

const DivStyled = styled.div`
  background-color: ${colors.colorGrey};
  flex: 1 1 auto;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const AStyled = styled.a`
  text-decoration: underline;
  margin: 0 16px;
  color: #172145;
  transition: .4s;

  @media (max-width: 768px) {
    margin: 16px 0;
  }
`;

const HStyled = styled.h1`
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 10px;
  color: ${colors.colorTextDark};

  @media (max-width: 768px) {
    font-size: 26px;
  }
`;

export const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [msg, setMsg] = useState('');
  const [spin, setSpin] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const history = useNavigate();
  const login = useSelector(selectLogin);

  useEffect(() => {
    if (login) {
      // console.log('login', login);
      // history(paths.USER_PROFILE);
    }
    // clearTokens();
    // dispatch(setLogin(''));
  }, [login]);

  const setToken = useCallback((authResult: AuthenticationResultType) => {
    Cookies.set(COOKIE_ID_TOKEN, authResult.IdToken || '');
    Cookies.set(COOKIE_ACCESS_TOKEN, authResult.AccessToken || '');
    if (rememberMe) Cookies.set(COOKIE_REFRESH_TOKEN, authResult.RefreshToken || '');
  }, [rememberMe]);

  const onFinishLogin = useCallback(async (values: any) => {
    setMsg('');
    setSpin(true);

    try {
      const result = (await axios.post<ResponseLoginType>(`${API.URL_LOGIN}${paths.LOGIN}`, JSON.stringify({
        username: values.username.toLowerCase(), password: values.password,
      }))).data;

      const { session, authResult } = result;

      setSpin(false);
      if (authResult && Object.keys(authResult).length) {
        setToken(authResult);
        dispatch(setLogin(values.username));
        history(paths.USER_PROFILE);
        // Cookies.set(COOKIE_REGISTRATION_ROLE, '');
        // Cookies.set(COOKIE_REGISTRATION_EMAIL, '');
        // Cookies.set(COOKIE_REGISTRATION_CONFIRMED_EMAIL, '');
      } else if (session) {
        dispatch(setLogin(values.username));
        history(paths.SET_NEW_PASSWORD);
        Cookies.set(COOKIE_SESSION, session);
      }
    } catch (e: any) {
      console.log(e);
      setSpin(false);
      setMsg(JSON.stringify(e.response.data.message));
    }
  }, [rememberMe]);

  const onFinishFailed = useCallback((errorInfo: any) => {
    console.log('Failed:', errorInfo);
  }, []);

  const renderMessageError = useAlertMessage('error', msg);

  const handleRememberMe = (event: CheckboxChangeEvent) => {
    console.log('event.target.checked', event.target.checked);
    setRememberMe(event.target.checked);
  };

  return (
    <DivStyled>
        <Helmet>
          <title>Login | ServiceIn.ca</title>
          <meta name="description" content="Login to your ServiceIn.ca account now" />
          <link rel="canonical" href='https://servicein.ca/login'/>
        </Helmet>
        <PanelShadow>
          <HStyled>Welcome to ServiceIn</HStyled>
          <span>Your success starts here!</span>
          <div style={{ marginTop: '20px' }}>
          <Spin spinning={spin} tip={t('Please wait')}>
            <Form
                name="login"
                size={'large'}
                onFinish={onFinishLogin}
                onFinishFailed={onFinishFailed}
                autoComplete="nope"
                layout={'vertical'}
            >
              <Form.Item
                  label={t('User Name')}
                  name={'username'}
                  rules={[
                    { required: true, message: t('Please input your username!') },
                    {
                      type: 'email',
                      message: t('The input is not valid E-mail!'),
                    },
                  ]}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                  label={t('Password')}
                  name={'password'}
                  rules={[
                    { required: true, message: t('Please input your password!') },
                  ]}
              >
                <Input.Password />
              </Form.Item>
              {
                renderMessageError
              }
              <Row>
                <Col span={18} style={{ padding: 3 }}>
                  <Checkbox defaultChecked={rememberMe} onChange={handleRememberMe}>{t('Remember me')}</Checkbox>
                </Col>
              </Row>
              <Row style={{ padding: '30px 0' }}>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button type="primary" htmlType="submit" size={'large'} style={{ width: '100%' }} aria-label={'Sign in'}>
                    {t('Sign In')}
                  </Button>
                </Col>
              </Row>
            </Form>
            <Row style={{ textAlign: 'center' }} gutter={useResizeWidthWindow() < 700 ? [16, 16] : 0}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <AStyled onClick={() => {
                  history(paths.RESET_PASSWORD);
                }}>{t('Forgot your password')}</AStyled>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <ButtonOpenModal mode={'link'} title={t('Create an account')} type={'registration'} />
              </Col>
            </Row>
          </Spin>
          </div>
        </PanelShadow>
      </DivStyled>
  );
};
